.nav-dropdown {
  height: 100%;
  display: flex;
  min-width: 50px;

  flex-direction: column;

  color: white;

  border-color: rgba(color(mirror), 0.2);
  border-style: solid;
  cursor: pointer;
  outline: 0;

  overflow: hidden;
  border-width: 0 1px;

  &:focus {
    overflow: visible;

    pointer-events: none;
  }

  &:last-child {
    border-right: 0;
  }

  &__item {
    display: flex;
    max-height: 100%;
    min-height: 100%;
    padding-left: 15px;
    padding-right: 15px;

    align-items: center;
    justify-content: center;

    order: 1;

    color: color(dark-grey, base);

    // background-color: color(white);

    pointer-events: none;

    &:first-child {
      border: 0;
    }

    &:not(.selected) {
      @include base-box-shadow();
      top: 10px;
      // position: relative;
      background-color: #394e60;

      pointer-events: all;

      &:hover {
        background-color: color(ice);
      }

      & + & {
        border-top: 1px solid rgba(222, 225, 230, 0.6);
      }
    }

    &.selected {
      order: 0;

      color: inherit;

      background-color: transparent;
    }

    @include mq($from: tablet) {
      padding-left: 21px;
      padding-right: 21px;
    }
  }

  @include mq($from: tablet) {
    min-width: 60px;

    background-position: right 21px center;
  }
}

// .nav-dropdown {
//   height: 100%;
//   display: block;
//   min-width: 50px;
//   padding-left: 15px;
//   padding-right: 24px;

//   color: white;

//   background: transparent;
//   background-image: url('../svg/icons/icon-select-closed.svg');
//   background-position: right 15px center;
//   background-repeat: no-repeat;

//   border-color: rgba(color(mirror), 0.2);
//   border-radius: 0;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   -ms-appearance: none;
//   appearance: none;
//   border-width: 0 1px;

//   &:last-child {
//     border-right: 0;
//   }

//   @include mq($from: tablet) {
//     min-width: 60px;
//     padding-left: 21px;
//     padding-right: 35px;

//     background-position: right 21px center;
//   }
// }
