.page-header {
  @include mq($from: tablet) {
    text-align: left;
  }
  padding: 50px 0;

  text-align: center;
}

header.subnav {
  @include standard-transition;
  @include base-box-shadow();
  width: 330px;

  margin-top: 10px;
  max-height: 100%;

  position: fixed;
  z-index: 500;

  font-size: 15px;

  line-height: 60px;

  background: color(white);

  a {
    width: 100%;
    display: block;
    padding: 0 30px;

    color: color(dark-grey, base);

    border-top: 1px solid rgba(color(grey, light), 0.6);

    &:hover {
      background: color(ice);
    }
  }

  li {
    display: none;

    &.is-selected {
      display: block;

      & + .is-selected {
        color: color(dark-grey, base);
      }
    }
  }
}

header.subnav + .live-search-results {
  @include al-horizontally;

  @include mq($from: tablet) {
    top: 71px;
  }
  width: 100%;
  margin: 0;
  max-width: 1050px;

  top: 62px;

  position: fixed;
}

nav.desktop-nav__sub {
  @include standard-transition;
  display: none;
  max-height: 0px;

  right: 0;
  bottom: 0;
  left: 142px;
  position: absolute;

  overflow: hidden;

  @media (min-width: 782px) {
    display: flex;
  }

  .back-to-top {
    @include size(60px);
    line-height: 70px;

    text-align: center;

    border-left: 1px solid rgba(255, 255, 255, 0.2);

    transition: background 0.2s ease-in-out;

    svg {
      transform: rotate(270deg);

      polygon {
        fill: color(white);
      }
    }
  }

  .link-popover {
    margin: 0 50px 0 0;
  }
}
