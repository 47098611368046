$spinner-offset: 187;
$spinner-duration: 1.4s;

.spinner {
  animation: rotator $spinner-duration linear infinite;

  &__path {
    stroke-dasharray: $spinner-offset;
    stroke-dashoffset: 0;

    transform-origin: center;
    stroke: color(manticore-blue, base);
    animation: dash $spinner-duration ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: $spinner-offset;
  }

  50% {
    stroke-dashoffset: $spinner-offset/4;

    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $spinner-offset;

    transform: rotate(450deg);
  }
}
