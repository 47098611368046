.tabbed-content {
  &__tabs {
    display: flex;

    li {
      padding: 12px 22px;
      min-width: 100px;
      border: 1px solid color(ice);
      cursor: pointer;
      letter-spacing: 1px;
      text-align: center;

      &.selected {
        background-color: color(ice);
      }
    }
  }

  &__panel {
    &.hidden {
      display: none;
    }
  }
}
