.billboard {
  min-height: 100vh;

  text-align: center;

  .image {
    @include size(100%);
    top: 0;
    position: absolute;

    background-position: center center;
    background-size: cover;
  }

  &__content {
    width: 100%;

    top: 50%;
    left: 50%;

    position: absolute;

    z-index: $z-content-on-grid;

    transform: translateY(-50.1%) translateX(-50%);
  }

  &__arrow {
    bottom: 60px;
    left: 50%;

    position: absolute;

    z-index: $z-base;

    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateX(-50%) scale(1.2);
    }
  }

  &-search__container {
    display: none;

    @include mq($from: tablet) {
      display: block;
    }
  }

  .homepage-svg {
    padding-right: 30px;

    @include mq($from: tablet) {
      margin-bottom: 100px;
      padding: 0;
    }
  }

  @include mq($from: tablet) {
    text-align: left;
  }
}
