
.carousel-container {
  max-width: 950px;
  // height: 550px;
  text-align: center;
  margin: 30px 0;

  #docs-gallery {
    height: 100%;
    background: #e7eaed;
  }

  .vanilla-zoom {
    width: 100%;
    display: flex;
    min-height: 500px;

    .carousel-sidebar {
      flex-basis: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #3a4f5f;
      padding: 0 20px;
      overflow-x: hidden;
      overflow-y: scroll;

      .small-preview {
        width: 100%;
        object-fit: contain;
        max-width: 120px;
        height: 70px;
        margin: 10px !important;
        cursor: pointer;
        box-shadow: 1px 7px 13px #222;
      }


      .small-preview:last-child{
        margin-bottom: 0;
      }
    }

    .zoomed-desc {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-around;
      background-color: #E7EAED;

      .zoomed-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 75%;
        height: 350px;
        margin: 2em auto;
        // box-shadow: 5px 10px 18px #888888;
        flex-grow: 1;
      }

      .zoomed-text {
        color: #3a4f5f;
        font-size: 20px;
        margin: 2em !important;
      }
    }
  }
}
