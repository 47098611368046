.appid-list .static-page__section {
  .app-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 750px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (min-width: 1110px) {
      width: 100%;
    }
  }

  .appid-item {
    width: 120px;
    height: 160px;
    margin: 20px;
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    background: color(white);
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;

    &__label {
      width: 70px;
      height: 20px;
      top: 10px;
      right: 10px;
      position: absolute;
      background: color(mirror);

      span {
        font-size: 10px;
        color: color(grey, base);
        text-align: center;
        text-transform: uppercase;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.44;
    }

    .top-05 {
      top: 0.5rem;
    }

    .top-15 {
      top: 1.5rem;
    }

    &__category {
      font-size: 12px;
      margin-bottom: 0.25rem;
    }

    &__icon {
      width: 120px;
      min-height: 120px;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: auto;

      @media only screen and (max-width: 600px) {
        height: auto;
      }
    }

    &__region {
      font-size: 10px;
      color: color(grey, base);
      text-transform: uppercase;
    }

    .after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      color: #fff;
      border-radius: 10px;

      .after-info {
        height: 6rem;

        &__text {
          margin-top: 2rem;

          &--title {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.44;
            overflow-wrap: break-word;
            margin: 0 0.5rem;
          }

          &--category {
            font-size: 12px;
            margin-top: 0.5rem;
            color: #000;
          }
        }

        &__available {
          p {
            font-size: 10px;
            color: color(grey, base);
          }
        }
      }

      hr {
        background-color: color(lime, base);
        height: 1px;
        border: none;
      }

      .after-download {
        border-radius: 0px 0px 10px 10px;
        height: 2.6rem;

        &:hover {
          background-color: color(lime, base);
          transition: all 0.3s ease-in-out;
        }

        &__button {
          height: 100%;
          display: flex;
          justify-content: center;

          &--text {
            color: color(lime, base);
            margin: auto;
            font-size: 10px;
            padding: 4px;

            &:hover {
              color: #fff;
            }
          }
        }

        @media only screen and (max-width: 600px) {
          height: 27%;
        }
      }
    }

    &:hover .after {
      transition: all 0.3s ease-in-out;
      opacity: 1;
      background: #d9d9d9;
      background: linear-gradient(to bottom, rgba(173, 173, 173, 0) 0%, rgba(255, 255, 255, 0.95) 22%);
    }
  }

  .mauto {
    margin: auto;
  }

  .shadow-container {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }

  .appid-mini {
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    text-align: center;
    background: color(white);

    .w85 {
      width: 85%;
    }

    &__label {
      width: 70px;
      height: 20px;
      top: 10px;
      right: 10px;
      position: absolute;
      background: color(mirror);

      span {
        font-size: 14px;
        color: color(grey, base);
        text-align: center;
        text-transform: uppercase;
      }
    }

    &__title {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.44;
    }

    &__category {
      font-size: 10px;
      line-height: 1.44;
    }

    &__icon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
    }

    &__region {
      font-size: 10px;
      color: color(grey, base);
      text-transform: uppercase;
    }

    .after {
      position: absolute;
      width: 10%;
      height: 10%;
      opacity: 0;
      color: #fff;
      border-radius: 10px;
      width: auto;

      .after-mini {
        margin-top: -6px;

        svg {
          height: 78px;
          margin-right: 0.75rem;
        }
      }
    }
  }

  .appid-mini.hover {
    &:hover .after {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      height: inherit;
      width: inherit;
      background: rgba(255, 255, 255, 0.75);
    }
  }

  hr {
    background-color: lightgray;
    height: 1px;
    border: none;
  }
}

.after-dowload:hover {
  background-color: white;
}

.after-download:hover .after-download__button--text {
  color: white !important;
}

.m-t-30 {
  margin-top: 30px;
}
