@mixin syntax-theme(
  $name,
  $base00,
  $base01,
  $base02,
  $base03,
  $base04,
  $base05,
  $base06,
  $base07,
  $base08,
  $base09,
  $base0A,
  $base0B,
  $base0C,
  $base0D,
  $base0E,
  $base0F
) {
  .#{$name}-dark {
    .codehilite .hll,
    .codehilite pre,
    .highlight,
    code {
      color: $base07;

      background: $base00;
    }

    #documentation__content pre code,
    .highlight,
    .codehilite,
    #theme-container {
      color: $base07;

      background: $base00;
      border: none;
    }
  }

  .#{$name}-light {
    .codehilite .hll,
    .codehilite pre,
    .highlight,
    code {
      color: $base00;

      background: $base07;
    }

    #documentation__content pre code,
    #theme-container {
      color: $base00;

      background: $base07;
      border: none;
    }
  }

  .#{$name} {
    .codehilite,
    .highlight,
    code {
      @include t-antialias;
      font-weight: 400;

      .p,
      .o,
      .l {
        color: $base05;
      }

      .nt,
      .n,
      .kt,
      .err {
        color: $base0E;

        background: none;
      }

      .mi,
      .kc,
      .bp {
        color: $base09;
      }

      .s2,
      .s,
      .nv,
      .nf {
        color: $base08;

        background: none;
      }

      .kd {
        color: $base0D;
      }

      .k,
      .nb {
        color: $base0A;
      }

      .nl,
      .na {
        color: $base0A;
      }

      .com {
        color: $base03;
      }

      .nc {
        color: $base0C;
      }
    }
  }
}

@include syntax-theme(
  default,
  #202c36,
  #394e60,
  #647686,
  #94a0ab,
  #a9b3bd,
  #e7eaed,
  #f0f2f4,
  #f7f8fa,
  #f4636c,
  #f39659,
  #78c96f,
  #2bb074,
  #3ac2b4,
  #366fb3,
  #be8dd0,
  #fda0de
);
@include syntax-theme(
  maroon,
  #2f1e2e,
  #41323f,
  #4f424c,
  #776e71,
  #8d8687,
  #a39e9b,
  #b9b6b0,
  #e7e9db,
  #ef6155,
  #f99b15,
  #fec418,
  #48b685,
  #42d1c6,
  #06b6ef,
  #815ba4,
  #e96ba8
);
@include syntax-theme(
  brown,
  #3b322b,
  #534636,
  #645240,
  #7e705a,
  #b8afad,
  #d0c8c6,
  #e9e1dd,
  #f5eeeb,
  #cb6077,
  #d28b71,
  #f4bc87,
  #a2a86a,
  #8dccb2,
  #7fb9c9,
  #a89bb9,
  #bb9584
);
@include syntax-theme(
  black,
  #151515,
  #202020,
  #303030,
  #505050,
  #b0b0b0,
  #d0d0d0,
  #e0e0e0,
  #f5f5f5,
  #c64a50,
  #ea8b4b,
  #f4bf75,
  #a0b566,
  #44ccb2,
  #5498c6,
  #905fa3,
  #8f5536
);

.codehilite,
.highlight {
  @include t-antialias;
  margin: 30px 0;
  padding: 18px;

  overflow-x: scroll;
  overflow-y: hidden;

  code {
    @include t-antialias;
    margin: 0;

    font-family: 'LivioMono', monospace;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }
}

code {
  font-family: 'LivioMono', monospace;

  .result-body__highlight {
    color: color(dark-grey, base);
  }
}
