
.homepage {
  .high-level-diagram {
    position: relative;
    z-index: 3;

    background-color: white;

    .section-head .t-small--tracked {
      margin-bottom: 30px;
    }

    &__section {
      padding-bottom: 0px;
      padding-top: 50px;
      margin-bottom: 8rem;

      .container-illustration {
        max-width: 600px;
        max-height: 295px;
        margin: auto;
        width: calc(100% - 60px);
        margin-left: auto;
        margin-right: auto;

        .cls-1 {
          fill: #fff;
        }

      }

      @include mq($from: tablet) {
        padding-bottom: 0;
        padding-top: 0;

        background: linear-gradient(to bottom, color(lime, base), color(lime, base) 50%, transparent 50%, transparent);
      }
    }

    &__content {
      @include mq($from: tablet) {
        padding-bottom: 72px;
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 116px;
      }
    }

    &__image--mobile {
      @include mq($from: wide-mobile) {
        display: none;
      }
    }

    &__image--desktop {
      display: none;

      @include mq($from: wide-mobile) {
        display: block;
      }
    }

    &__button {
      width: 100%;
      height: 4rem;
      display: flex;
      margin-top: 20px;
      padding: 20px;

      align-items: center;
      justify-content: space-between;

      font-weight: 500;

      color: color(lime, base);
      letter-spacing: 2px;
      text-transform: uppercase;

      border: 1px solid;

      & svg,
      & g {
        fill: currentColor !important;
      }

      &:hover {
        color: white;

        background-color: color(lime, base);
      }

      @include mq($from: tablet) {
        padding-bottom: 49px;
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 49px;

        border: 0;
        border-top: 1px solid;
      }
    }

    @include mq($from: tablet) {
      box-shadow: 0 20px 70px 0 rgba(color(black), 0.15);
    }
  }
  // .high-level-diagram {
  //     &-section {
  //         .container.container--full {
  //             @include mq($until: desktop) {
  //                 width: 100%;
  //                 max-width: 100%;
  //                 overflow: hidden;
  //             }
  //         }
  //         .copy-banner.with-button {
  //             @include mq($until: desktop) {
  //                 padding-bottom: 0px;
  //             }
  //         }
  //         .copy-banner__button {
  //             border-top: 1px solid #78c96f;
  //             &.about-link {
  //                 @include mq($until: desktop) {
  //                     display: none;
  //                 }
  //             }
  //             &:hover {
  //                 background-color: #78c96f;
  //                 p {
  //                     color: white;
  //                 }
  //                 svg {
  //                     polygon {
  //                         fill: white;
  //                     }
  //                 }
  //             }
  //         }
  //     }
  //     @include mq($from: tablet) {
  //         display: block;
  //         &--mobile {
  //             display: none;
  //         }
  //     }
  //     @include mq($until: tablet) {
  //         display: none;
  //         &--mobile {
  //             display: block;
  //         }
  //     }
  //
  //     .btn--mobile--full {
  //         &:hover {
  //             background-color: #78c96f;
  //             color: white;
  //         }
  //     }
  // }

  .about--mobile {
    width: 100%;
    display: flex;
    padding: 30px 30px 0;

    background-color: #ffffff;

    @include mq($from: desktop) {
      display: none;
    }
  }
  // .copy-banner__button.about-link {
  //     @include mq($until: desktop) {
  //         display: none;
  //     }
  // }

  .billboard {
    background-image: url('../img/homepage/billboard-mobile.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__content {

      &--header {
        display: flex;
        flex-direction: row;


        .mauto {
          margin: auto;
        }

        img {
          width: 400px;
          height: 167px;
          margin-right: 4rem;


          @media only screen and (max-width: 1000px) {
            margin-right: 2.5rem;
          }

          @media only screen and (max-width: 882px) {
            margin: auto;
          }
        }

        h1 {
          overflow-wrap: anywhere;
          font-size: 3rem;
          margin: auto 0;
          line-height: 1.1;

          @media only screen and (max-width: 882px) {
            text-align: center;
            margin-top: 1em;
          }

          @media only screen and (max-width: 600px) {
            font-size: 2em;
          }
        }

        @media only screen and (max-width: 882px) {
          flex-direction: column;
        }

      }
    }

    @include mq($from: desktop) {
      background-image: url('../img/homepage/billboard.jpg');
    }
  }

  .platform-buttons {
    flex-wrap: wrap;

    position: relative;
    z-index: $z-content-on-grid;

    a {
      @include standard-transition;
      width: calc(50% - 15px);
      height: 120px;

      font-size: 20px;

      &:hover {
        @include standard-transition;
        background-color: color(lime, light);
      }

      svg {
        top: -4px;
        position: relative;
      }

      @include mq($until: desktop) {
        width: 100%;
      }
    }

    @include mq($from: desktop) {
      flex-wrap: nowrap;
    }
  }

  .what-is-sdl {

    .button {
      max-width: 690px;
      width: 100%;
      height: 120px;
      margin: auto;
      filter: drop-shadow(0px 20px 35px rgba(32,44,54,0.15));

      background-color: #78c96f;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px;
      border: none;
      margin-top: 4rem;

      font-size: 30px;
      letter-spacing: 2px;
      color: #ffffff;

      .arrow {
        width: 20px;
        height: 20px;
        background-image: url('../svg/icons/icon-right-arrow-white.svg');
        background-color: transparent;
        background-repeat: no-repeat;
      }

      &:hover {
        background-color: #fff;
        color: #78c96f;

        .arrow {
          background-image: url('../svg/icons/icon-right-arrow-green.svg');
         }
      }
    }

    @include mq($from: tablet) {
      // padding-bottom: 250px;
    }
    @include mq($from: desktop) {
      // padding-bottom: 200px;
    }
  }

  .section-head {
    .t-small--tracked {
      // width: 150px;
    }

    &__button--desktop {
      display: block;

      svg g {
        fill: color(lime, base);
      }
    }
  }

  .copy-banner--color-field {
    padding: 90px 0 60px;

    z-index: unset;

    @include mq($from: tablet) {
      padding: 80px 0;
    }
    @include mq($from: desktop) {
      padding: 100px 0 120px;
    }
  }

  .three-up-grid {
    position: relative;
    z-index: $z-content-on-grid;

    .grid-item:not(:last-of-type) {
      @include mq($until: desktop) {
        margin-bottom: 60px;
      }
    }

    @include mq($from: tablet) {
      width: calc(100% - 140px);
      margin-left: auto;
      margin-right: auto;
    }
    @include mq($from: desktop) {
      width: unset;
    }
  }

  .catalog-grid {
    display: flex;
    flex-wrap: wrap;

    div {

      div {

        img {
          width: 15rem;
        }

        .title {
          width: 15rem;
        }

      }
    }

    @media only screen and (max-width: 1095px) {
      width: 70%;
      margin: auto;
    }
  }

  footer {
    .section-head {
      color: color(lime, base);

      .t-small--tracked {
        width: auto;
      }

      &::before {
        background-color: color(lime, base);
      }
    }
  }

  .homepage-spacing {
    padding: 80px 0 60px;

    &--top {
      padding-top: 80px;

      @include mq($from: tablet) {
        padding-top: 120px;
      }
    }

    &--bottom {
      padding-bottom: 60px;

      @include mq($from: tablet) {
        padding-bottom: 120px;
      }
    }

    &--small {
      padding: 40px 0 60px;

      @include mq($from: tablet) {
        padding: 80px 0 120px 0;
      }
    }

    &--no-mobile {
      @include mq($from: tablet) {
        padding-bottom: 100px;
      }
    }

    @include mq($from: tablet) {
      padding: 120px 0;
    }
  }

  .space-before-floating {
    @include mq($from: tablet) {
      padding-bottom: 320px;
    }
  }

  #what-is-sdl-anchor {
    display: block;

    top: -140px;
    position: relative;

    visibility: hidden;
  }

  //
  // Partners
  //

  .illo-content-block {
    &__image {
      height: 310px;

      position: relative;

      background-position: center center;

      background-size: cover;
      box-shadow: 0px 20px 50px 0px #cacaca;

      &.fit-to-grid {
        height: 200px;

        background: color(white);

        svg {
          max-width: 90%;
        }
      }

      svg {
        @include vert-align();
      }

      @include mq($from: tablet) {
        height: 400px;

        flex: 1;

        &.fit-to-grid {
          height: 300px;
        }
      }
    }

    &__info {
      width: calc(100% - 60px);
      margin: 30px auto 60px;

      position: relative;
      z-index: $z-content;

      p:not(.mb20-40):first-of-type {
        margin-bottom: 10px;

        span {
          display: inline-block;
          margin-right: 10px;

          font-size: 19px;

          transform: translateY(-1px);
        }
      }

      @include mq($from: tablet) {
        margin: 0;

        flex: 0 0 330px;
      }
    }

    @include mq($from: tablet) {
      display: flex;

      & + .illo-content-block {
        margin-top: 120px;
      }

      &--right {
        flex-direction: row-reverse;

        .illo-content-block__info {
          @include mq($from: tablet, $until: desktop) {
            padding-right: 25px;
          }
        }

        .illo-content-block__image:not(.fit-to-grid) {
          @include mq($from: desktop) {
            transform: translateX(120px);
          }
        }

        .illo-content-block__image.fit-to-grid {
          margin-left: 60px;
        }
      }

      &--left {
        .illo-content-block__info {
          @include mq($from: tablet, $until: desktop) {
            padding-left: 25px;
          }
        }

        .illo-content-block__image:not(.fit-to-grid) {
          @include mq($from: desktop) {
            transform: translateX(-120px);
          }
        }

        .illo-content-block__image.fit-to-grid {
          margin-right: 60px;
        }
      }
    }
    @include mq($from: desktop) {
      &--right {
        .illo-content-block__image.fit-to-grid {
          margin-left: 120px;
        }
      }

      &--left {
        .illo-content-block__image.fit-to-grid {
          margin-right: 120px;
        }
      }
    }
  }

  .app-partners {
    &__section {
      background-color: #2d3d4b;

      @include mq($from: tablet) {
        padding-bottom: 100px;
        padding-top: 215px;
      }
    }

    &__headline-container {
      display: flex;

      justify-content: space-between;

      color: #ffffff;
    }

    &__headline {
      margin-bottom: 47px;
    }

    &__directory {
      display: flex;

      color: color(white);

      span {
        margin-left: 10px;
      }
    }

    .fx-wrapper {
      align-items: center;
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }
    }

    .app-partner {
      width: 80%;
      height: 160px;
      display: flex;
      margin-bottom: 30px;
      margin-left: 0px;

      align-items: center;
      justify-content: center;
      flex-direction: column;

      flex: 0 1 240px;

      background: rgba(255, 255, 255, 0.1);

      &:first-child {
        margin-left: 0px;
      }

      img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }

      span {
        height: 30px;

        font-size: 20px;
        line-height: 1.5;
      }

      @include mq($from: tablet) {
        width: 23%;
        height: 160px;
        margin-left: 30px;
      }
    }

    @include mq($from: tablet) {
      display: flex;

      flex-wrap: wrap;
    }
  }

  .brand-letter {
    display: none;

    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }

    svg g {
      fill: rgba(color(grey, light), 0.3);
    }

    &--s {
      width: auto;
      height: 450px;

      top: -64px;
      left: -226px;
    }

    &--s--2 {
      top: -260px;
      right: -150px;
      z-index: $z-brand-letters;
    }

    &--s--3 {
      top: 0;
      left: 50px;
      z-index: $z-brand-letters;

      transform: translateX(201px) translateY(-195px);

      transform: rotate(90deg);
    }

    &--l {
      top: -161px;
      left: 0;
    }

    &--l--rotated {
      top: 140px;
      right: -150px;
      z-index: -1;
    }

    &--d--rotated {
      width: 507px;

      right: 0;
      z-index: $z-brand-letters;

      transform: translateX(201px) translateY(-195px) rotate(90deg);
    }

    &--d {
      bottom: 80px;
      left: -253px;
    }

    @include mq($from: tablet) {
      display: initial;
    }
  }

  .email-callout {
    padding: 30px 0;

    text-align: center;

    p {
      padding-bottom: 15px;
    }

    &--padding {
      @include mq($from: tablet) {
        padding: 120px 0 400px;

        p {
          padding-bottom: 30px;
        }
      }
    }

    @include mq($from: tablet) {
      padding: 120px 0;
    }
  }

  .email-callout + .bg-mirror .copy-banner.bg-shadow {
    @include mq($from: tablet) {
      z-index: 6;

      box-shadow: 0 20px 70px 0 rgba(50, 50, 50, 0.15);
    }
  }

  &.about {
    .sdl-logo__mark::after {
      display: none;
    }
  }

  .break_word {
    overflow-wrap: break-word;
  }
}
