// Vendor

@import 'vendor/rangeslider';

.manticore-wrapper {
  // A different base unit for Manticore :/
  $u: 10px;
  // Tachyons Variable overrides

  @import 'variables/spacing';
  @import 'node_modules/tachyons-sass/scss/spacing';
  @import 'fcss/coordinates';
  @import 'fcss/heights';
  @import 'fcss/input-reset';
  @import 'fcss/max-widths';
  @import 'fcss/type-scale';
  @import 'fcss/widths';
}

// Components

@import 'components/arrow';
@import 'components/manticore';
@import 'components/no-manticore';
@import 'components/resize-handle';
