.fc-white--stop4 {
  opacity: 0.4;
}

.svg-lime g {
  fill: color(lime, base);
}

.svg-ios-tutorial #Fill-1 {
  fill: color(teal, base);
}

.svg-android-tutorial #Fill-1 {
  fill: color(forest, base);
}

.svg-core-tutorial #Fill-1 {
  fill: color(lime, base);
}

.svg-server-tutorial #Fill-1 {
  fill: color(blue, base);
}

.svg-shaid-tutorial #Fill-1 {
  fill: color(orange, base);
}
