.pad {
  &-0 {
    padding: 0px;
  }

  &-5 {
    padding: 5px;
  }

  &-10 {
    padding: 10px;
  }

  &-15-20 {
    padding: 15px 20px;
  }

  &-16 {
    padding: 16px;
  }

  &-20 {
    padding: 20px;
  }

  &-30 {
    padding: 30px;
  }

  &-t {
    &-10 {
      padding-top: 10px;
    }

    &-12 {
      padding-top: 12px;
    }

    &-20 {
      padding-top: 20px;
    }

    &-30 {
      padding-top: 30px;
    }
  }

  &-b {
    &-10 {
      padding-bottom: 10px;
    }

    &-12 {
      padding-bottom: 12px;
    }

    &-20 {
      padding-bottom: 20px;
    }

    &-30 {
      padding-bottom: 30px;
    }

    &-60 {
      padding-bottom: 60px;
    }
  }

  &-r {
    &-10 {
      padding-right: 10px;
    }

    &-20 {
      padding-right: 20px;
    }

    &-30 {
      padding-right: 30px;
    }
  }

  &-l {
    &-5 {
      padding-left: 5px;
    }

    &-10 {
      padding-left: 10px;
    }

    &-20 {
      padding-left: 20px;
    }

    &-30 {
      padding-left: 30px;
    }
  }
}

@media #{$breakpoint-medium} {
  .pad {
    &-r {
      &-30-m {
        padding-right: 30px;
      }
    }
  }
}
