// dropdown
.status-dropdown {
  @include flex-direction(row);
  @include align-items(center);
  width: 130px;
  height: 30px;
  display: flex;
  padding: 10px;

  position: relative;

  font-size: 12px;

  background: color(white);
  background-image: url(../svg/basic/chevron-down.svg);
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 10px;

  &--full {
    width: 100%;
  }

  &--non-interactive {
    background-image: none;

    pointer-events: none;
  }

  .status-dot {
    width: 6px;
    height: 6px;
    margin-right: 10px;

    background-color: color(grey, base);
    border-radius: 50%;

    &--pending {
      background-color: color(grey, base);
    }

    &--approved {
      background-color: color(lime, base);
    }

    &--denied {
      background-color: color(red, base);
    }
  }

  &__menu {
    @include base-box-shadow;
    width: 100%;
    display: none;
    max-height: 500px;
    top: 30px;
    left: 0;
    position: absolute;
    z-index: 12;
    transform: translateY(5px);
  }

  &__main {
    color: color(dark-grey, base);
    text-decoration: none;
  }

  &__submenu {
    > .status-dropdown__option:not(:first-of-type) {
      border-top: 1px solid color(grey, light);
    }
  }

  &__option {
    height: 30px;
    display: block;
    padding: 10px;

    color: color(dark-grey, base);

    background: color(white);

    &:hover {
      background: color(grey, light);
      cursor: pointer;
    }
  }

  &.is-open {
    background-image: url('../svg/basic/chevron-up.svg');

    .status-dropdown__menu {
      display: block;
    }
  }
}
