.consortium {
  padding-top: 60px;

  &__intro {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    color: #394e60;
    line-height: 1.44;

    p {
      margin-bottom: 30px;

      a {
        color: color(lime, base);
      }
    }
  }

  &__docs {
    margin-bottom: 60px;
    padding-top: 60px;
  }

  &__contact {
    padding-bottom: 100px;
    padding-top: 83px;

    background-color: #f4f5f7;
  }

  .contact-form {
    display: flex;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;

    flex-direction: column;

    background-color: #ffffff;

    &__header {
      height: 60px;
      display: flex;

      align-items: center;
      justify-content: space-between;
      flex: 1;

      font-weight: 500;

      color: #394e60;
    }

    &__body-wrap {
      display: none;
      padding-bottom: 30px;
    }

    &__body {
      display: flex;

      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__input {
      display: flex;
      margin-bottom: 30px;

      flex-direction: column;

      &--radio {
        margin-bottom: 10px;

        flex-direction: row;

        label {
          font-size: 15px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          color: #394e60;
          line-height: 1.33;
        }

        span {
          margin-left: 17px;
        }
      }

      label {
        margin-bottom: 10px;

        font-size: 10px;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;

        color: #a9b3bd;
        letter-spacing: 1.3px;
        line-height: 2;

        text-transform: uppercase;
      }

      input {
        height: 60px;

        background-color: #f7f8fa;

        border: solid 1px #dee1e6;
      }

      &.w-50 {
        flex-basis: 100%;

        @media (min-width: 600px) {
          flex-basis: 47.5%;
        }
      }
    }

    &__submit {
      width: 100%;
      display: flex;
      margin-top: 30px;

      justify-content: center;

      button {
        width: 150px;
        height: 40px;
        display: flex;

        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 500;

        color: #394e60;
        letter-spacing: 2px;
        line-height: 1.67;
        text-align: center;
        text-transform: uppercase;

        background-color: #e7eaed;

        border: none;

        transition: 0.2s ease;

        &:hover {
          background-color: #dee1e6;
        }
      }
    }

    p.submit-text {
      margin: 0 auto;
    }

    .js-svg-open {
      display: block;
    }

    .js-svg-close {
      display: none;
    }

    input[type='radio'] {
      display: none;
    }
  }

  .download {
    height: 60px;
    display: flex;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;

    align-items: center;
    justify-content: space-between;

    background-color: #ffffff;
    border: solid 1px #eaecf0;

    transition: 0.2s ease;

    &:hover {
      background-color: #eaecf0;
    }

    &__name {
      display: flex;

      align-items: center;

      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;

      color: #394e60;

      span {
        margin-right: 34px;
      }
    }

    svg {
      fill: #808080;
    }
  }

  .w-100 {
    flex-basis: 100%;
  }

  .section-head {
    margin-bottom: 53px;
    margin-top: 0px;

    font-weight: normal;

    &::before {
      background-color: #465e72;
    }
  }

  .label-error {
    color: #f3636c;
  }

  .input-error {
    border: 1px solid #f3636c !important;
  }
}
