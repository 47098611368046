.licenses {
  padding-top: 80px;

  p {
    margin-bottom: 30px;
  }

  &__intro {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    color: #394e60;
    line-height: 1.44;

    p {
      margin-bottom: 30px;
    }

    h2 {
      padding: 30px 0 20px;

      font-size: 30px;
      font-weight: 400;
    }
  }

  &__links {
    padding-bottom: 100px;
    padding-top: 0;

    background-color: #f4f5f7;

    .section-header {
      padding-bottom: 45px;
      padding-top: 50px;
    }
  }

  .download {
    height: 60px;
    display: flex;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;

    align-items: center;
    justify-content: space-between;

    color: #394e60;

    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(color(black), 0.15);

    transition: 0.2s ease;

    &:hover {
      color: white;

      background-color: #394e60;

      svg {
        polygon {
          fill: #ffffff;
        }
      }
    }

    &__name {
      display: flex;

      align-items: center;

      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;

      &:hover {
        color: white;
      }

      span {
        margin-right: 34px;
      }
    }

    svg {
      polygon {
        fill: #394e60;
      }
    }
  }

  .w-100 {
    flex-basis: 100%;
  }

  .section-head {
    margin-bottom: 53px;
    margin-top: 0px;

    font-weight: normal;

    &::before {
      background-color: #465e72;
    }
  }
}
