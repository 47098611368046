// Tachyons

.shrink-0 {
  flex-shrink: 0;
}

.shrink-1 {
  flex-shrink: 1;
}

.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.flex-item {
  min-height: 0;

  flex: 1 1 0%;
}

.flex {
  display: flex;
}

@media #{$breakpoint-not-small} {
  .shrink-0-ns {
    flex-shrink: 0;
  }

  .shrink-1-ns {
    flex-shrink: 1;
  }

  .grow-0-ns {
    flex-grow: 0;
  }

  .grow-1-ns {
    flex-grow: 1;
  }

  .flex-item-ns {
    flex: 1 1 0%;
  }

  .flex-auto-ns {
    flex: 1 1 auto;

    @media all and (-ms-high-contrast: none) {
      flex: 1 1 100%;
    }
  }
}
@media #{$breakpoint-medium} {
  .shrink-0-m {
    flex-shrink: 0;
  }

  .shrink-1-m {
    flex-shrink: 1;
  }

  .grow-0-m {
    flex-grow: 0;
  }

  .grow-1-m {
    flex-grow: 1;
  }

  .flex-item-m {
    flex: 1 1 0%;
  }

  .flex-auto-m {
    flex: 1 1 auto;

    @media all and (-ms-high-contrast: none) {
      flex: 1 1 100%;
    }
  }
}
@media #{$breakpoint-large} {
  .shrink-0-l {
    flex-shrink: 0;
  }

  .shrink-1-l {
    flex-shrink: 1;
  }

  .grow-0-l {
    flex-grow: 0;
  }

  .grow-1-l {
    flex-grow: 1;
  }

  .flex-item-l {
    flex: 1 1 0%;
  }

  .flex-auto-l {
    flex: 1 1 auto;

    @media all and (-ms-high-contrast: none) {
      flex: 1 1 100%;
    }
  }
}

// Not tachyons

.fx-wrapper {
  width: 100%;
  display: flex;
}

.fx-wrap {
  flex-wrap: wrap;
}

.fx-nowrap {
  flex-wrap: nowrap;
}

.fx-a-center {
  align-items: center;
}

.center-items {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.fx-a-baseline {
  align-items: baseline;
}

.fx-a-top {
  align-items: flex-start;

  &--tablet {
    @include mq($until: desktop) {
      align-items: flex-start;
    }
  }
}

.fx-a-bottom {
  align-items: flex-end;
}

.fx-s-between {
  justify-content: space-between;
}

.fx-jc-end {
  justify-content: flex-end;
}

.fx-s-center {
  justify-content: center;

  &--mobile {
    @include mq($until: tablet) {
      justify-content: center;
    }
  }
}

.fx-f-start {
  justify-content: flex-start;
}

.fx-f-end {
  justify-content: flex-end;
}

.fx-column {
  flex-direction: column;

  &--tablet {
    @include mq($until: desktop) {
      flex-direction: column;
    }
  }

  &--mobile {
    @include mq($until: tablet) {
      flex-direction: column;
    }
  }
}

.fx-push-right {
  margin-left: auto;
}

.fx-push-left {
  margin-right: auto;
}

.fx-third {
  .fx-cell {
    width: calc(33.33% - 30px);
  }
}

.fx-quarter {
  .fx-cell {
    width: calc(25% - 20px);
  }
}

.fx-half {
  .fx-cell {
    width: calc(50%);
  }
}
