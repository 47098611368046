#documentation__content {
  .api {
    margin: 60px 0;
    padding: 20px;

    background-color: #2d3d4a;

    .codehilite {
      margin-bottom: 0;
      margin-top: 0;
    }

    > p {
      padding-top: 20px;

      font-size: 16px;
      color: color(white);
      line-height: 2.5;

      opacity: 0.5;
    }

    h3 {
      margin: 0;

      font-size: 16px;

      font-weight: normal;
      color: color(white);
      line-height: 2.5;

      border-bottom: 1px solid color(dark-grey, base);
    }
  }

  .method {
    margin: 60px 0;
    padding: 55px;

    border: 1px solid #e7eaed;

    &__type {
      span {
        @include t-h6;
        margin-bottom: 30px;
        padding: 10px 35px;

        color: color(white);
        text-transform: uppercase;
      }
    }

    > p {
      margin-top: 30px;
    }
  }

  #request,
  #response {
    @include t-h6;
    margin-bottom: 50px;
    margin-top: 60px;

    text-transform: uppercase;
  }
}
