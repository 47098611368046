.input {
  @include t-antialias;

  position: relative;

  border-bottom: solid 1px color(dark-grey, base);

  svg {
    @include al-vertically;
    width: 15px;
    height: 15px;

    right: 0;

    g path {
      fill: color(dark-grey, base);
    }
  }

  input {
    appearance: none;

    width: 100%;
    padding: 20px 0;

    font-weight: 500;
    color: color(dark-grey, base);

    border: none;
  }

  &.error {
    margin-bottom: 30px;

    border-color: color(red, base);
  }

  &.animated.error {
    &::after {
      transform: scaleX(1);
      background-color: color(red, base);
      visibility: visible;
    }
  }
}

@mixin animated-input($pseudoPosition: after) {
  border-bottom: none;

  input {
    border-bottom: 1px solid color(dark-grey, base);
    outline: none;
  }

  &:#{$pseudoPosition} {
    transition: all 0.3s ease-in-out;
    transform: scaleX(0);
    width: 100%;
    height: 1px;
    content: ' ';

    bottom: 0;
    left: 0;

    position: absolute;

    background-color: color(lime, base);
    visibility: hidden;
  }

  &.focused:#{$pseudoPosition} {
    transition: all 0.3s ease-in-out;
    transform: scaleX(1);

    visibility: visible;
  }

  @if $pseudoPosition == before {
    &::after {
      display: none;
    }
  }
}

.animated.input {
  @include animated-input;

  &.use-before {
    @include animated-input($pseudoPosition: before);
  }
}

//
// Removes border-radius on ipad
//

input[type='search'] {
  appearance: none;
}

.radio-option {
  display: flex;
  margin-bottom: 10px;

  &__wrap {
    width: 13px;
    height: 13px;
  }

  &__text {
    margin-left: 17px;
  }

  input {
    display: none;
  }

  svg .icon_checkmark {
    fill: rgba(01, 01, 01, 0);
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: rgba(169, 179, 189, 0.3);

    border-radius: 100px;
  }

  input[type='radio']:checked + .thelabel {
    font-weight: bold;

    background-color: #78c96f;

    svg .icon_checkmark {
      fill: #ffffff;
    }
  }
}
