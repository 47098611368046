.privacy {
  .privacy-cta {
    p {
      width: 100%;

      font-size: 22px;
    }

    &__wrapper {
      margin: 48px 0 24px 0;
      padding-bottom: 60px;

      a {
        @include base-box-shadow();
        font-size: 15px;
        font-weight: 500;
      }

      .container {
        max-width: 844px;
      }

      .button-hover--lime-white:hover * {
        fill: none;
      }

      @include mq($from: tablet) {
        margin: 0;
        padding: 104px 0 164px 0;
      }
    }

    .download-icon {
      width: 60px;
      height: 100%;
    }
  }
}
