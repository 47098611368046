.button-std {
  appearance: none;
  width: 86px;
  height: 64px;

  position: relative;

  background-color: none;

  border: none;

  &::before {
    @include size(100%);
    @include standard-transition;
    content: '';
    display: block;

    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;

    background: color(white);
    box-shadow: 0px 15px 30px 0px color(grey, light);
  }

  &:hover::before {
    transform: scale(0.88);
    box-shadow: 0px 10px 30px 0px color(grey, light);
  }

  &:active::before {
    transform: scale(1);
    box-shadow: 0px 15px 30px 0px color(grey, light);
  }

  &:active {
    outline: 0;
  }

  &:focus {
    outline: 0;
  }

  &.bordered {
    width: 100%;

    font-weight: 500;
    color: color(grey, base);

    border: solid 1px color(dark-grey, base);
  }
}

.btn--mobile--full {
  height: 60px;
  display: block;
  padding: 0 30px;

  position: relative;
  z-index: $z-content-on-grid;

  line-height: 60px;

  svg {
    transform: translateY(-50%);
    top: 50%;
    right: 30px;
    position: absolute;
  }

  @include mq($from: tablet) {
    display: none;
  }
}

/// button for dev profile page and app id

.button-admin {
  $btn-border-radius: 2px !default;

  appearance: none;
  @include standard-transition;

  padding: 0;

  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-decoration: none;

  border-radius: $btn-border-radius;
  border-style: solid;
  border-width: 1px;
  // border-color: transparent;

  &--large {
    width: 100%;
    display: block;

    font-size: 15px;
    line-height: 51px;

    @include mq($from: tablet) {
      max-width: 510px;
    }
  }

  &.bc-lime {
    color: color(lime, base);

    border-color: color(lime, base);

    &:hover {
      color: color(white);

      background-color: color(lime, base);
    }

    &:focus {
      background-color: color(lime, dark);
    }
  }

  &.bc-red {
    color: color(red, base);

    border-color: color(red, base);

    &:hover {
      color: color(white);

      background-color: color(red, base);
    }

    &:focus {
      background-color: color(red, dark);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.floating-button {
  @include standard-transition();
  width: 100%;
  height: 100px;
  padding: 30px;

  color: color(dark-grey, base);

  background-color: inherit;

  &__text {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 2px;

    @include mq($from: tablet) {
      padding-left: 60px;
    }
  }

  svg {
    @include mq($from: tablet) {
      padding-right: 60px;
    }
  }

  svg g {
    fill: color(dark-grey, base);
  }

  @include mq($from: tablet) {
    height: 120px;
    padding: 0 60px;
  }
}

@mixin button {
  $btn-border-radius: 2px !default;

  appearance: none;
  @include standard-transition;
  width: 150px;
  padding: 0;

  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-decoration: none;

  border-radius: $btn-border-radius;
  border-style: solid;

  cursor: pointer;
  border-width: 1px;
  // border-color: transparent;

  //
  //  Sizes
  //

  &--medium {
    width: 290px;
  }

  &--large {
    width: 100%;

    font-size: 15px;
    line-height: 51px;

    @include mq($from: tablet) {
      max-width: 510px;
    }
  }

  &--full {
    width: 100%;
    display: block;
  }

  //
  //  Convience styles for treatments
  //

  &.bc-lime {
    color: color(lime, base);

    border-color: color(lime, base);

    &:hover {
      color: color(white);

      background-color: color(lime, base);
    }

    &:focus {
      background-color: color(lime, dark);
    }
  }

  &.bc-white {
    color: color(white);

    background-color: rgba(color(white), 0.3);

    &:hover {
      color: color(dark-grey, base);

      background-color: color(white);
    }

    &:focus {
      background-color: color(dark-grey, base);
    }
  }

  &.bc-red {
    color: color(red, base);

    &:hover {
      color: color(white);

      background-color: color(red, base);
    }

    &:focus {
      background-color: color(red, dark);
    }
  }

  &.bg-red,
  &.bg-lime {
    color: color(white);
  }

  //
  //  States
  //

  &:hover {
    @include standard-transition;
  }

  &:disabled {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.3;
  }
}

.permissions-button {
  padding: 8px 24px;
}

.button {
  @include button;
}

a.button {
  display: block;

  color: inherit;

  text-decoration: none;
}

//
// SDL 2.0 Button styles
//

@mixin button-standard {
  width: 232px;
  display: block;
  padding: 20px 45px;

  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  border-style: solid;
  cursor: pointer;

  border-width: 1px;

  &:hover {
    background-color: color(ice);
  }
}

.button-standard {
  @include button-standard;
}

//
// Button Helpers
//

.button-hover {
  &--lime-white:hover {
    color: white;

    background-color: color(lime, base);

    * {
      fill: white;
    }
  }

  &--white-lime:hover {
    color: color(lime, base);

    background-color: color(white);

    * {
      fill: color(lime, base);
    }
  }

}

.standrard-transition {
  @include standard-transition;
}

.app-catalog-hover:hover {
  color: #3a4f5f;
  background-color: #fff;

  * {
    fill: #3a4f5f;
  }
}
