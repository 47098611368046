.linklist {
  margin: 40px 0 0 0;
  padding: 0;
  padding-bottom: 40px;

  list-style: none;

  &__object {
    @include mq($from: tablet) {
      margin-top: 50px;
    }
    margin-top: 30px;
  }

  &__both {
    display: flex;

    align-items: center;
  }

  &--small &__item {
    max-width: 420px;
    padding-left: 30px;
    padding-right: 20px;


    border: 1px solid color(grey, light);

    &:hover {
      background-color: color(dark-grey, base);

      .linklist__title {
        color: color(white);
      }

      .linklist__tertiary svg g {
        fill: color(white);
      }

      .linklist__icon .light {
        fill: color(white);
      }

      .linklist__icon .dark {
        fill: color(grey, base);
      }

      .linklist__icon .svg-link g {
        fill: color(white);
      }
    }
  }

  &--small &__icon {
    padding-right: 30px;
  }

  &__item {
    width: 100%;
    display: inline-flex;
    padding: 20px 0;
    padding-bottom: 20px;

    align-items: flex-start;
    justify-content: space-between;


    border-top: 1px solid color(grey, light);
  }

  &__icon {
    .light {
      fill: #465e72;
    }

    .dark {
      fill: #394e60;
    }
  }

  &__title {
    margin-left: 30px;

    flex: 1;

    font-size: 15px;
    font-weight: 500;
    color: color(dark-grey, base);
  }

  &__tertiary {
    max-width: 52%;

    text-align: right;

    svg g {
      fill: color(dark-grey, base);
    }

    // @include mq($until: tablet) {
    //   display: none;
    // }
  }

  p + a {
    @include mq($from: tablet) {
      margin-top: 50px;
    }
    display: block;
    margin-top: 30px;
  }
}
