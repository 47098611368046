.stationary-list {
  max-height: 560px;
  scroll-behavior: smooth;

  &.loading &__pagination-spinner {
    height: 100px;
  }

  &__pagination-spinner {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 200ms ease-out;

    .spinner__path {
      stroke: currentColor;
    }
  }
}

.scrolling-list {
  max-height: 560px;

  overflow: scroll;
  scroll-behavior: smooth;

  &.loading &__pagination-spinner {
    height: 100px;
  }

  &__pagination-spinner {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 200ms ease-out;

    .spinner__path {
      stroke: currentColor;
    }
  }
}
