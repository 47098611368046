.f-12 {
  font-size: 12px;
}

.f-15 {
  font-size: 15px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-25 {
  font-size: 25px;
}

.f-30 {
  font-size: 30px;
}

.f-36 {
  font-size: 36px;
}

.f-40 {
  font-size: 40px;
}

.f-48 {
  font-size: 48px;
}
