.version-tag {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  padding: 2px 5px 2px 6px;

  font-family: $livio-norm;
  font-size: 15px;
  font-weight: 500;
  color: color(dark-grey, base);

  border: 2px solid color(dark-grey, base);
  border-radius: 2px;

  &:hover {
    color: color(white);

    background-color: color(dark-grey, base);
  }
}

.version-tag-list {
  margin-top: -10px;
}
