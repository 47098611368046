$cell-padding: 20px;
$table-padding: 30px;

table {
  width: 100%;
  padding: 0 30px;

  text-align: left;

  border: 1px solid color(grey, light);
  border-left: 0;

  thead {
    @include t-chart-small;
    text-align: left;
    text-transform: uppercase;

    border-bottom: 1px solid color(grey, light);
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid color(grey, light);
  }

  th,
  td {
    padding: $cell-padding;

    &:first-of-type {
      padding-left: $table-padding;
    }

    &:last-of-type {
      padding-right: $table-padding;
    }
  }

  .shared_td {
    max-width: 300px;
  }

  &.def-table td:first-child {
    width: 160px;
  }

  @include mq($from: tablet) {
    margin-top: 40px;

    border-left: 1px solid color(grey, light);
  }
}
