.sliding-panel {
  transform: translateZ(0);
  width: 100vw;
  min-height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: -100vw;
  position: fixed;
  z-index: $z-sliding-panel;

  background-color: color(white);

  &__header {
    height: 60px;
    padding: 0 0 0 30px;

    border-bottom: color(dark-grey, base) 1px solid;

    .title {
      font-weight: 500;
    }

    a.js-dismiss-sliding-panel {
      @include size(60px);
      display: inline-block;

      line-height: 60px;

      text-align: center;
    }
  }

  &__body {
    height: calc(100% - 60px);
    padding: 46px 0;

    position: static;

    opacity: inherit;
    overflow-y: auto;

    img {
      display: block;
      margin: 0 auto;
      max-width: 90%;

      opacity: inherit;
    }
  }

  .icon-dismiss {
    width: 14px;
    height: 14px;

    cursor: pointer;

    g path {
      fill: color(grey, base);
    }
  }

  &.hidden {
    @include animation(slideOut 0.4s);
  }

  &.visible {
    @include animation(slideIn 0.45s);

    display: block;

    left: 0;

    box-shadow: 0px 12px 40px 0px rgba(color(black), 0.15);
    opacity: 1;
  }
}
