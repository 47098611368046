.error-wrapper {
  ul {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-top: 12px;

    justify-content: center;

    color: #e3101d;
    list-style-type: none;

    background-color: rgba(243, 99, 108, 0.1);
    border-radius: 4px;
  }
}

.input-error-wrapper {
  input,
  textarea,
  select {
    border: 1px solid color(red, base);

    &:focus {
      outline: none;
    }
  }
}

.error-wrap {
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
  padding-bottom: 12px;
  padding-left: 0px;
  padding-top: 12px;

  justify-content: center;

  color: #e3101d;

  background-color: rgba(243, 99, 108, 0.1);
  border-radius: 4px;
}
