@mixin theme-gen($name: base, $color: #5da0de) {
  .#{$name}-theme {
    .main-header {
      background: color($color, dark);
      border-bottom: 0;

      .sdl-logo__mark::after {
        display: none;
      }
    }

    .docs-back-button {
      background-color: color($color, base);
      // &::before {
      //   background-color: color($color, base);
      // }

      svg g {
        fill: color($color, dark);
      }
    }

    a.back-to-top:hover {
      background: color($color, light);
    }

    .mobile-select .documentation-select {
      background-color: color($color, light);
    }

    .sidebar-nav {
      a:hover {
        color: color($color, dark);
      }

      a.is-active {
        color: color($color, dark);
      }

      a.with-sub svg g {
        fill: color($color, dark);
      }
    }

    #documentation {
      h4 {
        color: color($color, dark);

        &::before {
          background: color($color, dark);
        }
      }

      &__content a:not(.visual-link):not(.documentation-select):not(.nocolor):not(.headerlink) {
        color: color($color, dark);

        &:hover {
          border-bottom-style: solid;
          border-bottom-width: 1px;
          border-color: color($color, dark);
        }
      }
    }

    //api component specific coloring

    #documentation__content {
      .method__type > span {
        background-color: color($color, base);
      }

      #request,
      #response {
        color: color($color, base);
      }
    }

    .article__pagination svg g {
      fill: color($color, dark);
    }

    .article__pagination a {
      &::after {
        background: color($color, dark);
      }

      &[class^='article__pagination--']:hover {
        color: color(white);

        svg g {
          fill: color(white);
        }
      }

      &.article__pagination--prev:hover {
        &::after {
          left: -1px;
        }
      }

      &.article__pagination--next:hover {
        &::after {
          right: -1px;
        }
      }
    }

    #page-header {
      background: color($color, base);
    }

    .fc-theme {
      color: color($color, base);
    }

    .bg-theme {
      background: color($color, base);
    }

    .svg-theme-fill {
      fill: color($color, base);
    }

    .visual-link__link::after {
      background: color($color, base);
    }

    footer.main-footer {
      .section-head {
        color: color($color, dark);

        &::before {
          background-color: color($color, dark);
        }
      }

      .footer-bar.focused {
        border-bottom-color: color($color, base);
      }

      .footer-bar .footer-bar__action svg g {
        fill: color($color, base);
      }
    }
  }
}

@include theme-gen(docs, lime);
@include theme-gen(android, forest);
@include theme-gen(ios, teal);
@include theme-gen(static, grey);
@include theme-gen(shaid, orange);
@include theme-gen(server, navy);
@include theme-gen(hmi, blue);
@include theme-gen(manticore-blue, manticore-blue);

// extended color palette

@include theme-gen(custom1, gold);
@include theme-gen(custom2, rose);
@include theme-gen(custom3, army);
@include theme-gen(custom4, brown);
@include theme-gen(custom5, lavender);
@include theme-gen(custom6, moss);
@include theme-gen(custom7, sand);
@include theme-gen(custom8, purple);

.static-theme {
  .main-header {
    background: color(dark-grey, base);
    border-bottom: 0;

    .sdl-logo__mark::after {
      display: none;
    }
  }

  p > a {
    color: color(lime, base);
  }

  .docs-back-button {
    &::before {
      background-color: color(dark-grey, base);
    }

    svg g {
      fill: color(dark-grey, base);
    }
  }

  .sidebar-nav {
    a:hover {
      color: color(grey, light);
    }

    li.is-active a.is-active {
      color: color(grey, light);
    }
  }

  footer.main-footer {
    .section-head {
      color: color(dark-grey, base);

      &::before {
        background-color: color(dark-grey, base);
      }
    }

    .footer-bar .footer-bar__action svg g {
      fill: color(dark-grey, base);
    }
  }

  .article__pagination svg g {
    fill: color(dark-grey, base);
  }

  .mobile-select .documentation-select {
    background-color: white;
    background-image: url('../svg/icons/icon-select-closed-black.svg');
    background-position: right 30px center;
    background-repeat: no-repeat;
    background-size: 6px 11px;
  }
}
