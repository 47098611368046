footer.main-footer {
  padding: 80px 0 60px 0;

  border-top: 1px solid color(grey, light);

  .footer-bar {
    display: flex;
    padding: 0 30px 20px;

    color: color(grey, base);

    border-bottom: 1px solid color(grey, light);
    overflow: hidden;

    &.focused {
      border-bottom-color: color(lime, base);
    }

    .footer-bar__action svg {
      right: 0;
      bottom: 8px;
      position: absolute;

      g {
        fill: color(lime, base);
      }
    }

    &__logo {
      display: inline-block;

      vertical-align: top;
    }

    &__content {
      display: inline-block;
      margin-left: 10px;
      max-width: 80%;

      vertical-align: top;

      @include mq($from: tablet) {
        margin-left: 32px;
        max-width: 100%;
      }
    }

    &__action {
      button {
        appearance: none;
        display: none;

        background: 0;
        border: 0;

        @include mq($from: tablet) {
          display: block;
        }
      }
    }

    &--slack {
      .footer-bar__action {
        width: 21px;
        height: 15px;

        top: 50%;
        right: 30px;
        position: absolute;

        transform: translateY(-50%);

        &.is-visible {
          top: 50%;

          transform: translateY(-45%);
        }

        @include mq($from: tablet) {
          @include standard-transition();
          display: inline-block;

          top: 50%;
          right: 0;
          position: absolute;

          transform: translateY(100px);
        }
      }

      @include mq($from: tablet) {
        .footer-bar__action {
          top: 50%;
        }

        &:hover .footer-bar__action {
          transform: translateY(-45%);
        }
      }
    }

    &--email {
      .success-message {
        color: color(lime, base);
      }

      .error-message {
        color: color(red, base);
      }

      .error-message,
      .success-message {
        display: none;

        top: 50%;
        right: 0;
        position: absolute;

        font-size: 18px;

        transform: translateY(-50%);
      }

      form {
        width: 100%;
      }

      input {
        padding: 0 0 0 3px;
      }

      .footer-bar__content {
        max-width: 100%;

        @include mq($from: tablet) {
          width: calc(100% - 60px);
        }
      }

      .footer-bar__action {
        @include mq($from: tablet) {
          @include standard-transition();
          display: inline-block;

          top: 200%;
          right: 0;
          position: absolute;
        }
      }

      @include mq($from: tablet) {
        &.focused .footer-bar__action {
          @include standard-transition;

          top: 5px;
        }
      }
    }

    & + .footer-bar {
      padding-top: 20px;
    }

    @include mq($from: tablet) {
      @include t-antialias;
      display: flex;

      padding: 0 0 17px;

      justify-content: space-between;

      font-size: 30px;
      font-weight: 400;
    }
  }

  input[type='email'] {
    appearance: none;
    width: 100%;

    line-height: initial;

    background: 0;
    border: 0;
    box-shadow: 0;

    &::placeholder {
      color: color(grey, base);
    }

    &:focus {
      outline: 0;
    }
  }

  .footer-links__internal li:not(:last-of-type) {
    @include mq($from: tablet, $until: desktop) {
      margin-right: 20px;
    }
    @include mq($from: desktop) {
      margin-right: 40px;
    }
  }

  .footer-links__info {
    @include mq($from: tablet) {
      display: flex;
      padding-top: 50px;

      align-items: center;
    }
    @include mq($from: wide) {
      padding-top: 0;
    }
  }

  ul.footer-links {
    margin: 0;
    padding: 30px 0 0 30px;

    list-style-type: none;

    li {
      margin-top: 10px;

      &.copyright {
        margin: 30px 0;

        @include mq($from: tablet) {
          margin: 0;
        }
        @include mq($from: wide) {
          text-align: right;
        }
      }

      &.social {
        margin: 60px 0;

        @include mq($from: tablet) {
          margin: 0;

          text-align: center;
        }
      }

      &.social a:not(:last-of-type) {
        display: inline-block;
        margin-right: 45px;

        @include mq($from: tablet) {
          margin-right: 30%;
        }
      }

      @include mq($from: tablet) {
        display: inline-block;
        margin: 0;
      }
    }

    @include mq($from: tablet) {
      display: flex;
      padding: 75px 0 0 0;

      flex-direction: column;
    }
    @include mq($from: wide) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &.docu-footer {
    border-top: 1px solid color(grey, light);
  }

  .livio-loves-mobelux {
    margin-top: 10px;

    a.livio-link:hover svg g {
      fill: #5392ca;
    }

    a.mobelux-link:hover svg g {
      fill: #ff6e0a;
    }

    .footer-heart {
      margin: 0 3px;

      transform: translateY(-2px);
    }

    @include mq($from: tablet, $until: desktop) {
      margin-left: 20px;
      margin-top: 0;
    }
    @include mq($from: desktop) {
      margin-left: 40px;
      margin-top: 0;
    }
  }
}
