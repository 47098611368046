.file-data {
  display: flex;

  color: color(grey, base);

  &__preview {
    width: 100%;
    max-width: 12rem;

    position: relative;

    background-color: color(ice);
    border: 0.06em solid color(grey, light);

    &::before {
      content: '';
      display: block;
      padding-bottom: 66%;
    }

    &::after {
      width: 100%;
      height: 100%;
      content: 'file preview';
      display: flex;

      align-items: center;
      justify-content: center;

      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &__meta {
    margin-left: 1.33em;
  }

  &__remove {
    display: block;
    margin-top: 1em;

    color: color(red, base);
    text-decoration: underline;
  }
}
