.t-a {
  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }
}


.safari-text {
  white-space: nowrap !important;
  text-align: center !important;
}