.alert {
  display: flex;

  &.hidden {
    display: none;
  }

  &::before {
    width: 1.266rem;
    height: 1.266rem;
    content: '';
    display: inline-block;
    margin-right: 8px;

    flex-shrink: 0;

    background-image: url('/static/svg/icons/icon-alert.svg');
  }
}
