.visual-link {
  display: block;

  color: color(black);

  text-decoration: none;

  background: color(white);
  box-shadow: 0px 12px 40px 0px color(grey, light);

  &__body {
    padding: 33px 30px;

    p:last-of-type {
      margin: 0 !important;
    }
  }

  &__title {
    margin-bottom: 15px;

    font-weight: 500;
    text-transform: uppercase;
  }

  &__link {
    min-width: 60px;
    padding: 20px 30px;

    position: relative;

    font-size: 15px;
    text-align: center;

    border-top: 1px solid rgba(color(mirror), 0.9);

    span {
      position: relative;
      z-index: $z-mobile-nav;
    }

    .icon {
      margin-left: auto;
    }
  }

  &:hover {
    .visual-link__link {
      background: color(ice);
    }
  }
}

.visual-link-wrapper {
  margin: 30px 0;

  @include mq($from: tablet) {
    margin: 60px 0;
  }
}

.visual-print-image {
  display: none;
}

.icon--pdf {
  display: none;
}
