.folding-menu {
  &__header {
    justify-content: space-between;
    width: 100%;
    height: 60px;
    display: flex;
    padding: 20px 20px 20px 30px;

    font-weight: 500;
    letter-spacing: 2px;

    text-transform: uppercase;

    border: 1px solid color(ice);
  }

  &__main {
    width: 100%;
    display: none;
    padding: 40px 30px 40px 30px;

    border: 1px solid color(ice);
    border-top: none;

    &.is-open {
      display: block;
    }
  }
}
