.tabs {
  margin-bottom: 48px;

  //
  //  Children
  //

  &__control {
    position: relative;

    font-weight: 500;
    letter-spacing: 2px;

    text-transform: uppercase;

    cursor: pointer;

    &::after {
      transition: all 0.3s ease-in-out;
      transform: scaleX(0);
      width: 100%;
      height: 1px;
      content: ' ';

      bottom: -3px;
      left: 0;

      position: absolute;

      background-color: color(lime, base);
      visibility: hidden;
    }
  }

  .spacer {
    padding: 0 30px;

    cursor: default;

    &::after {
      display: none;
    }
  }

  &__content {
    display: none;
  }

  //
  //  States
  //

  &__control.selected {
    color: color(lime, base);

    // border-bottom: solid 1px color(lime, base);

    &::after {
      transition: all 0.15s ease-in-out;
      transform: scaleX(1);

      visibility: visible;
    }
  }

  &__content.selected {
    display: block;
  }

  //
  //  Media Queries
  //

  @include mq($from: tablet) {
  }
}
