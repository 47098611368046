a {
  text-decoration: none;
}

a.github-link {
  display: inline-block;
  margin-top: 30px;
}

.guide-link {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 30px;
  padding: 20px 30px;

  position: relative;

  border: 1px solid color(grey, light);

  svg #Fill-1 {
    transition: fill 0s ease-in-out 0.1s;
  }

  &.ios-theme {
    &::before {
      background: color(teal, base);
    }
  }

  &.android-theme {
    svg #Fill-1 {
      fill: color(forest, base);
    }

    &::before {
      background: color(forest, base);
    }
  }

  &.docs-theme {
    svg #Fill-1 {
      fill: color(lime, base);
    }

    &::before {
      background: color(lime, base);
    }
  }

  &.shaid-theme {
    svg #Fill-1 {
      fill: color(orange, base);
    }

    &::before {
      background: color(orange, base);
    }
  }

  &.server-theme {
    svg #Fill-1 {
      fill: color(navy, base);
    }

    &::before {
      background: color(navy, base);
    }
  }

  &.hmi-theme {
    svg #Fill-1 {
      fill: color(blue, base);
    }

    &::before {
      background: color(blue, base);
    }
  }

  &.custom1-theme {
    svg #Fill-1 {
      fill: color(gold, base);
    }

    &::before {
      background: color(gold, base);
    }
  }

  &.custom2-theme {
    svg #Fill-1 {
      fill: color(rose, base);
    }

    &::before {
      background: color(rose, base);
    }
  }

  &.custom3-theme {
    svg #Fill-1 {
      fill: color(army, base);
    }

    &::before {
      background: color(army, base);
    }
  }

  &.custom4-theme {
    svg #Fill-1 {
      fill: color(brown, base);
    }

    &::before {
      background: color(brown, base);
    }
  }

  &.custom5-theme {
    svg #Fill-1 {
      fill: color(lavender, base);
    }

    &::before {
      background: color(lavender, base);
    }
  }

  &.custom6-theme {
    svg #Fill-1 {
      fill: color(moss, base);
    }

    &::before {
      background: color(moss, base);
    }
  }

  &.custom7-theme {
    svg #Fill-1 {
      fill: color(sand, base);
    }

    &::before {
      background: color(sand, base);
    }
  }

  &.custom8-theme {
    svg #Fill-1 {
      fill: color(purple, base);
    }

    &::before {
      background: color(purple, base);
    }
  }

  &:hover {
    svg #Fill-1 {
      fill: color(white);
    }
  }

  &__title {
    flex: 1;
    padding: 0 20px;

    font-weight: 500;
    color: color(dark-grey, base);
  }

  &__tertiary {
    svg g {
      fill: color(dark-grey, base);
    }
  }

  &::before {
    @include standard-transition();
    content: '';
    display: block;
    max-width: 0px;

    top: -1px;
    right: 100%;
    bottom: -1px;
    left: -1px;
    position: absolute;
    z-index: -1;
  }

  &:hover {
    .guide-link__title {
      transition: color 0s ease-in-out 0.1s;
      color: color(white);
    }

    .guide-link__tertiary svg g {
      fill: color(white);
    }

    &::before {
      max-width: 500px;

      right: -1px;
    }
  }

  @include mq($from: tablet) {
    max-width: 420px;
  }
}
