.select-wrapper {
  @include base-box-shadow();
  height: 60px;
  display: inline-flex;
  margin-top: 15px;

  z-index: 6;

  cursor: pointer;

  .mobile-select {
    flex: 1;
  }

  &.has-version {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  @include mq($from: tablet) {
    margin-top: 0;
  }
}

.doc-select {
  position: relative;

  &__opacity {
    width: 100%;
    height: 60px;

    top: 0;
    left: 0;
    position: absolute;

    background-color: color(white);
    opacity: 0.2;

    &:hover {
      opacity: 0.4;
    }
  }

  &__main {
    align-items: center;
    width: 100%;
    height: 60px;
    display: flex;
    padding-left: 20px;

    font-weight: 500;
    color: color(white);

    background-image: url('../svg/icons/icon-select-closed.svg');
    background-position: right 20px center;
    background-repeat: no-repeat;

    svg {
      width: 20px;
      height: 20px;
    }

    svg path {
      fill: color(white);
    }
  }

  &__options {
    flex-direction: column;
    display: none;

    transform: translateY(5px);

    & a {
      @include base-box-shadow();
      width: 100%;
      max-height: 60px;
      min-width: 230px;
      padding: 0 30px;

      font-weight: 500;
      color: color(dark-grey, base);
      line-height: 60px;
      text-decoration: none;

      background-color: color(white);
      border-top: 1px solid rgba(color(grey, light), 0.6);

      cursor: pointer;

      &:first-of-type {
        border-top: 0;
      }

      &:hover {
        background-color: color(ice);
      }

      @include mq($from: tablet) {
        min-width: 280px;
      }
    }

    &.has-version {
      align-items: flex-end;
    }

    @include mq($from: tablet) {
      align-items: flex-end;
    }
  }

  &--small {
    width: 85px;
  }

  &--large {
    @include mq($from: tablet) {
      width: 280px;
    }
  }

  &.is-open {
    .doc-select__main {
      background-image: url('../svg/icons/icon-select-open.svg');
    }

    .doc-select__options {
      display: flex;
    }
  }
}
