//
// Border widths and styles
//

.border {
  &-a {
    border: 1px solid;
  }

  &-b {
    border-bottom: 1px solid;
  }

  &-t {
    border-top: 1px solid;
  }

  &-l {
    border-left: 1px solid;
  }

  &-r {
    border-right: 1px solid;
  }

  &--2px {
    border-width: 2px;
  }

  &--3px {
    border-width: 3px;
  }

  &--dotted {
    border-style: dotted;
  }
}

//
// Border color
//

.remove-btn-border {
  border: 1px solid #F3636C;
}

.bc {
  &-red {
    border-color: color(red, base);
  }

  &-lime {
    border-color: color(lime, base);
  }

  &-white {
    border-color: color(white);
  }

  &-charcoal {
    border-color: #394e60;
  }

  &-grey {
    border-color: color(grey, base);

    &--dark {
      border-color: color(grey, dark);
    }

    &--light {
      border-color: color(grey, light);
    }
  }
}

// Tachyons

.bln {
  border-left: none;
}

.brn {
  border-right: none;
}

.btn {
  border-top: none;
}

.bbn {
  border-bottom: none;
}

//
// Border colors for .input-group
//

.input-border {
  &-grey {
    &--light {
      & input,
      select {
        border: 1px solid color(grey, light);
      }
    }
  }
}
