/* Type Scale */

.f1 {
  font-size: 3 * $u;
}

.f2 {
  font-size: 2.25 * $u;
}

.f3 {
  font-size: 1.5 * $u;
}

.f4 {
  font-size: 1.25 * $u;
}

.f5 {
  font-size: 1 * $u;
}

.f6 {
  font-size: 0.875 * $u;
}

.f7 {
  font-size: 0.75 * $u;
} /* Small and hard to read for many people so use with ext * $ue caution */

@media #{$breakpoint-not-small} {
  .f-6-ns,
  .f-headline-ns {
    font-size: 6 * $u;
  }

  .f-5-ns,
  .f-subheadline-ns {
    font-size: 5 * $u;
  }

  .f1-ns {
    font-size: 3 * $u;
  }

  .f2-ns {
    font-size: 2.25 * $u;
  }

  .f3-ns {
    font-size: 1.5 * $u;
  }

  .f4-ns {
    font-size: 1.25 * $u;
  }

  .f5-ns {
    font-size: 1 * $u;
  }

  .f6-ns {
    font-size: 0.875 * $u;
  }

  .f7-ns {
    font-size: 0.75 * $u;
  }
}
@media #{$breakpoint-medium} {
  .f-6-m,
  .f-headline-m {
    font-size: 6 * $u;
  }

  .f-5-m,
  .f-subheadline-m {
    font-size: 5 * $u;
  }

  .f1-m {
    font-size: 3 * $u;
  }

  .f2-m {
    font-size: 2.25 * $u;
  }

  .f3-m {
    font-size: 1.5 * $u;
  }

  .f4-m {
    font-size: 1.25 * $u;
  }

  .f5-m {
    font-size: 1 * $u;
  }

  .f6-m {
    font-size: 0.875 * $u;
  }

  .f7-m {
    font-size: 0.75 * $u;
  }
}
@media #{$breakpoint-large} {
  .f-6-l,
  .f-headline-l {
    font-size: 6 * $u;
  }

  .f-5-l,
  .f-subheadline-l {
    font-size: 5 * $u;
  }

  .f1-l {
    font-size: 3 * $u;
  }

  .f2-l {
    font-size: 2.25 * $u;
  }

  .f3-l {
    font-size: 1.5 * $u;
  }

  .f4-l {
    font-size: 1.25 * $u;
  }

  .f5-l {
    font-size: 1 * $u;
  }

  .f6-l {
    font-size: 0.875 * $u;
  }

  .f7-l {
    font-size: 0.75 * $u;
  }
}
