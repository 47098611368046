.registration {
  #privacy-form-header {
    width: intrinsic; /* safari oddity.  same as max-content */
    width: -moz-max-content; /*firefox doesn't seem to support just max-content yet */
    width: max-content;
    display: table;

    .t-privacy-policy--rule {
      width: initial;
      display: block;
    }
  }

  .billboard {
    padding-bottom: 60px;

    position: relative;
  }

  .tabs {
    margin-bottom: 20px;
  }

  a {
    color: color(lime, base);
  }

  .form-wrapper {
    position: relative;

    input:not([type='submit']) {
      padding: 15px 0;

      background: none;
      outline: none;
    }

    .input-group {
      margin-bottom: 15px;
    }

    // Hello

    .t-h2 {
      display: block;
      margin-bottom: 31px;
    }

    .t-privacy-policy--rule {
      width: 264px;
      margin-bottom: 24px;
      margin-top: 4px;
    }

    .sdl-logo {
      width: 150px;
      display: block;
      margin: 0 auto;
      padding: 60px 0;

      text-align: center;

      &__mark,
      &__text {
        display: inline-block;
      }
    }

    .bg-white {
      margin: 0 auto;
      max-width: 510px;
      padding: 54px 34px;

      @include mq($from: tablet) {
        padding: 54px 94px;
      }
    }

    .button-std {
      width: 100%;
      display: block;

      line-height: 60px;
      text-align: center;

      &::before {
        display: none;
      }

      &.bordered {
        @include standard-transition;
        color: $dark-gray;

        &:hover {
          color: white;

          background-color: $dark-gray;
        }
      }
    }
  }

  .reg-checkboxes {
    margin: 40px 0 30px;

    label {
      color: $dark-gray;
    }
  }

  div.or-break {
    justify-content: space-between;
    align-items: center;
    display: flex;

    .line {
      flex: 1;

      border-top: 1px solid color(grey, light);
    }

    .text {
      margin: 20px;

      color: #9b9b9b;
    }
  }

  .social-login {
    input[type='submit'] {
      margin: 20px 0 0;
    }

    a.button-std:not(.forgot-pw) {
      justify-content: center;
      align-items: center;
      display: flex;

      color: color(white);

      svg,
      span {
        margin: 0 5px;
      }

      svg {
        transform: translateY(-2px);
      }

      & + a {
        margin-top: 10px;
      }
    }

    a.forgot-pw {
      margin: 10px 0 0;
    }

    > label {
      display: block;

      font-weight: 500;

      text-align: center;
    }

    label:first-of-type {
      margin-top: 34px;
    }

    .privacy-note {
      margin-top: 1.25rem;

      font-size: 0.875rem;
      color: #496173;
    }
  }
}
