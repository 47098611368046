.manticore-page {
  .static-page-header {
    background: color(manticore-blue, base);
  }

  p code {
    padding: 2px 5px;

    background: color(ice);
    border: 1px solid color(grey, light);
  }

  code {
    font-family: 'LivioMono', monospace;
    font-size: 15px;
    color: color(black);

    border-radius: 2px;
  }

  .codehilite {
    margin-top: 15px;
    padding: 10px 30px;

    font-family: 'LivioMono', monospace;
    letter-spacing: 0.3px;

    line-height: 1.33;

    .btn-row {
      margin-left: -1rem;
    }
  }

  .button-link {
    align-items: center;
    justify-content: space-between;
    @include standard-transition;
    width: 100%;
    height: 80px;
    display: flex;
    padding: 0 20px;

    background-color: color(white);

    &:hover {
      background-color: #e7eaed;
    }
  }

  .static-block:last-of-type {
    margin-bottom: 0;
    padding-bottom: 100px;
    padding-top: 30px;
  }
}
