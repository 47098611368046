// * $uoves the global livio input styles

/* @TODO: Can  * $uove once input component stlyes are refactored to not select input tag */

input.manticore-input {
  width: 6.6 * $u;
  padding: initial;

  color: $dark-gray;

  background-color: initial;
  border: 1px solid $silver;
}
