.fw {
  &-400 {
    @include t-antialias;
    font-weight: 400;
  }

  &-500 {
    @include t-antialias;
    font-weight: 500;
  }

  &-600 {
    @include t-antialias;
    font-weight: 600;
  }
}
