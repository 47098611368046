.left-05 {
  left: 0.5 * $u;
}

.right-05 {
  right: 0.5 * $u;
}

.left-1 {
  left: $u;
}

.right-1 {
  right: $u;
}

.bottom-1 {
  bottom: $u;
}

.left-1 {
  left: $u;
}

.left-2 {
  left: 2 * $u;
}

.right-2 {
  right: 2 * $u;
}

.bottom-2 {
  bottom: 2 * $u;
}

.left-2 {
  left: 2 * $u;
}

.left-3 {
  left: 3 * $u;
}

.right-3 {
  right: 3 * $u;
}

.bottom-3 {
  bottom: 3 * $u;
}

.left-3 {
  left: 3 * $u;
}

.top-4 {
  top: 4 * $u;
}

.bottom-2px {
  bottom: 2px;
}

.right-2px {
  right: 2px;
}

.bottom--04 {
  bottom: -0.4 * $u;
}

.left-center {
  left: 50%;
}

.top-center {
  top: 50%;
}

@media #{$breakpoint-not-small} {
  .left-2-ns {
    left: 2 * $u;
  }

  .right-2-ns {
    right: 2 * $u;
  }

  .bottom-2-ns {
    bottom: 2 * $u;
  }

  .left-2-ns {
    left: 2 * $u;
  }

  .left-3-ns {
    left: 3 * $u;
  }

  .right-3-ns {
    right: 3 * $u;
  }

  .bottom-3-ns {
    bottom: 3 * $u;
  }

  .left-3-ns {
    left: 3 * $u;
  }

  .top-4-ns {
    top: 4 * $u;
  }

  .left-center-ns {
    left: 50%;
  }

  .top-center-ns {
    top: 50%;
  }

  .bottom-2px-ns {
    bottom: 2px;
  }

  .right-2px-ns {
    right: 2px;
  }
}
@media #{$breakpoint-medium} {
  .left-2-m {
    left: 2 * $u;
  }

  .right-2-m {
    right: 2 * $u;
  }

  .bottom-2-m {
    bottom: 2 * $u;
  }

  .left-2-m {
    left: 2 * $u;
  }

  .left-3-m {
    left: 3 * $u;
  }

  .right-3-m {
    right: 3 * $u;
  }

  .bottom-3-m {
    bottom: 3 * $u;
  }

  .left-3-m {
    left: 3 * $u;
  }

  .top-4-m {
    top: 4 * $u;
  }

  .left-center-m {
    left: 50%;
  }

  .top-center-m {
    top: 50%;
  }

  .bottom-2px-m {
    bottom: 2px;
  }

  .right-2px-m {
    right: 2px;
  }
}
@media #{$breakpoint-large} {
  .left-2-l {
    left: 2 * $u;
  }

  .right-2-l {
    right: 2 * $u;
  }

  .bottom-2-l {
    bottom: 2 * $u;
  }

  .left-2-l {
    left: 2 * $u;
  }

  .left-3-l {
    left: 3 * $u;
  }

  .right-3-l {
    right: 3 * $u;
  }

  .bottom-3-l {
    bottom: 3 * $u;
  }

  .left-3-l {
    left: 3 * $u;
  }

  .top-4-l {
    top: 4 * $u;
  }

  .left-center-l {
    left: 50%;
  }

  .top-center-l {
    top: 50%;
  }

  .bottom-2px-l {
    bottom: 2px;
  }

  .right-2px-l {
    right: 2px;
  }
}
