.static-page-header {
  @include mq($from: tablet) {
    padding: 70px 0;

    &--small {
      padding: 45px 0;
    }
  }

  @include mq($from: $nav-breakpoint) {
    margin-top: 60px;
  }
  margin-top: 50px;
  padding: 30px 0;

  background: #465e72;

  &--green {
    background: #78c96f;
  }

  h1 {
    @include t-antialias;
    margin: 0;

    color: color(white);
    font-weight: normal;

    text-align: center;

    &:not(.t-default) {
      @include mq($from: tablet) {
        font-size: 60px;
      }
      font-size: 30px;
    }

    a {
      color: white;
    }
  }

  &--dark {
    @include mq($from: tablet) {
      padding: 60px 0;
    }
    padding: 30px;

    text-align: center;

    background: color(ice);

    h1 {
      font-size: 20px;
      font-weight: 500;
      color: color(dark-grey, base);
    }

    &.maxheight {
      max-height: 180px;
    }
  }
}



.static-page__section {
  @include mq($from: tablet) {
    padding: 120px 0;

    &.m-padding {
      padding: 70px 0;
    }
  }
  width: 100%;
  padding: 60px 0 0;

  position: relative;

  .excerpt {
    margin-bottom: 40px;
  }

  .post-cta {
    @include button;
    margin-top: 44px;

    max-width: 100% !important;

    letter-spacing: 2.5px;
  }

  &.no-x-padding {
    padding: 0;
  }

  .illustration {
    @include mq($from: tablet) {
      margin-bottom: 0;
    }
    width: 100%;
    height: 300px;
    margin-bottom: 80px;

    &.logo {
      max-width: 450px;

      img {
        width: 100%;
      }
    }
  }

  .content {
    .section-head {
      // margin: 22px 0 50px 0;

      &__button--desktop {
        color: color(dark-grey, base);
      }

      svg g {
        fill: color(dark-grey, base);
      }
    }
  }

  &.about-information .content {
    p + .section-head {
      @include mq($from: tablet) {
        margin-top: 120px;
      }
      margin-top: 80px;
    }
  }

  &--reverse {
    @include mq($from: tablet) {
      .container {
        flex-direction: row-reverse;

        .illustration {
          @include clearfix();
          margin-right: 0;

          svg {
            float: right;
          }
        }
      }
    }
  }
  // &--center {
  //     justify-content: center;
  // }

  .btn--mobile--full {
    margin-top: 60px;
  }

  .static-block {
    padding: 60px 0;

    border-bottom: 1px solid color(grey, light);

    &__icon {
      margin-right: 30px;
      margin-top: 4px;

      &.android svg #Fill-1 {
        fill: color(forest, base);
      }

      &.ios svg #Fill-1 {
        fill: color(teal, base);
      }

      &.core svg #Fill-1 {
        fill: color(lime, base);
      }
    }

    &.no-t-padding {
      padding-top: 0;
    }

    &:last-of-type {
      margin-bottom: 60px;

      border-bottom: 0;
    }
  }

  a.static-block {
    color: color(dark-grey, base);
  }

  .container {
    &:not(.no-flex) {
      @include mq($from: tablet) {
        display: flex;

        align-items: flex-start;
        justify-content: space-between;

        .illustration,
        .content {
          flex: 1;
        }

        .content {
          margin-left: 0%;
          max-width: 100%;
        }

        .content-top {
          margin-left: 5%;
          max-width: 70%;
        }
      }
    }

    &--center:not(.no-flex) {
      justify-content: center;
    }
  }

  &.terms {
    @include mq($from: tablet) {
      padding-top: 100px;
    }

    p.t-default {
      margin-bottom: 30px;
    }

    p.t-h4 {
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}

.static-pb-3 {
  padding-bottom: 3rem;
}

.app-page-section {
  padding: 60px 0;
}

.author-row {
  margin-top: 22px;

  img {
    @include size(40px);
    margin-right: 20px;
  }

  a {
    font-weight: 500;
    color: color(dark-grey, base);
  }

  p {
    @include mq($from: tablet) {
      display: inline-block;

      vertical-align: middle;
    }

    &:first-of-type {
      color: color(dark-grey, base);
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &--post {
    @include mq($from: tablet) {
      margin-bottom: 60px;
    }
    margin-bottom: 30px;
  }
}

.static-post-content {
  a {
    color: color(blue, base);

    &:hover {
      text-decoration: underline;
    }
  }

  code {
    @include mq($from: tablet) {
      word-break: normal;
    }
    color: color(black);
    word-break: break-all;

    background: color(ice);
    border: 1px solid color(grey, light);
  }

  ol li,
  ul li {
    @include t-antialias;
    margin: 10px 0;

    font-size: 20px;
    font-weight: 400;
    color: #394e60;
    line-height: 30px;
  }

  ul li code {
    font-size: 20px;
  }

  h3 {
    @include t-h3;
    margin: 20px 0 0;

    font-weight: 400;
  }

  hr {
    margin: 20px 0;
  }

  p {
    @include t-default;
    @include t-antialias;
    color: color(dark-grey, base);

    &:first-of-type {
      @include mq($from: tablet) {
        margin-top: 60px;
      }
      margin-top: 30px;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }

    & + p {
      margin-top: 20px;
    }
  }

  h3 {
    @include t-h3();
  }
}
