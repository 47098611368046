// Tachyons

.shadow-1 {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.bg-shadow {
  box-shadow: 0 20px 70px 0 #efefef;

  &-m {
    @media #{$breakpoint-medium} {
      box-shadow: 0 20px 70px 0 #efefef;
    }
  }

  &--dark {
    box-shadow: 0 20px 70px 0 rgba(color(black), 0.15);

    &-m {
      @media #{$breakpoint-medium} {
        box-shadow: 0 20px 70px 0 rgba(color(black), 0.15);
      }
    }
  }
}
