.translateY-0 {
  transform: translateY(0);
}

.translateY-100 {
  transform: translateY(100%);
}

.translateY--50 {
  transform: translateY(-50%);
}

.translateX-0 {
  transform: translateX(0);
}

.translateX--50 {
  transform: translateX(-50%);
}

.translateX--100 {
  transform: translateX(-100%);
}

.translate--50-100 {
  transform: translate(-50%, 100%);
}

.translate--50-50 {
  transform: translate(-50%, 50%);
}

.translate--50--50 {
  transform: translate(-50%, -50%);
}

.origin-center {
  transform-origin: 50% 50%;
}

.origin-top-left {
  transform-origin: 0 0;
}

.bf-h {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
