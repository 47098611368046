html {
  font-size: 15px;
}

body {
  color: color(dark-grey, base);

  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;

  &.no-scroll {
    overflow: hidden;
  }
}

svg {
  max-width: 100%;
}

.container {
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width;

  &--narrow {
    @include mq($from: tablet) {
      max-width: $container-narrow-max-width;
    }
  }

  &--medium-less {
    @include mq($from: tablet) {
      // max-width: $container-medium-width;
      max-width: 860px;
    }
  }

  &--medium {
    @include mq($from: tablet) {
      max-width: $container-medium-width;
    }
  }

  &--full {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &--x-small {
    @include mq($from: tablet) {
      max-width: 510px;
    }
  }

  &--with-sidebar {
    @include mq($from: tablet) {
      margin-left: 90px;
      margin-right: 90px;
    }
  }
}

.section-head__container {
  width: calc(100% - 60px);
  margin: 20px auto 0;

  @include mq($from: tablet) {
    width: 100%;
    margin: 0;
  }
}


.page-error {
  padding: 160px 0 100px;

  text-align: center;

  .illustration {
    margin-bottom: 60px;

    svg {
      width: 95%;
      height: auto;
      max-width: 928px;
    }
  }
}

img#ee {
  transform: rotate(20deg);
  transition: left 0.3s ease-in-out;
  width: 500px;
  height: auto;

  bottom: -50px;
  left: -500px;
  position: fixed;

  &.is-visible {
    left: -100px;
    z-index: 10000;
  }
}

.snippet {

  button {
      background: none;
      border: 1px solid #fff;
      visibility: hidden;

      p {
        font-size: 12px;
      }
    }

    button:focus {
      outline: 0;
    }

    .copy-confirm {
      font-size: 10px;
      margin: auto 0;
      margin-left: 1rem;
      visibility: hidden;

    }
}

.user-notification-dot {
  position: relative;
  top: -8px;
  display: inline-block;
  width: 5px;
  height: 5px;

  background-color: #F3636C;
  border-radius: 50%;

  /* This is a a couple of kludger fixes for these dots on the developer apps list. There this user-notification-dot is
  * inside a div with flexbox stuff and a .flex class. We could do .flex .user-notification-dot to fix it, but that risks
   * breaking it everywhere else I already know it displays correctly, so I am just adding a new modifier class here
   * to make it reusable (hopefully) but on effect the one place I need it to
   */
  &--devapps {
    top: 0;
    right: -3px;
  }
  &--webengine {
    top: 5px;
    right: -3px;
  }
}
