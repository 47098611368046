.modal {
  display: none;

  &[open] {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    padding: 20px;

    align-items: center;
    justify-content: center;

    top: 0;
    left: 0;

    position: fixed;
    z-index: 10;

    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
  }
}
