.search-results {
  #container .container {
    @include mq($from: tablet) {
      width: 100%;
    }
  }

  .article__pagination {
    svg g {
      fill: color(lime, base);
    }

    a {
      margin: 0 auto;
    }

    a.article__pagination--next::after {
      background-color: color(lime, base);
    }

    a.article__pagination--next:hover {
      color: color(white);

      border-color: color(lime, base);

      svg g {
        fill: color(white);
      }

      &::after {
        right: -1px;
      }
    }
  }

  .results {
    .search-form {
      margin: 0 0 47px;
      padding-left: 0;
      padding-top: 94px;

      input {
        width: 100%;
        padding: 10px 10px 11px 38px;

        color: color(dark-grey, base);

        &::placeholder {
          font-size: 20px;
          font-weight: 400;
          color: color(grey, base);
        }
      }

      svg {
        width: 16px;
        height: 20px;

        top: 29px;
        left: 14px;
        position: absolute;

        &.icon-search {
          width: 17.8px;
          height: 15.8px;

          top: 25px;
          left: 0;

          @include mq($from: tablet) {
            top: 35px;
          }
        }

        &.icon-arrow-right {
          @include al-vertically;
          right: 29px;
          left: auto;
        }
      }

      .animated.input {
        &::before {
          top: 50px;
          bottom: auto;

          @include mq($from: tablet) {
            top: 63px;
          }
        }
      }

      @include mq($from: tablet) {
        margin-bottom: 37px;
        padding-top: 0;
      }
    }

    .filter-checkboxes {
      display: none;

      @include mq($from: tablet) {
        display: block;
      }
    }

    .filter-select {
      .documentation-select {
        background-image: url('../svg/icons/icon-select-closed-black.svg');
        background-position: right 24px center;
        background-repeat: no-repeat;
      }

      @include mq($from: tablet) {
        display: none;
      }
    }

    .filter-results {
      margin-bottom: 60px;

      fieldset {
        border: none;
      }

      legend {
        @include mq($until: tablet) {
          margin: 47px 0 44px;

          font-size: 50px;
        }
        @include mq($from: tablet) {
          margin-bottom: 28px;
        }
      }

      .checkbox-button {
        margin-left: 30px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        @include mq($from: tablet) {
          width: 150px;
        }
      }

      .fx-wrapper {
        margin-bottom: 30px;
      }

      @include mq($from: tablet) {
        margin-bottom: 10px;
      }
    }

    svg.icon-documents,
    svg.icon-tutorial {
      &.core {
        .fill {
          fill: color(lime, base);

          &-detail {
            fill: color(white);
          }
        }

        .dropshadow {
          fill: color(dark-grey, base);
        }
      }

      &.ios {
        .fill {
          fill: color(teal, base);

          &-detail {
            fill: color(white);
          }
        }

        .dropshadow {
          fill: color(dark-grey, base);
        }
      }
    }

    .results__list {
      max-width: 100%;

      a {
        display: block;
      }

      .no-search-results {
        margin-top: 50px;
        padding-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;

        color: color(dark-grey, base);

        @include mq($from: tablet) {
          padding-left: 0;
          padding-right: 0;

          border-bottom: 1px solid color(dark-grey, base);
        }

        .no-search-results-list {
          list-style: none;
          padding: 0;

          .no-search-results-list-item {
            margin-bottom: 26px;

            a {
              display: inline;

              color: color(lime, base);
            }
          }
        }
      }



      &__item {
        width: 100%;
        padding-bottom: 55px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 58px;

        color: color(dark-grey, base);

        border-bottom: color(dark-grey, base) 1px solid;
        overflow-x: scroll;

        .column {
          &:first-of-type {
            width: 16px;
          }

          &:last-of-type {
            width: calc(100% - 16px);
            padding-left: 15px;
          }
        }

        svg {
          top: 4px;
          position: relative;
        }

        .result-title {
          margin-bottom: 11px;

          font-size: 20px;

          font-weight: 500;
          line-height: 30px;
        }

        .result-codesnippet {
          display: block;
          margin: 12px 0 0;
        }

        .result-location {
          margin-top: 30px;

          line-height: 20px;
        }

        .result-body {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;

          @include mq($from: tablet) {
            margin: 10px 0 0;
          }
        }

        .result-body__highlight {
          background-color: color(lemon);
        }

        &:first-of-type {
          @include mq($until: tablet) {
            border-top: color(dark-grey, base) 1px solid;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }

        @include mq($from: tablet) {
          padding-bottom: 50px;
          padding-left: 0;
          padding-right: 0;
          padding-top: 47px;
        }
      }
    }

    @include mq($from: tablet) {
      margin: 0 auto;
      max-width: 689.3px;
      padding-top: 183px;
    }
  }

  // color adjustments for /search nav

  // .desktop-search {
  //     border-left: 1px solid $light-grey;
  // }

  // .desktop-search__close svg {
  //     fill: #394e67;
  // }
  //
  // .desktop-search__form-wrapper input {
  //     color: #394e67;
  //
  //     &::placeholder {
  //         color: #a6b3bd;
  //         opacity: 1;
  //     }
  // }
}

// color themes for search results

.sdl_android .fill,
.sdl_android_guides #Fill-1 {
  fill: color(forest, base);
}

.sdl_core_guides #Fill-1 {
  fill: color(lime, base);
}

.sdl_hmi_integration_guidelines .fill {
  fill: color(blue, base);
}

.sdl_ios .fill,
.sdl_ios_guides #Fill-1 {
  fill: color(teal, base);
}

.sdl_server .fill {
  fill: color(navy, base);
}

.sdl_shaid_docs {
  fill: color(orange, base);
}
