.mw1 {
  max-width: 1 * $u;
}

.mw2 {
  max-width: 2 * $u;
}

.mw3 {
  max-width: 4 * $u;
}

.mw4 {
  max-width: 8 * $u;
}

.mw5 {
  max-width: 16 * $u;
}

.mw6 {
  max-width: 32 * $u;
}

.mw7 {
  max-width: 48 * $u;
}

.mw8 {
  max-width: 64 * $u;
}

.mw9 {
  max-width: 96 * $u;
}

/* Max Width String Properties */

.mw-none {
  max-width: none;
}

@media #{$breakpoint-not-small} {
  .mw-100-ns {
    max-width: 100%;
  }

  .mw1-ns {
    max-width: 1 * $u;
  }

  .mw2-ns {
    max-width: 2 * $u;
  }

  .mw3-ns {
    max-width: 4 * $u;
  }

  .mw4-ns {
    max-width: 8 * $u;
  }

  .mw5-ns {
    max-width: 16 * $u;
  }

  .mw6-ns {
    max-width: 32 * $u;
  }

  .mw7-ns {
    max-width: 48 * $u;
  }

  .mw8-ns {
    max-width: 64 * $u;
  }

  .mw9-ns {
    max-width: 96 * $u;
  }

  .mw-none-ns {
    max-width: none;
  }
}
@media #{$breakpoint-medium} {
  .mw-100-m {
    max-width: 100%;
  }

  .mw1-m {
    max-width: 1 * $u;
  }

  .mw2-m {
    max-width: 2 * $u;
  }

  .mw3-m {
    max-width: 4 * $u;
  }

  .mw4-m {
    max-width: 8 * $u;
  }

  .mw5-m {
    max-width: 16 * $u;
  }

  .mw6-m {
    max-width: 32 * $u;
  }

  .mw7-m {
    max-width: 48 * $u;
  }

  .mw8-m {
    max-width: 64 * $u;
  }

  .mw9-m {
    max-width: 96 * $u;
  }

  .mw-none-m {
    max-width: none;
  }
}
@media #{$breakpoint-large} {
  .mw-100-l {
    max-width: 100%;
  }

  .mw1-l {
    max-width: 1 * $u;
  }

  .mw2-l {
    max-width: 2 * $u;
  }

  .mw3-l {
    max-width: 4 * $u;
  }

  .mw4-l {
    max-width: 8 * $u;
  }

  .mw5-l {
    max-width: 16 * $u;
  }

  .mw6-l {
    max-width: 32 * $u;
  }

  .mw7-l {
    max-width: 48 * $u;
  }

  .mw8-l {
    max-width: 64 * $u;
  }

  .mw9-l {
    max-width: 96 * $u;
  }

  .mw-none-l {
    max-width: none;
  }
}
