//
// Headers
//

@mixin t-h1 {
  font-size: 50px;
  line-height: 60px;

  @include mq($from: tablet) {
    font-size: 100px;
    line-height: 110px;
  }
}

.t-h1 {
  @include t-h1;
}

@mixin t-h2 {
  font-size: 30px;
  line-height: 40px;

  @include mq($from: tablet) {
    font-size: 40px;
    line-height: 50px;
  }
}

.t-h2 {
  @include t-h2;
}

@mixin t-h3 {
  font-size: 20px;
  line-height: 30px;

  @include mq($from: tablet) {
    font-size: 30px;
    line-height: 40px;
  }
}

.t-h3 {
  @include t-h3;

  &--normal {
    @include t-antialias;

    font-weight: normal;
  }
}

@mixin t-h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;

  @include mq($from: tablet) {
    font-size: 20px;
    line-height: 30px;
  }
}

.t-h4 {
  @include t-h4;

  &--normal {
    @include t-antialias;

    font-weight: normal;
  }
}

@mixin t-h5 {
  letter-spacing: 2px;
  line-height: 20px;
}

.t-h5 {
  @include t-h5;
}

@mixin t-h6 {
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 20px;
}

.t-h6 {
  @include t-h6;
}

@mixin t-light-header {
  @include t-antialias;
  font-size: 40px;
  line-height: 50px;

  @include mq($from: tablet) {
    font-size: 60px;
    line-height: 64px;
  }
}

.t-light-header {
  @include t-light-header;
}

//
// dark headers
//

@mixin t-xsmall {
  font-size: 10px;
  letter-spacing: 1.2px;
  line-height: 20px;
}

.t-xsmall {
  @include t-xsmall;
}

//
// Text
//

@mixin t-default {
  font-size: 18px;
  line-height: 30px;

  @include mq($from: tablet) {
    font-size: 20px;
  }
}

.t-default {
  @include t-default;
}

@mixin t-default-small {
  font-size: 15px;
  line-height: 1.44;

  @include mq($from: tablet) {
    font-size: 18px;
  }
}

.t-default-small {
  @include t-default-small;
}

@mixin t-default-docu {
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 25px;

  @include mq($from: tablet) {
    font-size: 20px;
    line-height: 30px;
  }
}

.t-default-docu {
  @include t-default-docu;
}

@mixin t-small {
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.t-small {
  @include t-small;

  &--tracked {
    letter-spacing: 2px;
  }
}

@mixin t-chart-small {
  font-size: 10px;
  letter-spacing: 2px;
  line-height: 12px;
}
@mixin t-chart {
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 20px;
}
@mixin t-chart-bold {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.t-chart {
  @include t-chart;

  &--bold {
    font-weight: bold;
  }
}

@mixin t-x-large {
  font-size: 200px;

  @include mq($from: tablet) {
    font-size: 300px;
  }
}

.t-x-large {
  @include t-x-large;
}

.t-bold {
  font-weight: 600;
}

.t-medium {
  font-weight: 500;
}

.t-regular {
  font-weight: 400;
}

.t-o-header {
  color: color(white);

  opacity: 0.3;
}

@mixin t-results-h1 {
  font-weight: 100;

  @include mq($from: tablet) {
    font-size: 80px;
    letter-spacing: 1.2px;
  }
}

.t-results-h1 {
  @include t-results-h1;
}

@mixin label-header {
  @include t-antialias;
  display: block;
  margin-bottom: 14px;

  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1.5px;
  line-height: 20px;

  text-transform: uppercase;
}

.label-header {
  @include label-header;
}

@mixin tagline {
  font-size: 30px;
  line-height: 1.5;

  @include mq($from: tablet) {
    font-size: 36px;
  }
}

.tagline {
  @include tagline;
}
