@mixin theme-gen($name: base, $color: #5da0de) {
    .#{$name}-theme {
      .collapsible-docs-nav {
        background: color($color, dark);
        border-bottom: 0;
      }
    }
  }

  @include theme-gen(docs, lime);
  @include theme-gen(android, forest);
  @include theme-gen(ios, teal);
  @include theme-gen(static, grey);
  @include theme-gen(shaid, orange);
  @include theme-gen(server, navy);
  @include theme-gen(hmi, blue);
  @include theme-gen(manticore-blue, manticore-blue);

  // extended color palette

  @include theme-gen(custom1, gold);
  @include theme-gen(custom2, rose);
  @include theme-gen(custom3, army);
  @include theme-gen(custom4, brown);
  @include theme-gen(custom5, lavender);
  @include theme-gen(custom6, moss);
  @include theme-gen(custom7, sand);
  @include theme-gen(custom8, purple);

.collapse-outer {
    width: 100%;
    max-width: 400px;
    top: 65px;
    right: 12px;
    position: fixed;
    z-index: 9;


    button.collapsible-docs-nav {
        // background: color($color, dark);
        border-bottom: 0;

        color: white;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 16px;
            margin-left: 8px;
        }
    }

    .collapsible-content {
        padding: 18px;
        // display: block;
        overflow: hidden;
        background-color: #f1f1f1;

        .collapsible-nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin: 10px 0;

            svg {
                margin-right: 8px;
            }

            .collapsible-nav-text {
              color: #394e60 !important;
              font-size: 12px !important;
            }
        }


      ul.collapsible-nav-ul {
        list-style: none;
        padding: 0;
        height: 100%;
        max-height: 200px;
        overflow-y: auto;

        li.collapsible-nav-item {
            word-break: break-all;
            margin: 0.5em 1em;
            text-indent: -.5em;

            a {
                text-decoration: none;
                color: #394e60 !important;
                font-size: 12px !important;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
      }

      ul.collapsible-nav-ul::-webkit-scrollbar {
        background-color: #fff;
        -webkit-appearance: none;
        width: 7px;
      }
      ul.collapsible-nav-ul::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0,0,0,.5);
          -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
      }

    }

    @media only screen and (max-width: 769px) {
       display: none;
    }
}
