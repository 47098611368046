.hr-thin {
  width: 100%;
  height: 1px;
  margin-bottom: 60px;
  margin-top: 60px;

  background-color: color(grey, light);
  border: 0;
}

.hr-thick {
  width: 100%;
  height: 4px;
  margin-bottom: 60px;
  margin-top: 60px;

  background-color: color(grey, light);
  border: 0;
}
