.main-header {
  @include t-antialias;
  width: 100%;

  top: 0;
  left: 0;

  position: fixed;
  z-index: 9;

  font-weight: 500;

  // border-bottom: 1px solid rgba(color(mirror), 0.2);
  background-color: #394e60;
  transition: background 0.3s ease-in-out;

  .alert-banner {
    background-color: #ffeed4;
    padding: 20px;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      margin: auto;

      .alert-icon {
        margin-right: 1rem;
      }

      .text {
        font-size: 12px;
        color: 394e60;
      }
    }


    .alert-close {
      height: 14px;
      cursor: pointer;
      margin: 0 10px;
    }
  }

  .global-alert-banner {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    position: relative;

    .text {
      padding: 10px;
      color: #FFFFFF;
      margin-right: 50px;
      overflow-wrap: anywhere;

      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .alert-close {
      fill: white;
      position: absolute;
      right: 25px;
      cursor: pointer;

      @media (max-width: $nav-breakpoint) {
        top: 10%;
      }
    }

    &.bg-lime {
      background-color: color(lime, base)
    }
    &.bg-rose {
      background-color: color(rose, base);
    }
    &.bg-dark-grey {
      background-color: color(dark-grey, base);
    }
    &.bg-grey {
      background-color: color(grey, base);
    }
  }

  &.homepage {
    background: color(dark-grey, dark);

    .sdl-search {
      display: none;
    }
  }

  &.about {
    background: color(dark-grey, dark);
  }

  &.is-subnavved {
    .desktop-nav__sub {
      max-height: 100%;
    }

    .sdl-navigation--desktop {
      transform: translateY(-100%);
    }

    .sdl-navigation--desktop {
      .sdl-select-links__options {
        display: none;
      }
    }
  }

  .sdl-logo {
    @include standard-transition;
    padding-left: 30px;

    // top: 50%;
    // left: 0;
    // //positioned absolutely at mobile-size to facilitate transition
    // position: absolute;
    z-index: 10;
    margin-top: auto;

    color: color(white);

    opacity: 1;
    // line-height: 50px;

    transform: translateY(-50%);

    &__mark {
      // transform: translateY(9px);

      svg g path {
        fill: color(white);
      }
    }

    div {
      display: inline-block;

      vertical-align: middle;

      @media (max-width: $nav-breakpoint) {
        position: relative;
        top: 5px;
      }
    }

    &.is-hidden {
      @include standard-transition;
      top: 0;
      left: 0;
      position: absolute;

      opacity: 0;
    }

    @include mq($from: $nav-breakpoint) {
      // was 160px, but need to leave room for the 30px padding + a tiny bit more to not overlap the `about` link
      min-width: 123px;
      // line-height: 60px;
    }
  }

  .search-form {
    input {
      width: 100%;
    }

    .live-search-results {
      @include base-box-shadow();
      position: absolute;

      @include mq($from: $nav-breakpoint) {
        max-width: 1050px;

        top: 40px;
        left: -60px;
      }
    }
  }

  .desktop-nav {
    @include standard-transition;
    min-height: 60px;
   // padding-left: 122px;

    opacity: 1;

    &.is-hidden {
      @include standard-transition;
      height: 0;

      opacity: 0;
      visibility: hidden;

      .desktop-nav--main {
        display: none;
      }
    }
  }

  .desktop-nav--main {
    @include standard-transition;
    height: 100%;
    display: flex;

    align-items: center;

    position: relative;

    font-size: 0;
  }

  .main-link {
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    position: relative;

    font-size: 15px;

    border-bottom: 0px solid color(white);

    transition: all 0.1s ease-in-out;

    > .sdl-select-links__options {
      margin: 0;

      bottom: 0;
      left: 0;
      transform: translateY(100%);
    }

    &:hover,
    &:focus,
    &:active {
      background: rgba(255, 255, 255, 0.2);

      > .sdl-select-links__options {
        display: flex;
      }
    }
  }

  .main-link--text {
    padding-bottom: 5px;

    color: color(white);
    text-decoration: none;

    &.active::after {
      height: 1px;
      content: '';
      display: block;
      margin-top: 2px;

      background: color(white);
    }
  }

  .sdl-navigation--desktop {
    @include standard-transition;
    display: none;

    justify-content: flex-end;

    user-select: none;

    // The following is part of a crazy transition that needs to take place
    // at a smaller desktop browswer width to allow room for the search bar.

    .nav-buttons {
      width: 60px;

      transition: background 0.3s ease-in-out;
    }

    .nav-buttons--authed {
      width: 158px;
      display: flex;
    }

    .nav-buttons,
    .desktop-nav:not(.desktop-nav__sub) {
      @include flex-shrink(0);
      white-space: nowrap;
      //
      // This is just to handle a smaller browswer width
      // and allow room for the search bar to expand
      // @include mq($until: 1024px) {
      //     opacity: 1;
      //     @include standard-transition;

      // this class doesnt seemt ot be ever applying itself - probably not needed

      // &.is-hidden {
      //     opacity: 0;
      //     width: 0;
      // }
    }

    @media (min-width: $nav-breakpoint) {
      width: 100%;
      display: flex;

    }
  }

  .sdl-navigation--mobile {
    height: 50px;

    justify-content: flex-end;

    z-index: $z-mobile-nav-trigger;

    a.mobile-menu {
      flex-shrink: 0;

      svg:last-of-type {
        display: none;
      }
    }

    .mobile-search {
      display: flex;

      justify-content: space-between;
      flex-direction: row;

      &.is-open {
        width: 100%;

        border-left: none;

        overflow: visible;
      }

      &__close {
        display: flex;
        padding-right: 15px;

        align-items: center;
      }

      &--left {
        width: 100%;
        display: flex;
      }

      &__input {
        @include mq($from: $nav-breakpoint) {
          height: 60px;
        }
      }

      &__form-wrapper {
        width: 100%;
      }
    }

    &.is-open a.mobile-menu {
      svg:first-of-type {
        display: none;
      }

      svg:last-of-type {
        display: initial;
      }
    }

    @media (min-width: $nav-breakpoint) {
      display: none;
    }

    @include mq($from: $nav-breakpoint) {
      height: 60px;
    }
  }

  .nav-buttons {
    a {

      width: 60px;
      // @include size(60px);
      height: 100%;
      display: flex;

      align-items: center;
      justify-content: center;

      text-align: center;
      text-decoration: none;
      // line-height: 64px;

      border-left: 1px solid rgba(color(mirror), 0.2);

      &.is-hidden {
        display: none;
      }

      &.logout-link {
        width: initial;
        padding: 0 20px;

        position: relative;

        color: color(white);

        border-left: 0;

        &::before {
          height: 20px;
          content: '';
          display: block;

          top: 50%;
          left: 0;
          position: absolute;

          border-left: 1px solid rgba(color(mirror), 0.2);

          transform: translateY(-50%);
        }
      }
    }

    svg g {
      fill: color(white);
    }
  }

  @at-root {
    .sdl-search {
      display: none;
      margin-left: 100px;
      padding-left: 30px;

      flex: 1;

      line-height: 60px;
      user-select: all;

      border-left: 1px solid rgba(color(mirror), 0.2);

      svg {
        display: inline-block;

        transform: translateY(3px);
      }

      .search-form {
        width: calc(100% - 90px);
        display: inline-block;

        position: relative;

        input {
          @include t-antialias;

          height: 100%;

          color: color(white);

          background: 0;
          border: 0;
          appearance: none;

          &::placeholder {
            color: rgba(color(white), 0.5);
          }

          &:focus {
            outline: 0;
          }

          @include mq($from: $nav-breakpoint) {
            padding-left: 30px;
          }
        }
      }

      @include mq($from: $nav-breakpoint) {
        display: block;
      }
      @include mq($from: $nav-breakpoint, $until: desktop) {
        margin-left: 20px;
      }
    }
  }
}

nav.mobile-nav {
  max-height: 0;
  padding-top: 51px;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: $z-mobile-nav;

  overflow-y: scroll;

  transition: max-height 0.3s ease-in-out, background 0s linear 0.3s;
  -webkit-overflow-scrolling: touch;

  ul {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px 0;

    & + ul {
      border-top: 1px solid rgba(color(mirror), 0.2);
    }
  }

  ul li {
    font-size: 18px;
    line-height: 38px;
    list-style-type: none;

    &:first-of-type a {
      color: color(white);
    }

    a {
      color: color(grey, base);
    }
  }

  &.is-open {
    max-height: 100vh;

    background-color: rgba(color(dark-grey, base), 0.95);

    transition: max-height 0.3s ease-in-out;
  }

  @media (min-width: $nav-breakpoint) {
    display: none;
  }
}

.main-header .nav-buttons {
  .account-dropdown {
    width: 200px;
    display: none;

    top: 70px;
    right: 10px;
    position: absolute;

    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;

      border-left: 0;

      li {
        margin: 0;
        padding: 0;

        color: white;

        &.padded {
          padding: 20px;

          background: rgba(32, 43, 54, 0.9);
        }

        &:not(:first-of-type) {
          border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }

    a {
      width: initial;
      height: initial;
      display: block;
      padding: 20px;

      color: white;
      line-height: initial;

      text-align: left;

      background: rgba(32, 43, 54, 0.9);

      border-left: 0;

      &:hover {
        background: rgba(32, 43, 54, 1);
      }
    }

    &.is-active {
      display: block;
    }

    @media (min-width: $nav-breakpoint) {
      display: none;
    }
  }
}
