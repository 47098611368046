.manticore {
  &__column {
    &--left {
      width: calc(100% - 410px);
    }

    &--right {
      width: 410px;
    }
  }

  &-pip-width {
    width: 725px;
  }

  &-pip-height {
    height: 491px;
  }

  &-minimized-width {
    width: 320px;
  }

  &-minimized-height {
    max-height: 216px;
  }
}
