table {
  color: color(dark-grey, base);
  word-break: normal;

  border-collapse: collapse;

  thead tr th {
    word-break: normal;
  }

  tr {
    height: 50px;
  }

  &.fixed {
    table-layout: fixed;
  }

  &.fixed.responsive {
    thead {
      th {
        cursor: pointer;
      }
    }
  }
}

table,
.div-table {
  @include t-antialias;
  width: 100%;
  margin: 20px 0;

  font-size: 12px;

  a {
    color: color(dark-grey, base);
    text-decoration: none;
  }

  thead {
    th {
      padding: 15px 20px;

      color: color(grey, base);
      text-align: left;
      text-transform: uppercase;

      background-color: color(mirror);

      outline: none;

      .arrow {
        width: 6px;
        height: 4px;
        display: none;

        top: -2px;
        left: 1px;
        position: relative;
      }
    }

    th.sort {
      &-ascending,
      &-descending {
        color: color(dark-grey, base);
      }

      &-ascending .arrow,
      &-descending .arrow {
        display: inline-block;
      }

      &-ascending .arrow {
        transform: rotate(-180deg);
      }
    }
  }

  tbody {
    td {

      // padding: 7px 0;
      //padding: 17px 20px;
      padding: 17px 15px;
      position: relative;

      background-color: color(ice);
    }

    tr:not(:last-of-type) {
      border-bottom: 1px solid color(grey, light);
    }

    .sortable:first-of-type {
      .sort-higher {
        opacity: 0.3;

        pointer-events: none;
      }
    }

    .sortable:last-of-type {
      .sort-lower {
        opacity: 0.3;

        pointer-events: none;
      }
    }

    tr:hover td,
    .row:hover {
      background-color: color(mirror);
    }
  }

  .checkbox {
    input + label::before {
      background-color: rgba(color(grey, base), 0.3);
      border: none;
    }
  }

  // Utility cells

  .spacer {
    width: 50px;

    &--double {
      width: 100px;
    }
  }

  .cell-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;

    vertical-align: middle;

    border-radius: 2px;
  }

  .cell-icon--large {
    width: 150px;
    height: 150px;

    border-radius: 15px;
  }
}

table,
.div-table,
.sort-controls {
  // Row sort controls

  .sort {
    &-higher,
    &-lower {
      display: block;

      text-align: center;

      &.disabled {
        opacity: 0.3;

        pointer-events: none;
      }

      svg {
        width: 6px;
      }
    }

    &-higher svg {
      transform: rotate(-180deg);
    }
  }
}

.div-table {
  margin-bottom: 0;
  margin-top: 0;

  background-color: color(ice);

  .thead {
    padding: 20px;

    font-size: 15px;
    font-weight: 500;
    color: color(dark-grey, base);
    line-height: 20px;

    background-color: color(mirror);

    span,
    div {
      display: block;
    }

    .arrow {
      width: 6px;
      height: 4px;
      display: none;

      top: -2px;
      left: 1px;
      position: relative;
    }

    th.sort,
    label.sort {
      &-ascending,
      &-descending {
        color: color(dark-grey, base);
      }

      &-ascending .arrow,
      &-descending .arrow {
        display: inline-block;
      }

      &-ascending .arrow {
        transform: rotate(-180deg);
      }
    }
  }

  .tbody {
    font-size: 15px;
    line-height: 20px;

    border-bottom: 1px solid color(dark-grey, base);

    .row {
      padding: 20px;

      border-top: 1px solid color(dark-grey, base);
    }

    // 'success' label before icon:
    // http://joeysharesthings.com/0F403w151Z0u

    .m-l-auto {
      margin-right: 18px;
    }

    .arrow {
      width: 7px;
    }
  }

  .tfoot {
    padding: 20px;
  }

  .button {
    width: 100%;
    display: block;
  }
}

.table-header {
  padding: 15px;

  font-weight: 800;

  color: color(dark-grey, base);

  background-color: color(mirror);
  border-bottom: 1px solid color(dark-grey, base);

  & + table {
    margin-top: 0;
  }
}

.regions {
  max-width: 330px;

  line-height: 1.67;
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}
