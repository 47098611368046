.mobile-menu {
  & {
    justify-content: center;
    align-items: center;
    width: 46px;
    display: flex;

    border-left: 1px solid rgba(color(mirror), 0.2);
  }

  // This svg is a little weird. It looks like it has a little bit of space to the left
  // so we'll just adjust it a smidge

  svg {
    left: -2px;
    position: relative;
  }
}
