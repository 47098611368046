body.appid-form {
  .appid-form--form__locked {
    opacity: 0.5;

    div.checkbox,
    div.checkbox label,
    div.checkbox input[disabled] {
      /* use cursor: not-allowed?  that would make sense for anywhere disabled */
      cursor: default;
    }
  }

  .nav-rpcs,
  .location-rpcs {
    margin-bottom: 20px;
    padding: 20px;

    select {
      width: 157px;
      height: 40px;
      padding: 10px;

      background-color: #ffffff;
    }
  }

  .button-admin--large {
    max-width: 100%;
  }

  .app-upload {
    display: block;
  }

  .form {
    width: 100%;

    &-header {
      margin-top: 30px;
      padding: 20px;

      background-color: color(mirror);
    }

    &-media {
      padding-bottom: 30px;
      // height: 300px;
      // flex-direction: column;
      // justify-content: space-between;
      padding-top: 40px;

      // background-color: color(ice);
    }
  }

  .media-wrapper {
    &-app-icon {
      width: 150px;
      height: 150px;
    }
  }

  .media-wrapper img {
    max-height: 100%;
    max-width: 100%;
  }

  .hidden-inputfile {
    width: 0.1px;
    height: 0.1px;

    position: absolute;
    z-index: -1;

    opacity: 0;
    overflow: hidden;
  }

  .reg-checkboxes {
    .checkbox + .checkbox {
      margin-top: 30px;
    }
  }

  .sdl-dropdown__menu.is-open {
    display: block;
  }

  #additional_categories_selector {
    z-index: 8;
  }

  .sdl-dropdown {
    height: 60px;
    padding: 20px;

    background-color: color(ice);
    border: none;

    &__main {
      margin-right: 20px;

      text-overflow: ellipsis;
      white-space: nowrap;

      overflow: hidden;
    }

    &--filtered {
      padding: 0;
    }

    &--filtered &__main {
      margin-right: 0;
    }

    &__menu {
      top: 60px;
    }

    &__submenu {
      padding: 0;

      list-style: none;

      > .checkbox {
      }
    }
  }


  //
  // modifiers for new styles
  //

  .bg-label {
    &-near-white {
      &::before {
        background-color: color(mirror);
      }
    }

    &-dark-grey {
      &::before {
        background-color: #d2d5da;
      }
    }
  }

  .bg-white {
    background-color: color(white);
  }

  /* duplicated from frontend/components/_buttons.scss */
  .button-hover {
    &--lime-white:hover {
      color: white;

      background-color: color(lime, base);

      * {
        fill: white;
      }
    }

    &--white-lime:hover {
      color: color(lime, base);

      background-color: color(white);

      * {
        fill: color(lime, base);
      }
    }

    &--red-white:hover {
      color: white;
      background-color: color(red, base);

      * {
        fill: white;
      }
    }
  }

  .bn-label::before {
    border: none;
  }

  .fw {
    &-400 {
      @include t-antialias;
      font-weight: 400;
    }
  }
}

.form-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.form-tooltip .form-tooltiptext {
  visibility: hidden;
  width: 142px;
  color: #394e60;
  background-color: #fff;
  text-align: center;
  border: 1px solid #394e60;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 500%;
  margin-left: -60px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 1px;
}

.form-tooltip .form-tooltiptext::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -1px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  border-top: 6px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #394e60;
}

.form-tooltip .form-tooltiptext::after {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
}

.form-tooltip:hover .form-tooltiptext {
  visibility: visible;
}

#locales-formset {

  .locales-form {

    select {
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      width: 100%;
      height: 62px;
      max-width: 690px;
      padding: 16.5px 20px 16.5px 20px;
      position: relative;
      z-index: 7;
      background: #f4f5f7;
      background-position: right 20px center;
      background-repeat: no-repeat;
      background-image: url(../svg/icons/icon-select-close-grey.svg);
    }

    #id_locales-form-0-name {
      width: 100%;
      height: 62px;
      max-width: 690px;
      padding: 16.5px 20px 16.5px 20px;
      position: relative;
      z-index: 7;
      background: #f4f5f7;
      background-image: url(../svg/basic/chevron-down.svg);
      background-position: right 20px center;
      background-repeat: no-repeat;
      border: 1px solid #dee1e6;
    }

    #id_locales-form-0-tts_type {
      background-image: url(../svg/basic/chevron-down.svg);
    }

  }

  .form-item {

    .checkbox {

      label::before {
        background-image: none;
        width: 689px;
        height: 62px;
        margin-top: 1rem;
        border: none;
      }
    }
  }
}
