.three-up-grid {
  width: 100%;
  margin: 60px 0 0 0;

  .grid-item {
    width: 100%;

    text-align: center;

    &__copy {
      width: 91%;
      margin-top: 40px;

      text-align: left;

      span {
        transform: translateY(-1px);
        display: inline-block;
        margin-right: 10px;

        font-size: 19px;
      }

      .t-default + .t-default {
        margin-top: 12px;

        @include mq($from: desktop) {
          margin-top: 20px;
        }
      }

      @include mq($from: tablet) {
        margin-top: 60px;
      }
    }

    @include mq($from: desktop) {
      width: calc((100% - 80px)/3);
    }
  }

  @include mq($from: desktop) {
    justify-content: space-between;
    display: flex;
  }
}

.two-up-grid--fluid {
  @include clearfix();
  width: 100%;

  &__item {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 30px;
    padding: 30px;

    @include mq($from: tablet) {
      width: calc(50% - 15px);
      float: left;

      &:nth-of-type(even) {
        margin-left: 30px;
      }

      &:last-of-type,
      &:nth-of-type(odd):nth-last-of-type(2) {
        // get second to last item if it's odd (first) in the row
        margin-bottom: 0;
      }
    }
  }
}
