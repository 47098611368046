.mw370 {
  max-width: 370px;
}

.mw629 {
  max-width: 629px;
}

.mw835 {
  max-width: 835px;
}

@media #{$breakpoint-not-small} {
  .mw370-ns {
    max-width: 370px;
  }

  .mw629-ns {
    max-width: 629px;
  }

  .mw835-ns {
    max-width: 835px;
  }
}
@media #{$breakpoint-medium} {
  .mw370-m {
    max-width: 370px;
  }

  .mw629-m {
    max-width: 629px;
  }

  .mw835-m {
    max-width: 835px;
  }
}
@media #{$breakpoint-large} {
  .mw370-l {
    max-width: 370px;
  }

  .mw629-l {
    max-width: 629px;
  }

  .mw835-l {
    max-width: 835px;
  }
}
