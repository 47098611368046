.no-manticore {
  &__label {
    padding-bottom: 11px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 11px;

    color: white;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    background-color: color(black);
  }

  &__body {
    padding: 25px 30px;

    line-height: 1.35;
    text-align: center;

    background-color: #ffffff;
  }

  &__confirm {
    padding: 20px;

    text-align: center;

    background-color: color(mirror);
  }
}
