.copy-banner {
  padding: 100px 0 120px;

  position: relative;
  z-index: $z-base;

  .container {
    width: calc(100% - 60px);
    position: relative;
    z-index: $z-content-on-grid;

    @include mq($from: tablet) {
      width: calc(100% - 180px);
    }
  }

  .brand-letter--s--2 {
    top: auto;
    right: -135px;
    bottom: -110px;
    position: absolute;
  }

  .copy__width {
  }

  p + p {
    margin-top: 1.6rem;
  }

  &.with-button {
    @include mq($from: tablet) {
      padding-bottom: 180px;
    }
  }

  &--pullup {
    @include mq($from: tablet) {
      margin-bottom: -260px;

      top: -280px;
    }
  }

  &--color-field {
    .section-head::before {
      background: color(white);
      opacity: 0.2;
    }
  }

  &__button--mobile {
    width: 100%;
    height: 60px;

    bottom: 0;
    left: 0;
    position: absolute;

    line-height: 60px;

    &.with-icon .container a {
      align-items: center;
      display: flex;
    }

    .icon-label {
      @include size(20px);
      margin-right: 10px;
    }

    svg {
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      position: absolute;
    }

    a {
      color: color(white);
      text-decoration: none;
    }

    @include mq($from: tablet) {
      display: none;
    }
  }

  &--floating {
    padding: 80px 0 60px 0;

    z-index: $z-content-on-grid;

    @include mq($from: tablet) {
      padding: 120px 40px;
    }
    @include mq($from: desktop) {
      padding-left: 90px;
      padding-right: 90px;
    }
  }

  &__button {
    @include standard-transition;
    height: 120px;
    display: none;
    padding: 0 30px;

    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;

    color: color(black);

    line-height: 120px;

    border-top: 1px solid rgba(211, 212, 214, 0.25);

    .t-default {
      line-height: inherit;
    }

    .link-text {
      top: 0;
      right: 0;
      position: absolute;

      svg {
        transform: translateY(2px);
        margin-left: 10px;

        position: static;
      }
    }

    &.news-link,
    &.about-link {
      padding-left: 90px;

      z-index: $z-content-on-grid;
    }

    &.news-link svg,
    &.about-link svg {
      right: 90px;
    }

    svg {
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      position: absolute;

      g {
        fill: color(lime, base);
      }
    }

    &:not(.copy-banner__button--billboard):hover {
      background: color(ice);
    }

    &--billboard {
      @include mq($from: tablet) {
        padding: 0;

        svg g {
          fill: color(white);
        }
      }
    }

    @include mq($from: tablet) {
      display: block;
      padding: 0 40px;
    }
    @include mq($from: desktop) {
      padding: 0 90px;
    }
  }

  &__author {
    margin-top: 40px;

    img {
      margin-right: 20px;
      max-width: 40px;
    }

    p {
      @include mq($from: tablet) {
        display: inline-block;
        margin-right: 7px;
      }
    }

    @include mq($from: tablet) {
      align-items: center;
    }
  }

  @include mq($from: tablet) {
    // min-height: 620px;
    padding: 140px 0 120px;
  }
}
