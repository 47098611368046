.slack-invite-form {
  display: flex;
  height: 100vh;
  background-color: color(ice);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    background-color: color(white);
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 54px 34px;

    @media (min-width: 600px) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &__logo svg {
    width: 60px;
    height: 60px;
  }

  &__heading {
    font-family: $livio-norm;
    font-size: 30px;
    font-weight: 400;
    margin-top: 20px;
  }

  &__input {
    width: 100%;
    display: block;
    padding: 20px;
    border: 0;
    border-bottom: 1px solid color(dark-grey, base);
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }

  &__recaptcha .g-recaptcha > div {
    margin-left: auto;
    margin-right: auto;
  }

  &__button {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 15px;
    padding: 18px;
    background-color: transparent;
    border: 1px solid color(lime, base);
    color: color(lime, base);
    width: 100%;
    margin-top: 30px;
  }

  &__message {
    margin-top: 30px;
    color: color(lime, base);
    font-size: 18px;
    font-weight: 500;

    &.error {
      color: color(red, base);
    }
  }
}
