.sg-container {
  width: 98%;
  margin: 100px auto;
  max-width: 1208px;
}

#icons {
  display: flex;

  flex-wrap: wrap;

  .icon {
    width: 20%;
    margin: 20px 0;

    text-align: center;

    &__icon {
      margin-bottom: 10px;
    }
  }
}

#colors {
  .color {
    height: 240px;
    margin: 20px 0;

    border: 1px solid color(grey, light);

    &__bar {
      height: 60px;

      position: relative;

      div {
        height: 100%;
        padding: 20px 25px;
      }

      div:last-of-type {
        width: 50%;
        max-width: 90px;

        top: 0;
        right: 0;
        position: absolute;
      }
    }

    &__information {
      padding: 25px 30px;

      div span:first-of-type {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
    }

    .uses {
      margin-top: 20px;
      padding-top: 20px;

      border-top: 1px solid color(grey, light);
    }
  }
}
