html {
  overflow-x: hidden;
}

.case-studies {

  &__block {

    @media (max-width: 500px) {

      div {
        flex-direction: column;
        margin: auto;

        a {
          margin: 1rem auto;
        }
      }
    }
  }
}

.enhancing-title {

  @media (min-width: 481px) and (max-width: 780px) {
    width: 50%;
  }

  @media (max-width: 400px) {
    width: 80%;
  }
}

.enhancing {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__col {
    width: 32%;
    padding: 20px;

    img {
      width: 150px;
      border-radius: 100%;
      display: block;
      margin: 2rem auto;
    }

    p {

      span {
        font-weight: bold;
      }
    }
  }

  @media (max-width: 450px) {
    display: block;

    &__col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

.overlay {
  position: relative;
  z-index: 3;
  top: 150px;
  width: 90%;
  margin: -5rem auto 0 auto;
  background-color: white;
  box-shadow: 0 20px 70px 0 rgba(color(black), 0.15);

  .inner {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1078px;
    @media (max-width: 500px) {
      width: 100%;
    }

  }

  @media (max-width: 500px) {
    width: 100%;
    box-shadow: none;
  }

  @include mq($from: tablet) {
    max-width: 650px;
  }
}

.summary {

  @include mq($from: tablet) {
    margin-bottom: -12rem;
  }

  @media (max-width: 450px) {
    margin-bottom: -10rem;
    margin-top: -7rem;
  }
}

.mt8 {
  margin-top: 12rem;
}

.solution {
  padding: 60px 0px;

  &__col {
    width: 24%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 120px;
    background-color: #fff;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);


    div {

      .p-title {
        word-wrap: break-word;

        @media (min-width: 501px) and (max-width: 780px) {
          word-wrap: normal;
        }
      }
    }

    &--number {
      position: relative;
      bottom: 33px;
      left: 10px;
    }

    img {
      display: block;
      width: auto;
      margin-top: -2rem;
    }

    @media (max-width: 500px) {
      box-shadow: none;
      margin: 0 0 60px 0;
      width: 100%;
    }

    @media (min-width: 501px) and (max-width: 780px) {
      width: 44%;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    padding: 20px 0px;
  }

  @media (min-width: 481px) and (max-width: 780px) {
    position: relative;
    padding: 0px;
  }

  // @media (min-width: 860px) {
  //   width: 90%;
  // }
}

.solution-bottom {
  justify-content: center;
}

.related-section {

  a {
    margin-right: 1rem;

    @media (max-width: 400px) {
      margin-right: 0
    }
  }

  @media (max-width: 400px) {
    justify-content: space-between;
  }
}
