.h0 {
  height: 0;
}

.h1 {
  height: 1 * $u;
}

.h2 {
  height: 2 * $u;
}

.h3 {
  height: 3 * $u;
}

.h4 {
  height: 4 * $u;
}

.h5 {
  height: 5 * $u;
}

.h6 {
  height: 6 * $u;
}

.h-12px {
  height: 12px;
}

.h0-important {
  height: 0 !important;
}

@media #{$breakpoint-not-small} {
  .h0-ns {
    height: 0;
  }

  .h1-ns {
    height: 1 * $u;
  }

  .h2-ns {
    height: 2 * $u;
  }

  .h3-ns {
    height: 3 * $u;
  }

  .h4-ns {
    height: 4 * $u;
  }

  .h5-ns {
    height: 5 * $u;
  }

  .h6-ns {
    height: 6 * $u;
  }

  .h-12px-ns {
    height: 12px;
  }
}
@media #{$breakpoint-medium} {
  .h0-m {
    height: 0;
  }

  .h1-m {
    height: 1 * $u;
  }

  .h2-m {
    height: 2 * $u;
  }

  .h3-m {
    height: 3 * $u;
  }

  .h4-m {
    height: 4 * $u;
  }

  .h5-m {
    height: 5 * $u;
  }

  .h6-m {
    height: 6 * $u;
  }

  .h-12px-m {
    height: 12px;
  }
}
@media #{$breakpoint-large} {
  .h0-l {
    height: 0;
  }

  .h1-l {
    height: 1 * $u;
  }

  .h2-l {
    height: 2 * $u;
  }

  .h3-l {
    height: 3 * $u;
  }

  .h4-l {
    height: 4 * $u;
  }

  .h5-l {
    height: 5 * $u;
  }

  .h6-l {
    height: 6 * $u;
  }

  .h-12px-l {
    height: 12px;
  }
}
