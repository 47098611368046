.h0 {
  height: 0;
}

.h1 {
  height: 1rem;
}

.h2 {
  height: 2rem;
}

.h3 {
  height: 3rem;
}

.h4 {
  height: 4rem;
}

.h5 {
  height: 5rem;
}

.h6 {
  height: 6rem;
}

.h200px {
  height: 200px;
}

.h250px {
  height: 250px;
}

.h150px {
  height: 150px;
}

.h30px {
  height: 30px;
}

.h40px {
  height: 40px;
}

.h50px {
  height: 50px;
}

.h60px {
  height: 60px;
}

.h80px {
  height: 80px;
}

.h20px {
  height: 20px;
}

.h5px {
  height: 5px;
}

.mx-h-500 {
  max-height: 500px;
}

.mn-h-500 {
  min-height: 80px;
}

.h-100 {
  height: 100%;
}

.lh-1_44 {
  line-height: 1.44;
}

.lh-20 {
  line-height: 20px;
}

.h-auto {
  height: auto !important;

  &--tablet {
    height: auto !important;
  }
}

@media #{$breakpoint-not-small} {
  .h1-ns {
    height: 1rem;
  }

  .h2-ns {
    height: 2rem;
  }

  .h3-ns {
    height: 3rem;
  }

  .h4-ns {
    height: 4rem;
  }

  .h5-ns {
    height: 5rem;
  }

  .h6-ns {
    height: 6rem;
  }

  .h200px-ns {
    height: 200px;
  }
}
@media #{$breakpoint-medium} {
  .h1-m {
    height: 1rem;
  }

  .h2-m {
    height: 2rem;
  }

  .h3-m {
    height: 3rem;
  }

  .h4-m {
    height: 4rem;
  }

  .h5-m {
    height: 5rem;
  }

  .h6-m {
    height: 6rem;
  }

  .h200px-m {
    height: 200px;
  }
}
@media #{$breakpoint-large} {
  .h1-l {
    height: 1rem;
  }

  .h2-l {
    height: 2rem;
  }

  .h3-l {
    height: 3rem;
  }

  .h4-l {
    height: 4rem;
  }

  .h5-l {
    height: 5rem;
  }

  .h6-l {
    height: 6rem;
  }

  .h200px-l {
    height: 200px;
  }
}
