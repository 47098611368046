.documentation-select {
  appearance: none;
  @include base-box-shadow();
  transition: all 0.3s ease-in-out;
  width: 100%;
  padding: 22px 20px;

  border: 0;

  border-radius: 0;

  &.fx-wrapper {
    display: flex;
  }

  &:focus {
    outline: 0;
  }

  &.sidebar-select-toggle {
    @include mq($from: tablet) {
      display: none;
    }
  }
}

// Link popovers
// Basically a select

//not sure what this is

.link-popover {
  &__target {
    height: 60px;
    padding-right: 20px;

    position: relative;

    color: color(white);
    line-height: 62px;

    background-image: url('../svg/icons/icon-select-closed.svg');
    background-position: right center;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    display: none;
  }

  &__list {
    width: 300px;
    margin-top: 10px;

    background: rgba(color(black), 0.9);

    a {
      display: block;
      padding: 0 30px;

      color: color(white);

      line-height: 50px;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(color(mirror), 0.2);
      }
    }
  }
}

.attached-link-pop {
  z-index: $z-header;
}

// Custom CSS Select dropdown

.sdl-select {
  flex-direction: column;
  display: flex;

  position: relative;
  z-index: $z-content;

  font-weight: 500;

  box-shadow: 0 10px 30px 0 rgba(color(black), 0.15);

  .sdl-select__option {
    align-items: center;
    width: 100%;
    height: 60px;
    display: flex;
    padding: 0 30px;

    order: 2;

    top: 0;
    position: absolute;
    z-index: 1;

    white-space: nowrap;

    background-color: white;
    overflow: hidden;

    pointer-events: none;

    svg {
      transform: translateY(-2px);
      margin-right: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &:focus .sdl-select__option {
    position: relative;

    pointer-events: all;
  }

  input {
    left: -99999px;
    position: absolute;

    opacity: 0;
  }

  input:checked + label {
    order: 1;

    position: relative;
    z-index: 2;
  }

  input:checked + label::after {
    transform: translateY(-50%);
    width: 6px;
    height: 11px;
    content: '';

    top: 50%;
    right: 30px;
    position: absolute;
  }

  input:not(:checked) + label {
    border-top: 1px solid color(grey, light);

    &:hover {
      background: color(grey, light);
      cursor: pointer;
    }
  }

  input:checked + label::before {
    content: '';

    right: 0;
    position: absolute;

    background: #666666;
  }

  &--large {
    width: 90%;
    margin: 0 auto;
    max-width: 510px;

    input:checked + label::after {
      background-image: url('../svg/icons/icon-select-closed-black.svg');
    }

    &:focus {
      input:checked + label::after {
        background-image: url('../svg/icons/icon-select-open-black.svg');
      }
    }
  }

  &--small {
    width: 100%;
    max-width: 280px;

    input:checked + label::after {
      background-image: url('../svg/icons/icon-select-closed.svg');
    }

    &:focus {
      input:checked + label::after {
        background-image: url('../svg/icons/icon-select-open.svg');
      }
    }

    a {
      color: color(white);
    }

    @include mq($from: tablet) {
      width: 280px;
    }
  }

  &--vehicle {
    margin: 20px 0;

    @include mq($from: tablet) {
      margin: 40px 0;
    }
  }

  &--plain {
    width: 100%;

    box-shadow: none;

    input:checked + label {
      font-weight: 400;

      background: color(ice);
    }

    input:checked + label::after {
      width: 10px;

      background-image: url('../svg/basic/chevron-down.svg');
      background-repeat: no-repeat;
    }

    &:focus {
      outline-color: red;
      outline-style: inset;

      input:checked + label::after {
        background-image: url('../svg/basic/chevron-up.svg');
      }
    }

    input:not(:checked) + label {
      font-weight: 400;

      border-bottom: 1px solid color(grey, light);
      border-left: 1px solid color(grey, light);
      border-right: 1px solid color(grey, light);
      border-top: 0;
    }

    label:hover {
      background: color(grey, light);
    }
  }

  &--abs {
    position: absolute;
  }
}

.sdl-select-link__docdrop .isopen {
  &::after {
    background-image: url('../svg/icons/icon-select-open.svg');
  }
}

.sdl-select-links {
  font-weight: normal;
  color: color(white);

  &__docdrop {
    width: 200px;
    height: 60px;
    min-width: 200px;
    padding: 0 20px;

    position: relative;

    line-height: 60px;

    background: rgba(255, 255, 255, 0.2);

    &:hover {
      cursor: pointer;
    }

    &::after {
      transform: translateY(-50%);
      width: 6px;
      height: 11px;
      content: '';

      top: 50%;
      right: 20px;
      position: absolute;

      background-image: url('../svg/icons/icon-select-closed.svg');
    }

    &.is-open::after {
      background-image: url(../svg/icons/icon-select-open.svg);
    }
  }

  // used in the main nav

  &__options {
    @include base-box-shadow();
    width: 330px;
    display: none;
    margin-top: 10px;

    position: absolute;

    transform: translateX(-20px);

    .option-wrapper {
      height: 60px;

      background: color(white);
    }

    .option-wrapper:not(:first-of-type) {
      border-top: 1px solid rgba(color(grey, light), 0.6);
    }

    .option {
      width: 100%;
      height: 60px;
      display: block;
      padding: 0 30px;

      color: color(dark-grey, base);

      line-height: 60px;
      text-decoration: none;

      background: color(white);

      &:hover {
        background-color: color(ice);
      }
    }

    .option:not(:first-of-type) {
      border-top: 1px solid rgba(color(grey, light), 0.6);
    }
  }

  &:focus {
    outline: 0;
  }

  &.is-open {
    .sdl-select-links__docdrop::after {
      background-image: url('../svg/icons/icon-select-open.svg');
    }

    .sdl-select-links__options {
      display: block;

      position: fixed;
    }
  }
}

.version-select--desktop {
  display: none;

  @include mq($from: tablet) {
    display: initial;
  }
}

.mobile-select {
  @include mq($from: tablet) {
    display: none;
  }
}

// dropdown

.sdl-dropdown {
  width: 100%;
  height: 50px;
  max-width: 690px;
  padding: 16.5px 20px 16.5px 20px;

  position: relative;

  z-index: $z-mobile-nav;

  background: color(mirror);
  background-image: url('../svg/icons/icon-select-close-grey.svg');
  background-position: right 20px center;
  background-repeat: no-repeat;
  border: 1px solid color(grey, light);

  &--filtered {
    padding: 0;
  }

  &__menu {
    @include base-box-shadow;
    width: 100%;
    display: none;
    max-height: 500px;

    top: 50px;
    left: 0;
    position: absolute;

    overflow-y: scroll;

    transform: translateY(5px);

    > .category {
      height: 50px;
      padding: 20px;

      font-size: 10px;
      font-weight: 500;
      color: color(grey, base);
      letter-spacing: 1.2px;

      text-transform: uppercase;

      background: color(mirror);
    }
  }

  &__main {
    color: color(dark-grey, base);
    text-decoration: none;
  }

  &__submenu {
    > .sdl-dropdown__option:not(:first-of-type) {
      border-top: 1px solid color(grey, light);
    }
  }

  &__option {
    height: 50px;
    display: block;
    padding: 16.5px 20px 16.5px 20px;

    color: color(dark-grey, base);

    background: color(white);

    &:hover {
      background: color(grey, light);
      cursor: pointer;
    }

    > span {
      color: color(grey, base);
    }
  }

  &.is-open {
    background-image: url('../svg/icons/icon-select-open-grey.svg');

    .sdl-dropdown__menu {
      display: block;
    }
  }
}

// multiselect specific styles to be used with sdl-dropdown

.multiselect {
  &__option {
    height: 60px;
    display: flex;
    padding: 20px;

    background-color: color(white);
  }

  &__option:not(:first-of-type) {
    border-top: 1px solid color(grey, light);
  }

  &__label {
    margin-left: 30px;
  }

  &__label::before {
    width: 13px;
    height: 13px;

    content: '';
    display: block;

    left: 20px;
    position: absolute;

    background: color(ice);
  }

  &__checkbox {
    opacity: 0;
  }

  input:checked + .multiselect__label::before {
    background-color: color(lime, base);
    background-image: url('../svg/icons/icon-checkmark.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
  }
}
