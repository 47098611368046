.manticore-wrapper {
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  position: fixed;

  z-index: $z-manticore;

  font-family: 'LivioNorm', 'Helvetica', sans-serif;

  pointer-events: none;
}

.manticore {
  // pointer-events: all;
}
