/* @TODO: refactor to not select input tags. Remember forms are being generated by Django */

.form-input {
  appearance: none;

  width: 100%;
  padding: 20px;

  color: color(dark-grey, base);

  line-height: 20px;

  background-color: color(ice);
  border: none;

  &::placeholder {
    color: color(grey, base);
  }

  &:focus {
    outline-color: color(lime, base);
  }

  &:disabled {
    color: color(grey, base);

    border: none;
  }
}

select {
  background-image: url('../svg/basic/chevron-down.svg');
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 10px;
  border-radius: 0px;
}

.js_reassign_to_company {
  background-position: right 6px center;
}

.reassign-submit {
  margin-top: 48px;
}

textarea {
  max-height: 350px;
  min-height: 240px;
}

.checkbox.checkbox-delete {
  label {
    &::before {
      width: 14px;
      height: 14px;
      // background-color: rgba(0, 0, 0, 0.0);
      background-image: url('../svg/icons/icon-x.svg');
      background-position: center center;
      background-repeat: no-repeat;
      border: none;
    }
  }
}

.checkbox,
.checkbox-button {
  @include t-antialias;
  position: relative;
  color: color(black);
  // overflow: hidden;

  input {
    position: absolute;
    border: none;
    opacity: 0;
  }

  label {
    padding-left: 29px;
    font-weight: 500;
    cursor: pointer;

    &::before {
      width: 14px;
      height: 14px;
      content: ' ';
      top: 2px;
      left: 0;
      position: absolute;
      border: 1px solid color(grey, base);
    }
  }

  input:checked + label {
    &::before {
      background-color: color(lime, base);
      background-image: url('../svg/icons/icon-checkmark.svg');
      background-position: center center;
      background-repeat: no-repeat;
      border: none;
    }
  }

  & + & {
    margin-top: 15px;
  }

  &--bg-grey {
    padding: 20px;
    background-color: color(ice);

    label::before {
      margin: auto;
      top: 23px;
      left: 20px;
      position: absolute;
      background-color: color(white);
    }
  }
}

.checkbox-button {
  @include t-antialias;

  label {
    width: 100%;
    display: block;
    padding: 20.5px;
    background-color: color(ice);
    border: transparent 1px solid;
    transition: background 0.2s ease-in-out;

    &:hover {
      background-color: color(dark-grey, base);
      color: #fff;
    }

    &::before {
      @include al-vertically;
      content: ' ';
      right: 20px;
      left: auto;
      border: none;
    }
  }

  input:checked + label {
    background-color: color(white);
    border: color(dark-grey, base) 1px solid;
    transition: background 0.2s ease-in-out;

    &:hover {
      color: #000;
    }

    &::before {
      width: 13px;
      height: 13px;
      background-color: color(black);
      background-image: url('../svg/icons/icon-checkmark.svg');
      background-position: center center;
      background-repeat: no-repeat;
      border: none;
      border-radius: 50%;
    }
  }

  & + & {
    margin: 0;
  }
}

.input-group {
  margin-bottom: 15px;

  label:not(.sdl-select__option):not(.button) {
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: color(grey, base);
    letter-spacing: 1.5px;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  em.help {
    font-size: 14px;
    color: color(platinum);
  }

  & .checkbox {
    label {
      margin-bottom: 0;
      font-size: 15px;
      font-weight: normal;
      color: color(dark-grey, base);
      letter-spacing: normal;
      line-height: 20px;
      text-transform: none;
    }
  }

  //
  // States
  //

  &.error {
    label:not(.button):not(.multiselect__label),
    .label {
      color: color(red, base);
    }

    input[type='text'],
    input[type='email'],
    textarea,
    .file-upload,
    .file-upload__file-chosen,
    .character-limit-textarea,
    .character-count,
    select {
      color: color(white);

      background-color: color(red, base);

      &::placeholder {
        color: #fff;
      }
    }

    select {
      background-image: url('../svg/basic/chevron-down-white.svg');
    }

    .button {
      color: color(white);
      background-color: rgba(color(white), 0.3);
      border: 1px solid color(white);
    }
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  @include mq($from: tablet) {
    margin-bottom: 36px;
  }
}

//
// Radio Circle Checks
//

.radio-option {
  display: flex;
  margin-bottom: 10px;

  &__wrap {
    width: 13px;
    height: 13px;
  }

  &__text {
    margin-left: 17px;
  }

  svg .icon_checkmark {
    fill: rgba(01, 01, 01, 0);
  }

  input {
    display: none;
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: color(grey, base);

    border-radius: 100px;
  }

  input[type='radio']:checked + .thelabel {
    font-weight: bold;

    background-color: color(lime, base);

    svg .icon_checkmark {
      fill: color(white);
    }
  }
}
