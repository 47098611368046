.cert-progress {
  padding-bottom: 60px;
  padding-top: 60px;

  position: relative;

  text-align: center;

  &.stage-1 &__steps {
    background-image: none;
  }

  &.stage-2 &__steps::before,
  &.stage-25 &__steps::before {
    width: 50%;
  }

  &.stage-3 &__steps::before {
    width: 0%;
  }

  &__steps {
    display: inline-flex;

    justify-content: center;

    position: relative;
    z-index: 0;

    background-image: linear-gradient(
      to right,
      color(grey, base),
      color(grey, base) 6px,
      transparent 6px,
      transparent 12px
    );
    background-position: center;
    background-repeat: repeat-x;
    background-size: 12px 2px;

    &::before {
      width: 100%;
      height: 100%;
      content: '';
      display: block;

      top: 0;
      right: 0;
      position: absolute;
      z-index: -1;

      background-color: white;
    }
  }

  &__step-indicator {
    width: 1.428em;
    height: 1.428em;
    display: flex;
    margin-left: 37px;
    margin-right: 37px;
    padding-top: 0.06em;

    align-items: center;
    justify-content: center;

    font-size: 9.1vw;
    font-weight: bold;
    color: color(white);
    line-height: 1;

    background-color: color(grey, light);
    border-radius: 100%;

    @media #{$breakpoint-medium} {
      font-size: 70px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.is-complete {
      background-color: color(lime, base);
    }

    &.is-denied {
      background-color: color(red, base);
    }
  }

  &__step-label {
    margin-top: 20px;

    text-align: center;
  }
}
