.section-head {
  display: flex;
  padding-top: 1.7rem;

  align-items: center;
  justify-content: space-between;

  position: relative;

  font-family: $livio-norm;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.133em;
  line-height: 1.33;
  text-transform: uppercase;

  &::before {
    width: 4rem;
    height: 0.266rem;
    content: '';

    top: 0;
    left: 0;
    position: absolute;

    background-color: currentColor;
  }

  &--transparent-bar::before {
    opacity: 0.2;
  }

  &__detail-link {
    display: flex;

    align-items: center;

    color: currentColor;
    letter-spacing: 0;
    text-transform: none;

    &:hover {
      opacity: 0.5;
    }
  }

  &__detail-link-arrow {
    width: 1.33rem;
    margin-left: 0.5rem;
    fill: currentColor;
  }
}


.tab{

  &::before {
    background-color: #61717f;
  }
}
