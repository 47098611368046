.br {
  &-one {
    border-radius: 1px;
  }

  &-two {
    border-radius: 2px;
  }

  &-three {
    border-radius: 3px;
  }

  &-four {
    border-radius: 4px;
  }

  &-five {
    border-radius: 5px;
  }
}

.b-50 {
  border-radius: 50%;
}

.b-rounded {
  border-radius: 100%;
}
