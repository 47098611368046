.w1 {
  width: 1rem;
}

.w2 {
  width: 2rem;
}

.w3 {
  width: 3rem;
}

.w4 {
  width: 4rem;
}

.w5 {
  width: 5rem;
}

.w6 {
  width: 6rem;
}

.w-200px {
  width: 200px;
}

.w-150px {
  width: 150px;
}

.w-120px {
  width: 120px;
}

.w-60px {
  width: 60px;
}

.w-20px {
  width: 20px;
}

.w-5px {
  width: 5px;
}

.w-10p {
  width: 10%;
}

.w-20p {
  width: 20%;
}

.w-30p {
  width: 30%;
}

.w-40p {
  width: 40%;
}

.w-50p {
  width: 50%;
}

.w-60p {
  width: 60%;
}

.w-70p {
  width: 70%;
}

.w-90p {
  width: 90%;
}

.w-100p {
  width: 100%;

  &--tablet {
    @include mq($until: desktop) {
      width: 100%;
    }
  }
}

.w-fit {
  width: fit-content;
}

@media #{$breakpoint-not-small} {
  .w1-ns {
    width: 1rem;
  }

  .w2-ns {
    width: 2rem;
  }

  .w3-ns {
    width: 3rem;
  }

  .w4-ns {
    width: 4rem;
  }

  .w5-ns {
    width: 5rem;
  }

  .w6-ns {
    width: 6rem;
  }

  .w8-ns {
    width: 8rem;
  }

  .w10-ns {
    width: 10rem;
  }

  .w11-ns {
    width: 11rem;
  }

  .w12-ns {
    width: 12rem;
  }
}
@media #{$breakpoint-medium} {
  .w1-m {
    width: 1rem;
  }

  .w2-m {
    width: 2rem;
  }

  .w3-m {
    width: 3rem;
  }

  .w4-m {
    width: 4rem;
  }

  .w5-m {
    width: 5rem;
  }

  .w6-m {
    width: 6rem;
  }
}
@media #{$breakpoint-large} {
  .w1-l {
    width: 1rem;
  }

  .w2-l {
    width: 2rem;
  }

  .w3-l {
    width: 3rem;
  }

  .w4-l {
    width: 4rem;
  }

  .w5-l {
    width: 5rem;
  }

  .w6-l {
    width: 6rem;
  }
}
