.app-cert-review-card {
  padding-bottom: 30px;
  padding-top: 30px;


  background-color: color(mirror);

  &.inactive {
    padding-bottom: 0;
  }

  &.inactive &__info,
  &.inactive &__form-links,
  &.inactive &__credentials,
  &.inactive &__review-form {
    opacity: 0.5;

    pointer-events: none;
  }

  &__info, &__form-links, &__review-form, &__credentials {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__form-links, &__credentials {
    display: flex;
    margin-top: 40px;

    list-style-type: none;

    li {
      margin-left: 15px;
      margin-right: 15px;

      flex: 1;

      overflow: hidden;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      p:first-child {
        font-size: 12px;
        font-weight: bold;
        color: color(gray, base);
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      a {
        margin-top: 5px;
      }
    }

    .download-link--small {
      margin-top: 5px;
      max-width: none;
    }
  }

  &__credentials {
    margin-top: 25px;
  }

  &__review-form {
    margin-top: 32px;

    input {
      display: none;
    }

    label {
      width: 100%;
      height: 30px;
      display: block;
      padding: 8px 10px 9px;

      position: relative;

      font-size: 12px;
      font-weight: bold;
      color: color(gray, base);
      letter-spacing: 1.5px;
      line-height: 1.33;
      text-transform: uppercase;
      white-space: nowrap;

      background-color: color(white);
      cursor: pointer;
      overflow: hidden;

      &::after {
        width: 30px;
        height: 100%;
        content: '';
        display: block;

        top: 0;
        right: 0;
        position: absolute;

        background-image: url(/static/svg/icons/icon-add.svg);
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }

  &__submit-buttons {
    display: flex;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;

    button {
      margin-left: 15px;
      margin-right: 15px;

      // letter-spacing: 2px;
      text-transform: capitalize;

      background-color: transparent;
      border: 1px solid currentColor;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__no-review {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__no-review, &__opt-to-review {
    margin-top: 30px;

    button {
      width: 100%;
      max-width: none;

      color: color(dark-grey, base);
      letter-spacing: 2px;
      text-transform: capitalize;

      background-color: color(grey, light);
      border: 0;
    }
  }

  .app-info-card {
    padding: 0;
  }
}
