.t-uppercase {
  text-transform: uppercase;
}

.t-capitalize {
  text-transform: capitalize;
}

.t-deco-none {
  text-decoration: none;
}
