.filter-desktop {
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
}


.pt120 {
  padding: 120px 0px 0px 0px !important;
}

.app-catalog-desc {
  max-width: 800px;

  .text {
    font-size: 24px;
    line-height: 36px;
    color: #394e60;
    text-align: center;
  }
}


.app-results{

  .checkbox-button {
    label {
      &:hover {
        transition: all 0.3s ease-in-out;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    height: 20rem;
  }

  @media (min-width: 860px) {
    height: 18rem;
  }
}

.flex-flow {
  flex-flow: row wrap;
}

.apps-flex-container {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: start !important;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
     //flex-flow: column;
    flex-flow: row wrap;
  }
}

.apps-thirds-container {
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 3rem;
    flex-direction: row;
    align-items: baseline;
    width: min-content;
  }
}

.flex-thirds {
  flex: 0 1 calc(35% - 25px);

  @media only screen and (max-width: 768px) {
    flex: inherit;
    // width: 250px;
    width: 320px;
  }
}

.flex-fifths {
  flex: 0 1 calc(20% - 20px);

  @media only screen and (max-width: 768px) {
    flex: auto;
  }
}


.oem-section {
  padding-bottom: 120px !important;
}

.every-other:nth-child(even) {
  background-color: #f4f5f7 !important;
}
.every-other:nth-child(odd) {
  background-color: #fff !important;
}
