.about-overview {
  .about-header {
    transition: height 0.2s ease-in-out;
    height: 346px;

    position: relative;

    background: color(dark-grey, base);

    &__image {
      transition: opacity 0.2s ease-in-out 0.3s;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;

      background: url('/static/img/SDL_Overview_Header.jpg');
      background-position: center center;
      background-size: cover;
      opacity: 1;
    }

    &.is-open {
      .about-header__image {
        opacity: 0;
      }

      .about-header__content {
        opacity: 0;
      }

      .about-header__video {
        transition: display 0s linear 0.2s;
        display: inline;
      }

      @include mq($from: tablet) {
        height: 768px;
      }
      @include mq($from: desktop) {
        height: 1000px;
      }
    }

    #mobile-about-video {
      width: 100%;
      height: 100%;

      top: 0;
      left: 0;
      position: absolute;
      z-index: 99999;

      @include mq($from: tablet) {
        display: none;
      }
    }

    .container {
      height: 100%;

      position: relative;
    }

    &__content {
      transform: translateY(-50%);
      transition: opacity 0.3s ease-in-out 0.2s;
      width: 100%;

      top: 60%;
      position: absolute;

      opacity: 1;

      @media screen and (min-width: 1068px) {
        align-items: center;
        display: flex;

        top: 45%;
      }
    }

    &__video {
      transform: translateY(-50%);
      transition: display 0.1s linear 0.4s;
      width: 100%;
      display: none;
      padding-bottom: 56.25%;

      top: 50%;
      position: absolute;

      &__control {
        transform: translateY(-50px);

        .t-h5 {
          letter-spacing: 0;
        }
      }

      iframe {
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
        position: absolute;

        @include mq($until: tablet) {
          display: none;
        }
      }
    }

    span {
      font-size: 20px;
    }

    .t-h1 {
      margin-bottom: 20px;

      @include mq($from: tablet) {
        margin-bottom: 0;

        font-size: 80px;
      }
    }

    a:hover span.play-arrow {
      background: rgba(color(white), 0.2);
    }

    span.play-arrow {
      @include size(80px);
      display: inline-block;
      margin-right: 20px;

      position: relative;
      vertical-align: middle;

      border: 2px solid color(white);
      border-radius: 100%;

      svg {
        @include vert-align(-27%, -50%);
      }
    }
  }

  .about-cta {
    &__wrapper {
      margin: 48px 0 24px 0;
      padding-bottom: 60px;

      .container {
        max-width: 844px;
      }

      .button-hover--lime-white:hover * {
        fill: none;
      }

      @include mq($from: tablet) {
        margin: 0;
        padding: 104px 0 94px 0;
      }
    }

    .download-icon {
      width: 60px;
      height: 100%;
    }
  }

  .static-page__section.about-what-is-sdl__wrapper {
    padding: 42px 0;

    @media screen and (min-width: 1068px) {
      margin-bottom: -225px;

      background: color(grey, light);
    }
  }

  .about-what-is-sdl {
    background: color(white);

    p + p {
      margin-top: 30px;

      color: color(dark-grey, base);
    }

    &__logo {
      padding: 45px 0 40px 0;
    }

    .sdl-detail-card {
      display: flex;
      margin-top: 30px;
      padding: 20px 30px;

      align-items: center;
      flex-direction: column;

      background: color(white);
      box-shadow: 0 20px 70px 0 rgba(50, 50, 50, 0.15);

      > .icon__wrapper {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
      }

      @include mq($from: tablet) {
        padding: 20px;

        flex-direction: row;

        > .icon__wrapper {
          margin-bottom: 0;
          max-width: 65px;
          padding-right: 20px;
        }
      }
    }

    .sdl-detail-card:last-of-type {
      margin-bottom: 30px;
    }

    .section-head {
      margin-bottom: 22px;
      margin-top: 15px;

      @include mq($from: tablet) {
        left: -50px;
      }
      @include mq($from: desktop) {
        left: 0px;
      }
    }

    @media screen and (min-width: 1068px) {
      width: calc(100% - 60px);
      transform: translateY(-10%);
      @include base-box-shadow();
      margin: 0 auto;
      max-width: $container-max-width;
      padding: 36px 80px;

      position: relative;
      z-index: 6;

      .sdl-detail-card {
        flex-direction: row;
      }
    }

    @include mq($from: tablet) {
      width: calc(100% - 200px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .static-page__section.about-for__wrapper {
    padding: 0;

    .hr-lime {
      width: 60px;
      height: 4px;
      margin-bottom: 24px;

      background-color: color(lime, base);

      border: none;
    }

    .illo_wrapper {
      display: flex;
      padding-bottom: 40px;

      justify-content: center;

      svg {
        height: 157px;
      }

      @include mq($from: tablet) {
        @include base-box-shadow();
        width: 300px;
        height: 260px;
        margin-right: 40px;
        min-width: 300px;
        padding: 50px 40px;

        align-items: center;

        background-color: color(white);
      }
      @include mq($from: desktop) {
        width: 520px;
        height: 450px;
        min-width: 520px;
        padding: 90px 0;

        svg {
          height: 100%;
        }
      }
    }

    .section-head {
      margin-bottom: 18px;

      &__container {
        margin: 0;
      }
    }

    .container--full {
      display: flex;

      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }
    }

    .about-for {
      &__anchor {
        width: 100%;
        height: 30px;
        display: block;

        top: -60px;
        position: absolute;
      }

      &__detail {
        padding: 60px 50px;

        @include mq($until: tablet) {
          @include base-box-shadow();
          margin: 0 30px 30px;
          padding: 24px 20px 14px 20px;

          background-color: color(white);
        }
        @include mq($from: desktop) {
          padding: 50px 0 90px 0;
        }
      }
    }

    p + ul {
      margin-top: 30px;

      color: color(dark-grey, base);
    }

    ul {
      padding-left: 20px;
    }
  }

  .about-information {
    padding-bottom: 80px;

    .github-button {
      @include mq($from: tablet) {
        margin-bottom: 0;
      }
    }

    &__column {
      p + a {
        margin-top: 24px;
      }

      a + a {
        margin-bottom: 50px;
        margin-top: 20px;
      }

      p {
        @include mq($from: tablet) {
          min-height: 190px;
        }
        @include mq($from: desktop) {
          min-height: 160px;
        }
      }

      @include mq($from: tablet) {
        width: calc((100% - 68px) / 3);
      }
    }

    @include mq($from: tablet) {
      padding-bottom: 120px;
    }
  }
}
