.rangeslider {
  height: 30px;

  position: relative;

  background-color: #dee1e6;
  border-radius: 2px;
  cursor: pointer;

  &--filled &__fill {
    background-color: #65a0ff;
  }

  &__fill {
    height: 100%;

    position: absolute;

    border-radius: 2px;
  }

  &__handle {
    width: 16px;
    height: 100%;

    position: absolute;

    background-color: #65a0ff;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5) 20%,
      transparent 20%,
      transparent 40%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 0.5) 60%,
      transparent 60%,
      transparent 80%,
      rgba(255, 255, 255, 0.5) 80%,
      rgba(255, 255, 255, 0.5)
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4px 10px;
    border-radius: 2px;
    cursor: pointer;

    transition: background-color 0.2s ease-out;

    &:hover,
    &:active {
      background-color: #5080cc;
    }
  }

  &__tooltip {
    display: none;
  }
}
