.events {
  .event {
    margin-bottom: 52px;

    address {
      margin-bottom: 20px;

      font-size: 24px;
      font-style: normal !important;
      font-weight: 600;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      padding-left: 27px;

      li {
        margin-bottom: 10px;
        padding-left: 10px;
      }
    }

    h1 {
      @extend .fc-green;
      @extend .fw-600;
    }
  }

  &-line {
    margin-bottom: 20px;

    &--x2 {
      margin-bottom: 40px;
    }
  }

  /* TODO: We can remove this .invite-link stuff as soon as the updated events are live.
    * On the updated pages this is being handled from .post-cta in the _static.scss styles
    * JM - 2018-09-03
    */

  .invite-link {
    @include button;
    margin-top: 44px;

    max-width: 100% !important;

    letter-spacing: 2.5px;
  }
}
