.file-upload {
  display: flex;

  &__input {
    display: none;
  }

  &__file-chosen {
    padding: 1.33rem;

    font-size: 1rem;
    white-space: nowrap;

    background-color: color(ice);
    border: 1px solid color(grey, light);
    overflow: hidden;
  }

  &__file-chosen {
    flex: 1;

    color: color(grey, base);
    line-height: 1.44;
  }

  &__button {
    margin: 0;
    margin-left: 1.33rem;
    padding: 1.53rem 2.33rem;

    flex-shrink: 0;

    font-size: 1rem;
    color: color(white);
    letter-spacing: 0.133em;
    text-transform: capitalize; 

    background-color: color(lime, base);
    cursor: pointer;
  }
}
