.app-info-card {
  display: flex;
  padding: 30px 15px;

  &--admin {
    min-height: 237px;
    padding: 0;
  }

  &--admin &__image {
    flex-basis: 150px;
    flex-shrink: 0;
    height: 100%;
    margin-right: 60px;

    border-radius: 0;
  }

  &--admin &__column {
    width: calc(33% - 100px);
  }

  &__image {
    width: 101px;
    height: 101px;

    border-radius: 15px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  &__column {
    margin-left: 30px;

    flex: 1;

    ul {
      height: 100%;
      display: flex;
      margin: 0;
      padding: 0;

      flex-direction: column;

      list-style-type: none;
    }

    li:not(:first-of-type):not(.bottom) {
      margin-top: 15px;
    }

    li {
      p:first-child {
        margin-top: 0;

        font-size: 12px;
        font-weight: bold;
        color: color(gray, base);
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }


      p {
        margin-top: 5px;
        font-size: 12px;
      }
    }
    .middle {
      margin-top: auto;
      padding-bottom: 20px; // This keeps very long text from running right into itx
    }
    .bottom {
      margin-top: auto;
      padding-top: 20px; // This keeps very long text from running right into it
    }
    .bottomHeader {
      font-size: 12px;
      font-weight: bold;
      color: color(gray, base);
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    .app-cert-review-card__review-form {
      max-width: 16.66rem;
    }
  }
}
