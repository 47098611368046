// Tachyons

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.vis-hidden {
  width: 1px;

  height: 1px;
  margin: -1px;
  padding: 0;

  position: absolute;

  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
