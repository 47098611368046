// Tachyons variables
$near-black: #202c36;
$dark-gray: #394e60;
$mid-gray: #8593a0;
$light-grey: #e7eaed;
$gray: #d2d5da;
$silver: #dee1e6;
$near-white: #f7f8fa;

$red: #f4505b;
$light-red: #f1646f;
$green: #19a974;
$light-green: #78c96f;
$dark-blue: #5080cc;
$blue: #65a0ff;
$washed-blue: #eff5ff;

.bg {
  &-red {
    background-color: color(red, base);
  }

  &-green {
    background-color: color(lime, base);
  }

  &-lighter-green {
    background-color: rgba(120, 201, 111, 0.17);
  }

  &-dark-green {
    background-color: #6bbd62;
  }

  &-white {
    background-color: color(white);

    &--30 {
      background-color: rgba(color(white), 0.3);
    }
  }

  &-mirror {
    background-color: color(mirror);
  }

  &-ice {
    background-color: color(ice);
  }

  &-med-grey {
    background-color: #dee1e6;
  }

  &-light-grey {
    background-color: $light-grey;
  }

  &-lighter-grey {
    background-color: #f4f5f7;
  }

  &-near-white {
    background-color: $near-white;
  }
}

.fc {
  &-red {
    color: color(red, base);
  }

  &-green {
    color: color(lime, base);
  }

  &-white {
    color: color(white);
  }

  &-charcoal {
    color: #394e60;
  }

  &-dark-grey {
    color: color(dark-grey, base) !important;
  }

  &-grey {
    color: color(grey, base);
  }
}


// -------------------- GREY
.status-btn-light {
  background-color: #e7eaed !important;
  color: #333 !important;
  border: none !important;
  outline: none !important;
  letter-spacing: 0 !important;

  &:hover {
    background-color: #3c4e60 !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
  }

  &:disabled {
    opacity: .7  !important;
  }

  &:hover:disabled {
    background-color: #e7eaed  !important;
    color: #333  !important;
  }
}
// -------------------- GREEN
.confirmation-btn-light {
  background: #fff !important;
  border: 1px solid #78C96F !important;
  color: #78C96F !important;
  letter-spacing: 0 !important;


  &:hover {
    background-color: #78C96F !important;
    color: #fff !important;

  }

  &:disabled {
    opacity: .7  !important;
  }

  &:hover:disabled {
    background-color: #fff !important;
    color: #78C96F  !important;
  }
}




// --------------------  RED
.cancel-btn-light {
  background: #fff;
  border: 1px solid #ef8188 !important;
  color: #ef8188;
  letter-spacing: 0 !important;

  &:hover {
    background-color: #ef8188;
    color: #fff;
  }

  &:disabled {
    opacity: .7;
  }

  &:hover:disabled {
    background-color: #fff !important;
    color: #ef8188;
  }
}

.cancel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


// EXTERNAL LINK
.external-link-btn-light {
  background: #fff !important;
  border: 1px solid #78C96F !important;
  color: #78C96F !important;
  letter-spacing: 2px !important;

  &__text {
    margin-right: 1rem;
  }

  .cta-text {
    margin-right: auto;
    margin-left: 20px;
  }

  .cta-icon {
    display: flex;
    margin-left: auto;
    margin-right: 20px;
  }

  .cta-text-left {
    position: absolute;
  }


  .arrow-right {
    height: 50px;
    width: 25px;
    background-image: url('../svg/basic/arrow-right.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
  }

  .arrow-left {
    height: 50px;
    width: 125px;
    background-image: url('../svg/basic/arrow-left.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    margin-right: auto;
  }

  svg {
    fill: #78C96F;

    g {
      fill: #78C96F !important;
    }
  }

  &:hover {
    background-color: #78C96F !important;
    color: #fff !important;

    .arrow-right {
      background-image: url('../svg/basic/arrow-right-hover.svg');
    }

    .arrow-left {
      background-image: url('../svg/basic/arrow-left-hover.svg');
    }


    svg {
      fill: #fff !important;

      g {
        fill: #fff !important;
      }
    }

  }

  &:disabled {
    opacity: .7  !important;
  }

  &:hover:disabled {
    background-color: #fff !important;
    color: #78C96F  !important;
  }
}
