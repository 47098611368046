.desktop-search {
  @include standard-transition;
  width: 60px;
  display: flex;

  align-items: center;
  // height:60px;

  border-left: 1px solid rgba(color(mirror), 0.2);
  overflow: hidden;

  transform: translateZ(0);

  &__icon {
    @include flex-shrink(0);
    width: 60px;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &__form-wrapper {
    width: 100%;

    input {
      padding: 0;
      padding-right: 12px;

      font-weight: normal;
      color: color(white);
      line-height: 1;

      background-color: transparent;

      outline: 0;

      &::placeholder {
        color: white;

        opacity: 1;
      }
    }
  }

  &__close {
    @include flex-shrink(0);
    padding-right: 15px;

    svg {
      fill: color(white);
    }
  }

  &.is-open {
    width: 100%;
    margin-left: 100px;

    overflow: visible;
  }
}
