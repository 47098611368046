// Search bar on mobile

.mobile-search {
  & {
    @include standard-transition;
    width: 46px;
    display: flex;

    border-left: 1px solid rgba(color(mirror), 0.2);

    overflow: hidden;

    @media (min-width: 781px) {
      display: none;
    }
  }

  .live-search-results {
    max-height: 219px;
  }

  .live-search-results__item {
    background-color: color(white);

    &:not(:last-of-type) {
      border-bottom: 1px solid color(grey, light);
    }

    &:hover {
      @include standard-transition;
      background-color: color(mirror);
    }

    .result-title,
    .result-location {
      color: color(dark-grey, base);
    }
  }

  &__icon {
    @include flex-shrink(0);
    width: 46px;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  &__form-wrapper {
    input {
      height: 50px;
      padding: 0;
      padding-right: 12px;

      font-weight: normal;
      color: color(white);
      line-height: 1;

      background-color: transparent;

      outline: 0;

      &::placeholder {
        color: color(white);

        opacity: 1;
      }
    }
  }

  &__close {
    @include flex-shrink(0);
    @include standard-transition;

    opacity: 0;

    svg {
      fill: color(white);
    }
  }

  &.is-open &__close {
    opacity: 1;
  }
}
