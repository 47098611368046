body.dev-profile {

  .main-header {
    background-color: #6bbd62 !important;
  }

  .modal {
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;

    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;

    background: rgba(151, 151, 151, 0.5);
    border: 0;
    box-shadow: 0 0 50px #aaaaaa;
    overflow: scroll;

    &__window {
      display: flex;

      flex-direction: column;

      position: fixed;
    }
  }

  .delete-shaid-key-modal {
    .modal {
      &__close {
        margin-left: auto;

        cursor: pointer;
      }

      &__window {
        max-width: 510px;
        padding: 20px;

        top: 25%;
        position: absolute;

        background: #ffffff;
        box-shadow: 0 0 30px #999999;

        h3 {
          margin-bottom: 20px;

          font-size: 30px;
          font-weight: 300;
          color: $red;
          text-align: center;
        }

        p {
          font-size: 15px;
          color: #394e60;
          line-height: 22px;
        }
      }
    }
  }

  .static-page__section {
    padding-top: 60px;
  }

  .dev-hero {
    @include mq($from: tablet) {
      // margin-top: 60px;
      margin-top: 50px;
    }
    height: 250px;
    display: flex;
    margin-top: 50px;

    align-items: center;
    justify-content: center;
  }

  .mobile-wide-col {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  .sdl-select .sdl-select__option {
    padding: 0px 20px;
  }

  .sdl-select {
    z-index: 0;
  }

  .sdl-select--vehicle {
    margin-top: 0;
  }

  .button-admin--large {
    max-width: 100%;
  }

  .button-admin--small {
    width: 150px;
    height: 40px;
  }

  // dev profile nav styles

  .sidebar-nav {
    display: none;

    background: color(white);

    transition: all 0.3s ease-in-out;

    &.is-visible {
      display: block;
    }
  }

  .documentation-select {
    @include mq($from: tablet) {
      display: none;
    }
  }

  .desktop-side-nav {
    @include mq($from: tablet) {
      display: block;
    }
    width: 285px;
    display: none;

    &__item {
      height: 40px;
      display: flex;
      min-width: 200px;
      padding: 10px 30px 10px 10px;

      background: color(ice);
    }

    &__link {
      display: flex;

      align-items: center;
      justify-content: flex-start;

      color: color(dark-grey, base);

      &:hover {
        color: color(lime, base);

        .desktop-side-nav__dot {
          opacity: 1;
        }
      }

      &__active {
        color: color(lime, base);
      }
    }

    &__active {
      color: color(lime, base);
    }

    &__dot {
      @include standard-transition;
      width: 8px;
      height: 8px;
      display: block;
      margin-right: 12px;

      background: color(white);
      border: 2px solid color(lime, base);
      border-radius: 50%;
      opacity: 0;
    }
  }
}

.full-row {
  @include mq($until: 992px) {
    flex-direction: column;

    .input-group {
      width: 100%;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}


#theme-container {
  margin: 100px 0 30px;
  padding: 30px;

  code {
    line-height: 1.5;
  }
}

.hr-div > .hr-thin {
  margin-top: 24px;
}

.id-list {
  width: 100%;

  border: 0;

  &__row {
    display: flex;
    padding: 20px 20px 20px 30px;

    justify-content: space-between;

    font-weight: 500;
    line-height: 1.3;

    background-color: color(ice);
    border-top: 1px solid color(grey, light);

    &:first-of-type {
      border-top: none;
    }

    .id-info {
      display: flex;
      padding-right: 40px;

      flex-direction: column;

      color: color(grey, base);

      & div:first-of-type {
        color: color(dark-grey, base);
      }
    }

    .id-edit {
      display: flex;

      align-items: center;
    }

    .edit {
      color: color(lime, base);
    }
  }
}

.resend-success {
  display: none;
  margin: 30px 0;

  &__header {
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: 500;
    letter-spacing: 2.5px;

    @extend .fc-green;
  }

  &__body {
    line-height: 25px;

    @extend .fc-charcoal;

    span {
      font-weight: 500;
    }
  }
}
