.billboard-search__container {
  position: relative;

  .billboard-search__input-container {
    position: relative;

    .icon {
      @include al-vertically;

      svg g g {
        fill: white;
      }
    }
  }

  .live-search-results {
    width: 100%;

    top: 55px;
    position: absolute;

    &.pending {
      background-color: color(white);
    }

    &__item {
      background-color: color(white);

      &:hover {
        @include standard-transition;
        background-color: color(mirror);
      }
    }

    &__item:not(:last-of-type) {
      border-bottom: 1px solid color(grey, light);
    }

    .loading-spinner {
      path {
        fill: color(dark-grey, base);
      }
    }

    .result-title,
    .result-location {
      color: color(dark-grey, base);
    }

    .icon-arrow-right {
      display: none;
    }
  }
}

.search-form {
  position: relative;

  // Loading image

  .search-pending {
    @include al-vertically;
    display: none;

    right: 0;

    &.visible {
      display: block;
    }
  }
}

.live-search-results {
  width: 100%;
  margin-top: 5px;
  max-height: 249px;

  position: relative;

  z-index: $z-search-results;

  overflow-y: auto;

  &__body {
    display: none;
  }

  .loading-spinner {
    @include animation(spin 0.5s linear infinite);
    width: 20px;
    height: 20px;
    display: none;
    margin: 0 auto;
  }

  &__item {
    @include standard-transition;
    padding: 16px 36px 14px;

    position: relative;

    background-color: rgba(color(black), 0.9);
    border-bottom: solid 1px rgba(color(white), 0.2);

    // Hover state

    &:hover {
      @include standard-transition;
      background-color: rgba(color(black), 1);
    }

    // Document icon colors

    @each $name, $color in $category_colors {
      &.#{$name} {
        .icon-documents .fill {
          fill: $color;
        }
      }
    }

    @include mq($from: tablet) {
      padding: 20px;
    }
  }

  &__item:last-of-type {
    border-bottom: none;
  }

  &__item__content {
    padding-left: 10px;
    padding-right: 20px;

    font-weight: normal;

    .result-title,
    .result-location {
      @include t-antialias;
      text-align: left;
    }

    .result-title {
      font-weight: 500;
    }

    .result-location {
      line-height: 25px;
    }

    @include mq($from: tablet) {
      padding-left: 20px;
      padding-right: 0;
    }
  }

  // states

  &.pending {
    height: 60px;
    padding: 20px;

    background-color: rgba(color(black), 0.9);

    .loading-spinner {
      display: block;
    }

    .live-search-results__body {
      margin-top: 20px;
    }
  }

  &.no-results {
    .result-title {
      font-weight: normal;
    }
  }

  &.results-ready,
  &.no-results {
    .live-search-results__body {
      display: block;
    }
  }

  // Document icon

  .icon-documents {
    top: 2px;
    left: 2px;
    position: relative;

    @include mq($from: tablet) {
      left: 9px;
    }
  }

  // Arrow icon

  &__item__content + svg {
    @include al-vertically;

    right: 30px;
  }

  //
  // Types
  //

  &--mobile {
    display: block;

    &.results-ready {
      display: block;
    }

    @include mq($from: tablet) {
      // display: none;
    }
  }

  //
  // Media Queries
  //

  @include mq($from: tablet) {
    // display: block;
  }
}

input[type='search'].billboard-search {
  @include t-antialias;
  appearance: none;

  width: 100%;
  margin-top: 2px;
  padding: 0 0 10px 50px;

  font-size: 24px;
  color: color(white);

  background: transparent;

  border: 0;
  border-bottom: 1px solid rgba(color(grey, light), 0.5);
  border-radius: 0;
  box-shadow: 0;

  &::placeholder {
    color: color(white);
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  @include mq($from: tablet) {
    font-size: 30px;
    line-height: 40px;
  }
}
