.no-mobile {
  display: none;

  @include mq($from: tablet) {
    display: initial;
  }
}

.no-desktop {
  @include mq($from: tablet) {
    display: none;
  }
}

//
// Alignment helpers
//

.al-horizontally {
  @include al-horizontally;
}

.al-vertically {
  @include al-vertically;
}

.t-antialias {
  @include t-antialias;
}

//
// SVG Helpers
//

.svg {
  &-brand-color svg g,
  &-brand-color g {
    fill: color(lime, base);
  }

  &-grey-color svg g,
  &-grey-color g {
    fill: color(dark-grey, base);
  }

  &-green-color svg g,
  &-green-color g {
    fill: color(lime, base);
  }
}

.rotate-180-svg svg {
  transform: rotateY(180deg);
}

.circle {
  @include circle;
}

.d-none {
  display: none;
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

.nav-frag-current::after {
  width: 100%;
  height: 1px;
  content: '';
  display: block;

  bottom: -1px;
  position: absolute;

  background-color: color(lime, base);
}

.focus-fc-green {
  &:focus {
    color: color(lime, base);
  }
}

.focus-fc-red {
  &:focus {
    color: color(red, base);
  }
}

.focus-bg-white {
  &:focus {
    background-color: color(white);
  }
}

.rotate-180 {
  transform: rotateY(180deg);
}

//
// Opacity Override
//

.o-100 {
  opacity: 1 !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-20 {
  opacity: 0.2 !important;
}

.o-40 {
  opacity: 0.4 !important;
}

.bg-hover-charcoal {
  &:hover {
    background-color: #394e60;
  }
}

.d-block {
  display: block;
}

.c-pointer {
  &:hover {
    cursor: pointer;
  }
}
