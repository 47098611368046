.colorblock {
  width: 100%;

  background-color: color(black);

  @include mq($from: tablet) {
    max-height: 450px;
  }
}

.slideshow {
  width: 100%;

  background-color: transparent;

  &__dots {
    @include calc(width, '100% - 46px');
    justify-content: center;
    display: flex;
    margin-bottom: 18px;
    margin-top: 18px;

    position: relative;

    cursor: pointer;

    @include mq($from: tablet) {
      width: initial;
      margin-bottom: 0;
      margin-top: 30px;

      order: -1;
    }
  }

  &__nav {
    align-items: center;
    display: flex;
    margin-left: 50px;

    cursor: pointer;

    & a {
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      display: flex;
      margin-bottom: 20px;

      background-color: color(white);

      cursor: pointer;

      svg g {
        fill: color(dark-grey, base);
      }

      svg path {
        fill: color(dark-grey, base);
      }

      &:hover svg g {
        fill: color(white);
      }

      &:hover svg path {
        fill: color(white);
      }
    }
  }

  .mobile-top {
    margin-bottom: 18px;
    margin-top: 18px;
    margin-left: auto;
    margin-right: 18px;

    svg path {
      fill: color(white);
    }
  }

  .caption {
    width: 80%;
    margin: 0 auto;

    right: 0;
    left: 0;
    position: absolute;

    color: color(white);

    text-align: left;

    &__title {
      font-weight: 500;
    }

    @include mq($from: tablet) {
      transform: translateY(100px);
      transition: transform 0.2s ease-in-out 0.1s;
    }
  }

  &__right {
    &.mobile-only {
      margin-left: 120px;
    }
  }

  &__info {
    &-close {
      display: none;
    }
  }

  &__left {
    transform: rotateY(180deg);

    &.mobile-only {
      margin-right: 120px;
    }
  }

  &__wrapper {
    flex-direction: column;
    align-items: center;
    display: flex;

    @include mq($from: tablet) {
      flex-direction: row;
      align-items: flex-start;
      margin: 0 auto;
      max-width: 1024px;

      transform: translateY(20%);
    }
  }
}

.slider {
  width: 100%;
  padding: 0;

  position: relative;

  list-style: none;

  &__image {
    height: 0;

    opacity: 0;
    overflow: hidden;

    transition: opacity 0.5s ease-in-out;
  }

  & img {
    @include standard-transition();
    width: 100%;

    position: relative;
  }

  .active-img {
    height: initial;

    left: 0;
    z-index: $z-content;

    opacity: 1;
    overflow: visible;
  }

  .active-img.has-caption img {
    margin-top: 50px;

    @include mq($from: tablet) {
      margin-top: 0;

      transform: translateY(100px);
    }
  }

  .active-img.has-caption .caption {
    @include mq($from: tablet) {
      height: 100%;
      margin-top: 0;

      transform: translateY(0);
    }
  }

  @include mq($from: tablet) {
    height: 500px;
  }
}

.dot {
  width: 10px;

  height: 10px;
  margin: 0 3px;

  position: relative;

  &::after {
    content: '';
    display: block;

    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    position: absolute;

    background: color(white);
    border-radius: 100%;
  }

  &.active {
    width: 10px;
    height: 10px;

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__nav {
    display: inline-flex;
    padding: 0;

    list-style: none;

    @include mq($from: tablet) {
      flex-direction: column;
      align-items: center;
      width: 100px;
    }
  }

  @include mq($from: tablet) {
    margin: 2px 0;
  }
}

.desktop-only {
  display: none;

  @include mq($from: tablet) {
    flex-direction: column;
    display: inline-flex;

    order: 1;
  }
}

.mobile-only {
  display: block;

  @include mq($from: tablet) {
    display: none;
  }
}

.static-page__section.after-slideshow {
  @include mq($from: tablet) {
    padding-top: 300px;
  }
}
