.arrow {
  width: 16px;
  height: 16px;
  display: flex;

  align-items: center;
  justify-content: center;

  background-color: color(manticore-blue, base);
  border-radius: 2px;

  &--left {
    &::after {
      content: '';
      display: block;

      border-bottom: 3px solid transparent;
      border-right: 4px solid #ffffff;
      border-top: 3px solid transparent;
    }
  }

  &--right {
    &::after {
      content: '';
      display: block;

      border-bottom: 3px solid transparent;
      border-left: 4px solid #ffffff;
      border-top: 3px solid transparent;
    }
  }
}
