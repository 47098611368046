.top-4 {
  top: 4rem;
}

.bottom--04 {
  bottom: -0.4rem;
}

.left-center {
  left: 50%;
}

.top-center {
  top: 50%;
}
