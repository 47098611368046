body.registration.password-change {
  a {
    margin-top: 10px;

    color: white;
  }

  .input-group:last-of-type {
    margin-bottom: 60px;
  }

  .button-std::before {
    transform: none;
  }
}
