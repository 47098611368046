.alias-tag {
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;

  background-color: color(grey, light);

  &__remove {
    width: 30px;
    display: flex;
    padding-top: 11px;

    justify-content: center;
    flex-shrink: 0;

    border-right: 1px solid color(grey, base);
  }

  &__text {
    padding: 8px;
  }
}
