@include keyframes(spin) {
  0% {
    transform: rotate(360deg);
  }
}
@include keyframes(slideIn) {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}
@include keyframes(slideOut) {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}
