.chat-message {
  margin-top: 21px;

  font-size: 15px;
  font-weight: 400;
  line-height: 1.44;

  &__avatar {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 2px;
    overflow: hidden;
    object-fit: contain;
  }

  &__byline {
    display: flex;
    align-items: center;
    color: color(grey, base);
  }

  &__message {
    margin-top: 4px;
    color: color(dark-grey, base);
  }
}

// wonder if this should just be combined in with .chat-message
.chat-history {
  border-bottom: 1px solid #a9b3bd;
  text-align: center;
  width: 100%;
  /* because we align the message text with the bottom border, using 1rem top margin and 2 rem bottom results in even spacing between elements*/
  margin: 1rem 0 2rem;

  &__message {
    top: .5rem;
    position: relative;
    background-color: #FFFFFF;
    padding: 0 1rem;
  }
}


.closed-message {
  margin-top: 42px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.44;

  p {
    font-weight: bold;
    font-size: 16px;
  }

}
