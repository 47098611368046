.warning-label {
  display: flex;
  font-size: 0.8rem;
  font-weight: bold;
  color: color(grey, base);
  letter-spacing: 0.08em;
  line-height: 1.8;
  text-transform: uppercase;

  &::before {
    width: 1.6rem;
    height: 1.6rem;
    content: '';
    display: block;
    flex-shrink: 0;
    margin-top: 0.33rem;
    margin-right: 0.66rem;

    background-image: url('/static/svg/icons/warning.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
