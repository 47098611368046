.w1 {
  width: 1 * $u;
}

.w2 {
  width: 2 * $u;
}

.w3 {
  width: 3 * $u;
}

.w4 {
  width: 4 * $u;
}

.w5 {
  width: 5 * $u;
}

.w6 {
  width: 6.6 * $u;
}

.w-16px {
  width: 16px;
}

.vw-100 {
  width: 100vw;
}

@media #{$breakpoint-not-small} {
  .w1-ns {
    width: 1 * $u;
  }

  .w2-ns {
    width: 2 * $u;
  }

  .w3-ns {
    width: 3 * $u;
  }

  .w4-ns {
    width: 4 * $u;
  }

  .w5-ns {
    width: 5 * $u;
  }

  .w6-ns {
    width: 6.6 * $u;
  }

  .w-16px-ns {
    width: 16px;
  }

  .vw-100-ns {
    width: 100vw;
  }
}
@media #{$breakpoint-medium} {
  .w1-m {
    width: 1 * $u;
  }

  .w2-m {
    width: 2 * $u;
  }

  .w3-m {
    width: 3 * $u;
  }

  .w4-m {
    width: 4 * $u;
  }

  .w5-m {
    width: 5 * $u;
  }

  .w6-m {
    width: 6.6 * $u;
  }

  .w-16px-m {
    width: 16px;
  }

  .vw-100-m {
    width: 100vw;
  }
}
@media #{$breakpoint-large} {
  .w1-l {
    width: 1 * $u;
  }

  .w2-l {
    width: 2 * $u;
  }

  .w3-l {
    width: 3 * $u;
  }

  .w4-l {
    width: 4 * $u;
  }

  .w5-l {
    width: 5 * $u;
  }

  .w6-l {
    width: 6.6 * $u;
  }

  .w-16px-l {
    width: 16px;
  }

  .vw-100-l {
    width: 100vw;
  }
}
