.profile-header {
  &__title {
    @include t-h2;
    font-weight: 400;
    color: color(dark-grey, base);
    text-transform: capitalize;
  }

  &__nav {
    margin-top: 30px;
  }
}

.profile-header-nav {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;

  color: color(dark-grey, base);

  list-style-type: none;

  &__item {
    padding-bottom: 10px;
    padding-top: 10px;

    flex: 1;

    position: relative;

    text-align: center;

    border-bottom: 1px solid color(grey, light);
    cursor: default;

    &.current {
      color: color(lime, base);

      border-color: currentColor;
    }

    &:hover .profile-header-nav__dropdown {
      display: block;
    }

    // tooltip
    .tooltip__disabled {
      position: relative;
      display: inline-block;

      .tooltiptext {
        display: none;
        flex-direction: row;
        -webkit-box-shadow: 0px 0px 46px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 46px -12px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 46px -12px rgba(0,0,0,0.75);
        width: max-content;
        background-color: #fff;
        color: #a9b3bd;
        text-align: center;
        padding: .5rem;
        position: absolute;
        z-index: 1;
        bottom: 0;

        @include mq($until: tablet) {
          width: auto !important;
          position: inherit !important;
        }

        img {
          margin-right: .5rem;
        }
      }


      &:hover .tooltiptext {
        display: block;
      }
    }

  }

  &__link {
    color: inherit;
  }



  &__dropdown {
    width: 100%;
    display: none;
    padding: 0;
    padding-top: 10px;

    top: 100%;
    left: 0;
    position: absolute;
    z-index: $z-content-dropdown;

    color: color(grey, base);

    list-style-type: none;

    box-shadow: 0 10px 20px 0 rgba(40, 40, 40, 0.15);
  }

  &__dropdown-item {
    min-height: 60px;
    padding-bottom: 10px;
    padding-top: 10px;

    align-items: center;
    justify-content: center;

    background-color: white;

    border-top: 1px solid color(grey, light);

    &:first-child {
      border: 0;
    }

    &.with-link {
      padding-bottom: 0;
      padding-top: 0;
    }

    &:hover {
      color: white;

      background-color: color(lime, base);
    }
  }

  &__dropdown-link {
    width: 100%;
    display: flex;
    min-height: 60px;
    padding-bottom: 10px;
    padding-top: 10px;

    align-items: center;
    justify-content: center;

    color: inherit;
  }
}
