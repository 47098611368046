body.partner-page {
  .section-head {
    &:not(:first-of-type) {
      margin-top: 120px;
    }
  }

  .t-h3 {
    font-weight: 500;
  }
}

.partner-header {
  width: 100%;
  height: 337px;

  &-image {
    height: 600px;

    background-position: center;
    background-size: cover;
  }
}

.partner-nav {
  margin: 25px 0 0;
  padding: 0;

  list-style-type: none;

  li {
    font-size: 15px;
    line-height: 2;
  }

  a {
    color: white;
  }

  @include mq($from: tablet) {
    justify-content: space-between;
    display: flex;
    margin: 0;

    transform: translateY(-7px);

    div {
      flex: 1;
    }
  }
}

.partner-contact {
  span {
    display: block;

    &:first-of-type {
      @include mq($from: tablet) {
        flex: 1.5;
      }
    }

    &:last-of-type {
      @include mq($from: tablet) {
        flex: 1;
      }
    }
  }

  & + .t-default.partner-contact {
    margin-top: 10px;
  }

  @include mq($from: tablet) {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.vehicle-select {
  margin-bottom: 40px;

  .carlist {
    margin: 0;
    padding: 0;

    list-style-type: none;
  }

  @include mq($from: tablet) {
    margin-bottom: 80px;
  }
}

.partner-banner {
  @include base-box-shadow();
  transform: translateY(-200px);
  /* 1180px sizes here are specific to this page and where it needs to break due to using a css transform to tranlate
       the logo 50px over */

  &__container {
    flex-direction: column;
    padding: 30px;

    @include mq($from: 1180px) {
      flex-direction: row;
      padding: 84px 0 92px 118px;
    }
  }

  &__logo {
    justify-content: center;
    height: 100%;
    display: flex;
    margin: 30px auto 0;
    max-width: 425px;
    // margin-top: 30px;
    background: color(white);

    img {
      @include base-box-shadow();
      display: block;
      padding: 0 10px;
    }

    @include mq($from: 1180px) {
      max-height: 302px;
      max-width: 425px;

      transform: translate(50px);
    }
  }

  &__copy {
    width: 100%;

    @include mq($from: 1180px) {
      margin-right: 37px;
      max-width: 496px;
    }
  }
}

//
// oempagelist.html styles
//

.oemdetail-banner {
  justify-content: center;
  height: 200px;
  display: flex;

  background: color(white);

  img {
    height: 200px;

    object-fit: contain;

    font-family: 'object-fit: contain;';
  }
}

.oemdetail-excerpt {
  > p {
    @include t-default-small;
  }
}

.oemdetail-footer {
  p > a {
    color: color(lime, base);
  }
}

//
// 2.0 styles
//

// member logo component

.logo-container {
  @include standard-transition();
  padding: 20px;

  background-image: linear-gradient(254deg, rgba(255, 255, 255, 0.5), rgba(251, 251, 251, 0.5));
  border: 1px solid color(grey, light);

  &--large {
    width: 100%;
    height: 9999px; // This is crazy but fixes an IE11 bug in which flex children don't care about parent's max-height
    max-height: 200px;
  }

  &--small {
    width: calc(50% - 8px);
    max-height: 160px;

    @include mq($until: tablet) {
      width: 100%;
      margin-top: 16px;
    }
  }

  .member-logo {
    width: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
}

.app-catalog-member-logo {
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,0.15);
}

.logo-container.has-page {
  cursor: pointer;

  &:hover {
    @include base-box-shadow();
    border: 1px solid transparent;
  }
}
