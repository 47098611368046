$spacing-directions: top right bottom left horizontal vertical all;
$spacing-properties: margin padding;
// Add pixel values here for corresponding functional classes
$values: 0 5 10 15 20 25 30 35 45 60 75 90 95 105 120 195;
// Script generates spacing classes for properties in $spacing-properties
// @media #{$breakpoint-not-small} {

@mixin spacing-selectors($property, $directions, $values, $suffix: null) {
  @if ($suffix) {
    $suffix: '-#{$suffix}';
  }

  @each $direction in $directions {
    @each $n in $values {
      $p: #{str_slice($property, 1, 1)};
      $d: #{str_slice($direction, 1, 1)};
      $dir: -#{$direction};
      $unit: px;

      @if ($n == 0) {
        $unit: null;
      }
      @if ($dir == '-all') {
        $dir: null;
      }
      @if $direction != horizontal and $direction != vertical {
        .#{$p}#{$d}#{$n}#{$suffix} {
          #{$property}#{$dir}: #{$n}#{$unit};
        }
      }

      @else if $direction == horizontal {
        .#{$p}#{$d}#{$n}#{$suffix} {
          #{$property}-left: #{$n}#{$unit};
          #{$property}-right: #{$n}#{$unit};
        }
      } @else if $direction == vertical {
        .#{$p}#{$d}#{$n}#{$suffix} {
          #{$property}-top: #{$n}#{$unit};
          #{$property}-bottom: #{$n}#{$unit};
        }
      }
    }
  }
}

@each $property in $spacing-properties {
  @include spacing-selectors($property, $spacing-directions, $values);

  @media #{$breakpoint-not-small} {
    @include spacing-selectors($property, $spacing-directions, $values, 'ns');
  }
  @media #{$breakpoint-medium} {
    @include spacing-selectors($property, $spacing-directions, $values, 'm');
  }
  @media #{$breakpoint-large} {
    @include spacing-selectors($property, $spacing-directions, $values, 'l');
  }
  @media #{$breakpoint-wide} {
    @include spacing-selectors($property, $spacing-directions, $values, 'w');
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.mh--15 {
  margin-left: -15px;
  margin-right: -15px;
}

@media #{$breakpoint-not-small} {
  .ml-auto-ns {
    margin-left: auto;
  }

  .mr-auto-ns {
    margin-right: auto;
  }

  .mh-auto-ns {
    margin-left: auto;
    margin-right: auto;
  }

  .mh--15-ns {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media #{$breakpoint-medium} {
  .ml-auto-m {
    margin-left: auto;
  }

  .mr-auto-m {
    margin-right: auto;
  }

  .mh-auto-m {
    margin-left: auto;
    margin-right: auto;
  }

  .mh--15-m {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media #{$breakpoint-large} {
  .ml-auto-l {
    margin-left: auto;
  }

  .mr-auto-l {
    margin-right: auto;
  }

  .mh-auto-l {
    margin-left: auto;
    margin-right: auto;
  }

  .mh--15-l {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media #{$breakpoint-wide} {
  .ml-auto-w {
    margin-left: auto;
  }

  .mr-auto-w {
    margin-right: auto;
  }

  .mh-auto-w {
    margin-left: auto;
    margin-right: auto;
  }

  .mh--15-w {
    margin-left: -15px;
    margin-right: -15px;
  }
}
