@mixin color-helper($namespace, $property, $colors) {
  @if $namespace {
    $namespace: '#{$namespace}-';
  }

  @each $item, $color in $colors {
    @if type-of($color) == 'map' {
      @each $map-item, $map-color in $color {
        @if $map-item == 'base' {
          .#{$namespace}#{$item} {
            #{$property}: #{$map-color};
          }

          .hover-#{$namespace}#{$item}:hover {
            #{$property}: #{$map-color};
          }

          .active-#{$namespace}#{$item}:active {
            #{$property}: #{$map-color};
          }
        }

        @else {
          .#{$namespace}#{$item}--#{$map-item} {
            #{$property}: #{$map-color};
          }

          .hover-#{$namespace}#{$item}--#{$map-item}:hover {
            #{$property}: #{$map-color};
          }

          .active-#{$namespace}#{$item}--#{$map-item}:active {
            #{$property}: #{$map-color};
          }
        }
      }
    }

    @else {
      .#{$namespace}#{$item} {
        #{$property}: #{$color};
      }

      .hover-#{$namespace}#{$item}:hover {
        #{$property}: #{$color};
      }

      .active-#{$namespace}#{$item}:active {
        #{$property}: #{$color};
      }
    }
  }
}
@mixin circle($color: color(forest, base)) {
  width: 10px;
  height: 10px;
  display: inline-block;

  background-color: $color;
  border-radius: 50%;
}
@mixin standard-transition() {
  transition: all 0.3s ease-in-out;
}

.standard-transition {
  @include standard-transition;
}

@mixin vert-align($x: -50%, $y: -50%) {
  transform: translateX($x) translateY($y);
  top: 50%;
  left: 50%;
  position: absolute;
}
@mixin base-box-shadow() {
  box-shadow: 0 10px 20px 0 rgba(color(black), 0.15);
}
@mixin t-antialias {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin al-vertically {
  transform: translateY(-50%);
  top: 50%;

  position: absolute;
}
@mixin al-horizontally {
  transform: translateX(-50%);
  left: 50%;

  position: absolute;
}
