.app-issue {
  p, ol, li, h1, h2, h3, h4, h5, h6 {
    line-height: 1.44;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
  }

  ol {
    padding-left: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  a {
    color: color(lime, base);
  }

  p + p,
  h1, h2, h3, h4, h5, h6 {
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
