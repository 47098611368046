@import 'main';

@page {
  size: portrait;
}

@media print {
  *::-webkit-scrollbar { width: 0 !important; };

  * {
    -ms-overflow-style: none !important;
    // page-break-inside: avoid !important;

    overflow: -moz-scrollbars-none !important;
  };

  dt, dd, dl > div {
    /* maybe dl - but I think that will just cause different weird page breaks.
      div is valid inside dl - we could figure out how to wrap dt and dd inside THAT
      https://html.spec.whatwg.org/multipage/grouping-content.html#the-dl-element
    */
    page-break-inside: avoid !important;
  }

  pre, code {
    font-family: monospace !important;
  }

  .codehilite code,
  .highlight code {
    font-family: monospace;
    page-break-inside: avoid !important;
  }

  .manticore-page code {
    font-family: monospace;
  }

  .manticore-page .codehilite {
    font-family: monospace;
  }

  code { font-family: monospace; }

  body {
    font-family: Helvetica, sans-serif !important;
  }

  body.documentation,
  body.pdf {
    -webkit-print-color-adjust: exact;

    // Stuff that's useless in print

    .sdl-navigation,
    .mobile-search,
    .mobile-menu,
    .select-wrapper,
    .documentation-select,
    .sidebar-search,
    .article__pagination,
    #documentation__sidebar {
      display: none;
    }

    // If this remains fixed it shows up on every printed page

    .main-header {
      position: relative;
    }

    #page-header {
      padding-top: 40px;

      .container {
        // Max width doesn't work in WeasyPrint-generated pdf
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    #documentation {
      // This is flex normally
      display: block;

      // Eliminate effects of doc sidebar lock

      &.is-locked #documentation__content {
        margin-left: initial;
      }
    }

    #documentation__content {
      // Let the documentation take up the full printed page

      & > .documentation__container {
        // max-width: none;
      }

      // Margins look a little large printed

      h1,
      h2 {
        margin: 50px 0 40px 0;
      }
    }

    // WeasyPrint doesn't like the SVG, so we'll use a png here

    .sdl-logo__mark img {
      width: 64px;
    }

    // For those that *do* like svgs

    .sdl-logo__mark path {
      fill: color(white) !important;
    }

    // Don't let these guys break accross multiple printed pages. Bad bad bad.

    .note,
    table,
    pre {
      page-break-inside: avoid;
    }

    // Give tables left borders.

    table {
      table-layout: fixed;

      word-wrap: break-word;

      border-left: 1px solid color(grey, light);
    }

    // WeasyPrint-generated PDFs are a little tight in codeblocks

    pre {
      line-height: 1.2;
    }

    // Don't let code overflow the container. Can't scroll on paper!

    .codehilite pre {
      white-space: pre-wrap;
    }

    // Get rid of the box shadow aroud the sequence diagram link box

    .visual-link {
      box-shadow: none !important;
    }

    // Get rid of the "view diagram" link

    .visual-link__link.fx-wrapper {
      display: none;
    }

    // Show the image in flow

    .visual-print-image {
      height: auto;
      display: block !important;
      max-height: 960px; // about 10 inches
    }
  }
}
