// Type sizes
$type-xxsmall: 0.625rem;
$type-xsmall: 0.75rem;
$type-small: 0.938rem;
$type-regular: 1.125rem;
$type-medium: 1.5rem;
$type-large: 1.875rem;
$type-xlarge: 2.5rem;
$type-xxlarge: 3.75rem;

// Colors
$category_colors: (
  server: #be8dd0,
  ios: #3ac2b4,
  android: #2bb074,
  core: #5da0de
);

// Container widths
$container-max-width: 1078px;
$container-medium-width: 1050px;
$container-narrow-max-width: 690px;

