//
// Position helpers
//

.above-grid {
  z-index: $z-content-on-grid;
}

.content-on-grid {
  z-index: $z-content-on-grid;
}

.z-content {
  z-index: $z-content;
}

//
// Border helpers
//

.bt-grey--dark {
  border-top: 1px solid color(grey, light);
}

//
// Spacers
//

.mb20-40 {
  margin-bottom: 20px;

  @include mq($from: tablet) {
    margin-bottom: 40px;
  }
}
