ul.pagination {
  justify-content: center;
  width: 100%;
  display: block;

  display: flex;
  margin: 0 0 60px 0;
  padding: 0;

  list-style-type: none;
  text-align: center;

  li {
    margin: 0 2px;
    padding: 0;

    position: relative;

    line-height: 30px;

    &.prev,
    &.last {
      background-color: #e7eaed !important;
      color: #333 !important;
      border: none !important;
      outline: none !important;

      &:hover {
        background-color: #3c4e60 !important;
        color: #fff !important;
        border: none !important;
        outline: none !important;
      }
    }

    &.prev svg {
      transform: translateY(-50%) translateX(-50%) rotate(180deg);
    }

    &:not(.prev):not(.last) {
      line-height: 34px;
    }

    a {
      @include size(30px);
      display: block;

      color: color(dark-grey, base);
    }

    &.active {
      background: color(dark-grey, base);

      a {
        color: color(white);
      }
    }

    svg {
      transform: translateY(-50%) translateX(-50%);
      max-height: 7.9px;

      top: 50%;
      left: 50%;
      position: absolute;
    }

    &:hover {
      background: color(dark-grey, base);
      border-color: color(dark-grey, base);

      svg g {
        fill: color(white);
      }

      a {
        color: color(white);
      }
    }
  }

  @include mq($from: tablet) {
    margin-bottom: 120px;
  }
}

.article__pagination {
  margin: 40px 0 60px 0;

  a {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: 10px 0;
    padding: 0 30px;
    position: relative;
    color: color(black);
    line-height: 60px;
    border: 1px solid color(grey, light);

    svg {
      transform: translateY(2px);
    }

    &.is-hidden {
      visibility: hidden;
    }

    &[class^='article__pagination--']::after {
      content: '';
      display: block;

      top: -1px;
      bottom: -1px;
      position: absolute;
      z-index: -1;
    }

    &.article__pagination--prev {
      span {
        font-size: 15px;
      }


      svg {
        transform: rotate(180deg) translateY(-2px);
      }

      &::after {
        transition: left 0.2s ease-in-out;
        right: -1px;
        left: 100%;
      }
    }

    span {
      position: relative;
      z-index: $z-content;
      font-weight: normal;
    }

    &:hover span {
      color: color(white);
    }

    &.article__pagination--next{
      margin-left: auto;

      span {
        font-size: 15px;
      }

      &::after {
        transition: right 0.2s ease-in-out;
        right: 100%;
        left: -1px;
      }
    }

    @include mq($from: tablet) {
      width: 48%;
    }
  }

  &--post {
    a[class^='article__pagination--']::after {
      background: color(dark-grey, base);
    }

    a {
      color: color(dark-grey, base);
    }
  }

  @include mq($from: tablet) {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-top: 60px;

    &.article__pagination--post {
      margin-bottom: 120px;
      margin-top: 0;
      width: 100%;
    }
  }
}
