.resize-handle {
  height: 12px;

  background: linear-gradient(
    to bottom,
    #929eaa,
    #929eaa 2px,
    rgba(0, 0, 0, 0) 2px,
    rgba(0, 0, 0, 0) 5px,
    #929eaa 5px,
    #929eaa 7px,
    rgba(0, 0, 0, 0) 7px,
    rgba(0, 0, 0, 0) 10px,
    #929eaa 10px,
    #929eaa 12px
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  cursor: row-resize;
}

.resize {
  &-vertical {
    background: linear-gradient(
      to bottom,
      #929eaa,
      #929eaa 16.66%,
      rgba(0, 0, 0, 0) 16.66%,
      rgba(0, 0, 0, 0) 41.66%,
      #929eaa 41.66%,
      #929eaa 58.33%,
      rgba(0, 0, 0, 0) 58.33%,
      rgba(0, 0, 0, 0) 83.33%,
      #929eaa 83.33%,
      #929eaa 100%
    );
    background-position: center;
    background-repeat: no-repeat;
    cursor: row-resize;
  }

  &-bottom-right {
    background: linear-gradient(
      135deg,
      transparent,
      transparent 50%,
      rgba(255, 255, 255, 0.25) 50%,
      rgba(255, 255, 255, 0.25) 60%,
      transparent 60%,
      transparent 70%,
      rgba(255, 255, 255, 0.25) 70%,
      rgba(255, 255, 255, 0.25) 80%,
      transparent 80%,
      transparent 90%,
      rgba(255, 255, 255, 0.25) 90%,
      rgba(255, 255, 255, 0.25) 100%
    );
    cursor: nwse-resize;
  }
}
