.download-link {
  width: 100%;
  display: flex;
  padding: 1.2rem 4rem 1.2rem 4.2rem;
  align-items: center;
  position: relative;

  font-weight: 500;
  color: color(dark-grey, base);
  letter-spacing: 0.133em;
  line-height: 1.33;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  background-color: color(ice);
  overflow: hidden;

  &::before {
    width: 3.33rem;
    height: 100%;
    content: '';
    display: block;

    top: 0;
    left: 0.67rem;
    position: absolute;

    background-image: url('/static/svg/icons/icon-document.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem 2rem;
  }

  &::after {
    width: 3.33rem;
    height: 100%;
    content: '';
    display: block;

    top: 0;
    right: 0;
    position: absolute;

    background-color: color(lime, base);
    background-image: url('/static/svg/icons/icon-download.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.2rem auto;
  }

  &--small {
    max-width: 16.66rem;
    padding: 0.533rem;
    padding-right: 2.533rem;

    font-size: 0.8rem;
    letter-spacing: 0;
    text-transform: none;

    background-color: white;

    &::before {
      display: none;
    }

    &::after {
      width: 2rem;

      background-size: 0.8rem auto;
    }
  }

  &--grey::after {
    background-color: color(dark-grey, base);
  }

  &--no-download::after {
    display: none;
  }

  @include mq($until: tablet) {
    white-space: pre-wrap;
  }
}
