// CSS3 Flexible Box Model and property defaults

// Custom shorthand notation for flexbox

@mixin box($orient: inline-axis, $pack: start, $align: stretch) {
  @include display-box;
  @include box-orient($orient);
  @include box-pack($pack);
  @include box-align($align);
}
@mixin display-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox; // IE 10
  display: box;
}
@mixin box-orient($orient: inline-axis) {
  // horizontal|vertical|inline-axis|block-axis|inherit

  @include prefixer(box-orient, $orient, webkit moz spec);
}
@mixin box-pack($pack: start) {
  // start|end|center|justify

  @include prefixer(box-pack, $pack, webkit moz spec);
  -ms-flex-pack: $pack; // IE 10
}
@mixin box-align($align: stretch) {
  // start|end|center|baseline|stretch

  @include prefixer(box-align, $align, webkit moz spec);
  -ms-flex-align: $align; // IE 10
}
@mixin box-direction($direction: normal) {
  // normal|reverse|inherit

  @include prefixer(box-direction, $direction, webkit moz spec);
  -ms-flex-direction: $direction; // IE 10
}
@mixin box-lines($lines: single) {
  // single|multiple

  @include prefixer(box-lines, $lines, webkit moz spec);
}
@mixin box-ordinal-group($int: 1) {
  @include prefixer(box-ordinal-group, $int, webkit moz spec);
  -ms-flex-order: $int; // IE 10
}
@mixin box-flex($value: 0) {
  @include prefixer(box-flex, $value, webkit moz spec);
  -ms-flex: $value; // IE 10
}
@mixin box-flex-group($int: 1) {
  @include prefixer(box-flex-group, $int, webkit moz spec);
}

// CSS3 Flexible Box Model and property defaults
// Unified attributes for 2009, 2011, and 2012 flavours.

// 2009 - display (box | inline-box)
// 2011 - display (flexbox | inline-flexbox)
// 2012 - display (flex | inline-flex)

@mixin display($value) {
  // flex | inline-flex

  @if $value == 'flex' {
    // 2009
    display: -webkit-box;
    display: -moz-box;
    display: box;

    // 2012
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox; // 2011 (IE 10)
    display: flex;
  }

  @else if $value == 'inline-flex' {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;

    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  } @else {
    display: $value;
  }
}

// 2009 - box-flex (integer)
// 2011 - flex (decimal | width decimal)
// 2012 - flex (integer integer width)

@mixin flex($value) {
  // Grab flex-grow for older browsers.
  $flex-grow: nth($value, 1);

  // 2009

  @include prefixer(box-flex, $flex-grow, webkit moz spec);

  // 2011 (IE 10), 2012

  @include prefixer(flex, $value, webkit moz ms spec);
}

// 2009 - box-orient ( horizontal | vertical | inline-axis | block-axis)
//      - box-direction (normal | reverse)
// 2011 - flex-direction (row | row-reverse | column | column-reverse)
// 2012 - flex-direction (row | row-reverse | column | column-reverse)

@mixin flex-direction($value: row) {
  // Alt values.
  $value-2009: $value;
  $value-2011: $value;
  $direction: normal;

  // 2009

  @include prefixer(box-orient, $value-2009, webkit moz spec);
  @include prefixer(box-direction, $direction, webkit moz spec);

  // 2012

  @include prefixer(flex-direction, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-direction: $value;

  @if $value == row {
    $value-2009: horizontal;
  }

  @else if $value == 'row-reverse' {
    $value-2009: horizontal;
    $direction: reverse;
  } @else if $value == column {
    $value-2009: vertical;
  } @else if $value == 'column-reverse' {
    $value-2009: vertical;
    $direction: reverse;
  }
}

// 2009 - box-lines (single | multiple)
// 2011 - flex-wrap (nowrap | wrap | wrap-reverse)
// 2012 - flex-wrap (nowrap | wrap | wrap-reverse)

@mixin flex-wrap($value: nowrap) {
  // Alt values
  $alt-value: $value;

  @include prefixer(box-lines, $alt-value, webkit moz spec);
  @include prefixer(flex-wrap, $value, webkit moz ms spec);

  @if $value == nowrap {
    $alt-value: single;
  }

  @else if $value == wrap {
    $alt-value: multiple;
  } @else if $value == 'wrap-reverse' {
    $alt-value: multiple;
  }
}

// 2009 - TODO: parse values into flex-direction/flex-wrap
// 2011 - TODO: parse values into flex-direction/flex-wrap
// 2012 - flex-flow (flex-direction || flex-wrap)

@mixin flex-flow($value) {
  @include prefixer(flex-flow, $value, webkit moz spec);
}

// 2009 - box-ordinal-group (integer)
// 2011 - flex-order (integer)
// 2012 - order (integer)

@mixin order($int: 0) {
  // 2009

  @include prefixer(box-ordinal-group, $int, webkit moz spec);

  // 2012

  @include prefixer(order, $int, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-order: $int;
}

// 2012 - flex-grow (number)

@mixin flex-grow($number: 0) {
  @include prefixer(flex-grow, $number, webkit moz spec);
  -ms-flex-positive: $number;
}

// 2012 - flex-shrink (number)

@mixin flex-shrink($number: 1) {
  @include prefixer(flex-shrink, $number, webkit moz spec);
  -ms-flex-negative: $number;
}

// 2012 - flex-basis (number)

@mixin flex-basis($width: auto) {
  @include prefixer(flex-basis, $width, webkit moz spec);
  -ms-flex-preferred-size: $width;
}

// 2009 - box-pack (start | end | center | justify)
// 2011 - flex-pack (start | end | center | justify)
// 2012 - justify-content (flex-start | flex-end | center | space-between | space-around)

@mixin justify-content($value: flex-start) {
  // Alt values.
  $alt-value: $value;

  // 2009

  @include prefixer(box-pack, $alt-value, webkit moz spec);

  // 2012

  @include prefixer(justify-content, $value, webkit moz ms o spec);

  // 2011 (IE 10)
  -ms-flex-pack: $alt-value;

  @if $value == 'flex-start' {
    $alt-value: start;
  }

  @else if $value == 'flex-end' {
    $alt-value: end;
  } @else if $value == 'space-between' {
    $alt-value: justify;
  } @else if $value == 'space-around' {
    $alt-value: distribute;
  }
}

// 2009 - box-align (start | end | center | baseline | stretch)
// 2011 - flex-align (start | end | center | baseline | stretch)
// 2012 - align-items (flex-start | flex-end | center | baseline | stretch)

@mixin align-items($value: stretch) {
  $alt-value: $value;

  // 2009

  @include prefixer(box-align, $alt-value, webkit moz spec);

  // 2012

  @include prefixer(align-items, $value, webkit moz ms o spec);

  // 2011 (IE 10)
  -ms-flex-align: $alt-value;

  @if $value == 'flex-start' {
    $alt-value: start;
  }

  @else if $value == 'flex-end' {
    $alt-value: end;
  }
}

// 2011 - flex-item-align (auto | start | end | center | baseline | stretch)
// 2012 - align-self (auto | flex-start | flex-end | center | baseline | stretch)

@mixin align-self($value: auto) {
  $value-2011: $value;

  // 2012

  @include prefixer(align-self, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-item-align: $value-2011;

  @if $value == 'flex-start' {
    $value-2011: start;
  }

  @else if $value == 'flex-end' {
    $value-2011: end;
  }
}

// 2011 - flex-line-pack (start | end | center | justify | distribute | stretch)
// 2012 - align-content (flex-start | flex-end | center | space-between | space-around | stretch)

@mixin align-content($value: stretch) {
  $value-2011: $value;

  // 2012

  @include prefixer(align-content, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-line-pack: $value-2011;

  @if $value == 'flex-start' {
    $value-2011: start;
  }

  @else if $value == 'flex-end' {
    $value-2011: end;
  } @else if $value == 'space-between' {
    $value-2011: justify;
  } @else if $value == 'space-around' {
    $value-2011: distribute;
  }
}
