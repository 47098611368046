.transition-standard {
  transition: all 0.2s ease-out;
}

.transition-reveal {
  transition: opacity 0.2s ease-out 0s, transform 0.2s ease-out 0s,
    visibility 0s linear 0.2s;
}

.transition-no-delay {
  transition-delay: 0s;
}
