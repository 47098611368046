.overflow-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.overflow-hidden {
  overflow: hidden;
}

.text-overflow--ellipsis {
  text-overflow: ellipsis;
}
