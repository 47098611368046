.modal-confirm {
  max-width: 400px;
  padding: 20px;

  background-color: $white;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  &__title {
    font-weight: 400;
    color: color(dark-grey, dark);
  }

  &__text {
    margin-top: 20px;

    font-size: 12px;

    color: color(platinum);
    line-height: 1.58;
  }

  &__button {
    margin-left: 16px;
    min-width: 50px;
    padding-bottom: 7px;
    padding-top: 7px;

    font-size: 10px;
    font-weight: bold;

    color: white;

    background-color: color(blue, base);
    border-radius: 2px;

    &:first-child {
      margin-left: auto;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 20px;
  }
}
