.note {
  margin: 50px 0;

  &__title {
    line-height: 10px;

    border-bottom: 1px solid color(mirror);
  }

  &__body {
    margin-top: 20px;
    padding: 45px 45px 45px 0;

    position: relative;

    &::before {
      width: 60px;
      height: 4px;
      content: '';

      top: -5px;
      left: -1px;
      position: absolute;
      z-index: 20;
    }
  }

  &__body__icon {
    width: 90px;
    padding-left: 15px;
  }

  &--hmi-must {
    .note__body {
      background: color(ice);
      border: 1px solid color(mirror);

      &::before {
        background-color: color(red, base);
      }
    }
  }

  &--must {
    .note__body::before {
      background-color: color(red, base);
    }
  }

  &--basic {
    .note__body::before {
      background-color: color(forest, base);
    }
  }

  &--may {
    .note__body::before {
      background-color: color(orange, base);
    }
  }

  &--sdl {
    .note__body::before {
      background-color: color(grey, light);
    }
  }

  .note-block:not(:last-child) {
    margin-bottom: 25px;
  }
}
