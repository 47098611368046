.pagination-control {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  font-family: $livio-norm;
  font-size: 12px;
  letter-spacing: 1.2px;
  color: color(grey, base);
  font-weight: bold;

  &__forward,
  &__back {
    color: color(lime, base);

    &--inactive {
      color: color(grey, light);
    }
  }

  &__back {
    margin-right: auto;
  }

  &__forward {
    margin-left: auto;
  }
}
