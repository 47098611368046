// html {
//   height: 100%;
// }

body.documentation {
  font-size: 1rem !important;

  footer {
    display: none;
  }
}

#page-header {
  @include t-antialias;

  @include mq($from: tablet) {
    max-height: 210px;
    padding: 110px 0 40px;
  }
  padding: 80px 0 30px;
  background: color(forest, dark);

  .container {
    @include mq($from: tablet) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    width: calc(100% - 40px);
    max-width: initial;
  }

  .t-h1 {
    @include mq($from: tablet) {
      padding-top: 6px;
      font-size: 40px;
      line-height: 50px;
    }
    font-size: 30px;
    line-height: 40px;
  }

  select.documentation-select {
    @include mq($from: tablet) {
      width: 280px;
      margin: 0;
    }
    background-image: url('../svg/icons/icon-select-closed.svg');
    background-position: right 24px center;
    background-repeat: no-repeat;
  }
}

#documentation {

  @include mq($from: tablet) {
    display: flex;
  }

  &__sidebar-wrapper {
    @include base-box-shadow();
    width: 100%;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: -100%;
    position: fixed;
    z-index: $z-docs-sidebar;
    background: color(white);
    transition: transform 0.3s ease-in-out;
    padding: 60px 0 40px;

    &.is-visible {
      transform: translateX(100%);
    }

    &.is-searching {
      .sidebar-nav li ul {
        border-top: 1px solid color(grey, light);
      }

      .is-active ul li {
        display: list-item;
      }
    }


    @media (max-width: $nav-breakpoint) {
      padding-top: 50px;
    }

    @include mq($from: tablet) {
      width: 300px;
      flex: 0 0 300px;
      position: relative;
      background: transparent;
      border-right: 1px solid color(grey, light);
      box-shadow: none;
      left: auto;
      transform: translateX(0);
      transition: none;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 0;
      }

      ul {
        max-width: 300px;
      }
    }
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: $z-docs-sidebar;
    background: color(white);
    transform: translateX(-120%);
    transition: all 0.3s ease-in-out;

    &.is-visible {
      transform: translateX(0);
    }

    &.is-searching {
      li {
        display: none;
      }

      li.search-result {
        display: block;
      }

      .sidebar-nav li ul {
        border-top: 1px solid color(grey, light);
      }

      .is-active ul li {
        // display: list-item;
      }
    }


  }

  &__sidebar {
    @include mq($from: tablet) {
      // top: 60px;
      position: sticky;
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }

    top: 0;
    left: 0;
    position: relative;
  }

  .documentation__container {
    @include mq($from: tablet) {
      width: 85%;
    }

    @include mq($from: desktop) {
      padding-left: 80px;
    }

    div[id^='constructor-'],
    div[id^='method-'],
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      position: relative;

      &::before {
        content: '';
        display: block;
        margin-top: 0px !important;
        padding-top: 0px !important;
        visibility: visible !important;
      }

      .headerlink {
        content: '';
        display: none;
        margin-left: 16px;

        &::after {
          width: 0.75em;
          height: 0.75em;
          content: '';
          display: block;

          background-image: url('../svg/outline/icon-outline-link.svg');
          background-repeat: no-repeat;
          background-size: 100% auto;
        }
      }

      &:hover .headerlink {
        display: inline-block;
      }
    }
  }

  &__content {
    // overflow: -moz-scrollbars-none;


    @include mq($from: tablet) {
      width: initial;
      flex: 1 1;
      position: relative;
      max-height: 100%;
      padding: 80px 20px 0;
      margin-top: 60px;


      & > .documentation__container {
        width: 90%;
      }
    }
    width: 100%;
    padding: 60px 20px 0;
    line-height: 1.5rem !important;
    overflow-x: hidden;

    p {
      @include t-antialias;

      @include mq($from: tablet) {
        font-size: 1.2rem !important;
        line-height: 1.467rem !important;
      }

      margin: 0;
      font-size: 15px;
      line-height: 25px;

      & + p {
        margin-top: 1.333rem !important;
      }

      & + h5 {
        margin-top: 30px;
      }

      code {
        padding: 2px 5px;
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        background: color(ice);
        border: 1px solid color(grey, light);
      }
    }

    h1:first-of-type {
      @include mq($from: tablet) {
        margin-bottom: 2.666rem !important;
      }
    }

    h1 {
      @include t-h1;
      @include t-antialias;

      @include mq($from: tablet) {
        margin: 70px 0 40px 0;
      }

      margin: 50px 0 35px 0;
      font-size: 2.666rem !important;
      font-weight: normal;
      line-height: 3.2rem !important;
    }

    h2 {
      @include t-h2;
      @include t-antialias;

      @include mq($from: tablet) {
        margin: 70px 0 40px 0;
      }

      margin: 50px 0 35px 0;
      font-size: 2.4rem !important;
      font-weight: normal;
      line-height: 2.8rem !important;
    }

    h3 {
      @include t-h3;

      @include mq($from: tablet) {
        font-size: 20px;
        font-weight: medium;
      }
      margin: 30px 0;
      font-size: 1.6rem !important;
      font-weight: medium;
      line-height: 1.866rem !important;
      -webkit-font-smoothing: antialiased;
    }

    h4 {
      margin-top: 60px;
      position: relative;
      font-size: 1.2rem !important;
      font-weight: 500;
      letter-spacing: 2px;
      line-height: 1.467 !important;
      text-transform: uppercase;

      &::before {
        width: 60px;
        height: 4px;
        content: '';
        display: block;
        margin-bottom: 20px;
      }

      & + p,
      & + .codehilite {
        margin-top: 1.333rem !important;
      }
    }

    ul,
    ol {
      @include t-antialias;

      @include mq($from: tablet) {
        font-size: 20px;
        line-height: 30px;
      }

      margin: 15px 0;
      font-size: 15px;
      color: color(dark-grey, base);
      line-height: 25px;
    }

    h5 {
      @include t-h5;
      margin: 10px 0 8px;

      font-size: 1.067rem !important;
      font-weight: 500;
      line-height: 1.267rem !important;
      text-transform: uppercase;
    }

    h6 {
      @include t-h6;
      font-size: 0.933rem !important;
      font-weight: normal;
      line-height: 1.067rem !important;
    }

    .table-wrapper {
      margin-top: 20px;

      @include mq($from: tablet) {
        border-left: 0;
        border-right: 0;

        table {
          border-right: 1px solid color(grey, light);
        }

        .pinned {
          border-right: 0;
        }
      }
      margin-top: 40px;
      border-left: 1px solid color(grey, light);
      border-right: 1px solid color(grey, light);

      table {
        border-right: 0;
        margin-top: 0;
      }

      .pinned {
        border-right: 1px solid color(grey, light);
      }

      .scrollable table[data-switched] tbody tr {
        // height: 57px !important;
        // line-height: 1;
      }

      .pinned table tbody tr {
        // height: 57px !important;
        // line-height: 1;
      }
    }

    table {
      & + h3,
      & + p {
        margin-top: 60px;
      }
    }

    img {
      margin: 30px 0;
      max-width: 100%;
    }

    code {
      @include mq($from: tablet) {
        word-break: normal;
      }
      font-size: 15px;
      color: color(black);
      word-break: break-all;
      background: color(ice);
      border: 1px solid color(grey, light);
      border-radius: 2px;
    }

    dl {
      @include mq($from: tablet) {
        display: grid;

        grid-template-columns: auto 1fr;

        dt:not(:first-of-type),
        dd:not(:first-of-type) {
          border-top: 1px solid color(grey, light);
        }
      }
      border: 1px solid color(grey, light);

      dt,
      dd {
        padding: 30px;
      }

      dt {
        @include mq($from: tablet) {
          min-width: 20%;
          padding-bottom: 30px;
        }
        padding-bottom: 15px;
        font-size: 15px;
        font-weight: 500;

        & + dd {
          @include mq($from: tablet) {
            padding-top: 30px;
          }
          padding-top: 0px;
        }
      }

      dd {
        @include mq($from: tablet) {
          word-break: normal;
        }
        margin: 0;
        font-size: 15px;
        line-height: initial;
        word-break: break-all;

        ul {
          @include mq($from: tablet) {
            padding: 0;
          }
          margin: 0;
          font-size: 15px;
          line-height: initial;
        }

        li {
          margin: 0;
          padding: 0;
        }

        & + dt {
          border-top: 1px solid color(grey, light);
        }
      }
    }

    .hierarchy {
      @include mq($from: tablet) {
        margin-bottom: 80px;
      }
      margin-bottom: 40px;

      h3 {
        margin-bottom: 0;
      }

      p {
        font-size: 15px;
        line-height: 30px;
      }
    }

    .note,
    .must,
    .may {
      @include mq($from: tablet) {
        padding: 60px 90px;
      }
      margin: 50px 0;
      padding: 30px 45px;
      position: relative;
      font-size: 15px;
      line-height: 20px;

      h5 {
        margin: 0 0 30px 0;
      }

      p {
        font-size: 15px;
      }

      ul,
      ol {
        @include mq($from: tablet) {
          padding-left: 25px;
        }
        padding-left: 18px;
        font-size: inherit;
        line-height: inherit;
      }

      .icon {
        position: absolute;
      }
    }

    .note {
      border: 1px solid color(grey, light);

      .icon {
        @include mq($from: tablet) {
          top: 60px;
          left: 45px;
        }
        width: 10px;
        height: 22px;
        top: 30px;
        left: 20px;
      }
    }

    .must,
    .may {
      background: color(ice);

      .icon {
        @include mq($from: tablet) {
          top: 60px;
          left: 40px;
        }
        top: 30px;
        left: 12px;
      }
    }
  }



  // &__content::-webkit-scrollbar {
  //   width: 0 !important;
  // }

  &.is-locked {
    height: calc(100vh - 60px);

    #documentation__sidebar {
      top: 60px;
      bottom: 0;
      position: fixed;
    }

    #documentation__content {
      margin-left: 300px;
    }
  }
}

.sidebar-search {
  position: relative;

  input {
    appearance: none;
    width: 100%;
    height: 50px;
    max-width: 300px;
    padding: 0 30px;
    border: 0;

    &:focus {
      outline: 0;
    }
  }

  a {
    @include size(50px);

    @include mq($from: tablet) {
      display: none;
    }
    display: block;
    top: 0;
    right: 0;
    position: absolute;

    svg {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
}

.sidebar-nav {
  width: 100%;
  // max-height: calc(100vh - 110px);
  overflow-y: scroll;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;

  ul {
    margin: 0;
    padding: 0;

    &.top-level {
      li:last-of-type {
        border-bottom: 1px solid color(grey, light);
      }
    }
  }

  li {
    margin: 0;

    list-style-type: none;
    line-height: 1.15;


    &.is-active {
      > ul {
        overflow-y: scroll;
      }
    }

    a {
      display: block;
      padding: 16px 30px;
      color: color(dark-grey, base);
      border-top: 1px solid color(grey, light);
      padding-right: 59px;
    }

    .faq-link {
      color: #A9B3BD;
      &:hover {
        color: color(dark-grey, base);
      }
    }

    a.with-sub {
      position: relative;

      svg {
        top: 50%;
        right: 15px;
        position: absolute;
        transform: translateY(-50%);
        transition: transform 0.2s ease-in-out;
      }

      &.is-active svg {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
    }

    ul li {
      height: initial;
      padding: 0 0 0 15px;
      //line-height: 30px;
      border: 0;

      line-height: 1.15;
      padding-right: 40px;

      a {
        display: block;
        padding: 8px 30px;
        color: color(grey, base);
        border: 0;
      }
    }
  }
}

.docs-back-button {
  flex-shrink: 0 ;
  height: 50px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // color: color(black);
  color: white;
  //background: color(ice);
  border-bottom: 1px solid color(grey, light);
  transition: color 0s linear 0.2s;

  span {
    position: relative;
    z-index: $z-content;
  }

  svg {
    transform: rotate(180deg);

    g {
      transition: fill 0.2s ease-in-out;
    }
  }

  & + .sidebar-search input {
    background: transparent;
  }

  &::before {
    content: '';
    top: 0;
    right: 100%;
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: right 0.3s ease-in-out;
  }

  // &:hover {
  //   color: white;
  //   transition: color 0.2s linear 0s;

  //   svg g {
  //     fill: color(white);
  //   }
  // }

  &:hover::before {
    right: -1px;
  }
}

.bg-white {
  background-color: #fff !important;
}

.table-border {
  border: 1px solid #dee1e6 !important;
}

.visible-strong {
  visibility: visible !important;
}

.md-fenced-code-tabs * {
  box-sizing: border-box;
}

.md-fenced-code-tabs {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0;

  .code-tab-label {
    padding: 3px 12px;
    margin: 0 !important;
  }

  .code-tabpanel {
    margin-top: 0 !important;

    .codehilite {
      background: #202b35;

      div.copy-content {

        pre {
          background: #202b35;

          .cp, .c1 {
            color: #fff;
          }
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }

  input:checked + label {
    color: #fff;
    background: #202b35;
  }

  input:checked + label + .code-tabpanel {
    display: block;
  }

  label {
    width: auto;
    padding: 4px;
    margin: 0 8px;
    cursor: pointer;
    color: #202b35;
    border: 1px solid #202b35;
    border-bottom: none;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;
    font-size: 16px;
  }

  .code-tabpanel {
    display: none;
    width: 100%;
    margin-top: 10px;
    order: 99;
  }
}

.md-fenced-code-tabs pre,
.md-fenced-code-tabs .codehilite {
  width: 100%;
  margin: 0px;
}
