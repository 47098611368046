.pos {
  &-r {
    position: relative;
  }

  &-a {
    position: absolute;
  }

  &-f {
    position: fixed;
  }
}

.left {
  &-0 {
    left: 0px;
  }
}

.right {
  &-18 {
    right: 18px;
  }
}

.top {
  &-20 {
    top: 20px;
  }
}
