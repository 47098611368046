.markdown-editor {
  &__input, &__preview {
    background-color: color(ice);
    padding: 20px;
  }

  &__input {
    resize: none;
    min-height: 575px;
  }

  &__preview {
    height: 575px;
    overflow: auto;
  }
}
