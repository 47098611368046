.about-sdl {
  .about-icon {
    height: auto;
    // padding-bottom: 8px;

    // &--eye {
    //   width: 46px;
    // }

    // &--person {
    //   width: 40px;
    // }

    // &--people {
    //   width: 45px;
    // }

    &__container {
      width: 60px;
    }
  }

  .section-head__button--desktop {
    display: block;
  }

  .static-page__section {
    padding: 60px 0 60px;

    .content {
      .section-head {
        p {
          max-width: 75%;
        }

        a {
          max-width: 35%;
        }
      }
    }
  }
}
