.m {
  &-t {
    &-96 {
      margin-top: 96px;
    }

    &-84 {
      margin-top: 84px;
    }

    &-75 {
      margin-top: 75px;
    }

    &-70 {
      margin-top: 70px;
    }

    &-60 {
      margin-top: 60px;
    }

    &-50 {
      margin-top: 50px;
    }

    &-40 {
      margin-top: 40px;
    }

    &-30 {
      margin-top: 30px;

      &--tablet {
        @include mq($until: desktop) {
          margin-top: 30px;
        }
      }

      &--mobile {
        @include mq($until: tablet) {
          margin-top: 30px;
        }
      }
    }

    &-24 {
      margin-top: 24px;
    }

    &-20 {
      margin-top: 20px;
    }

    &-14 {
      margin-top: 14px;
    }

    &-10 {
      margin-top: 10px;

      &--mobile {
        @include mq($until: tablet) {
          margin-top: 10px;
        }
      }
    }

    &-5 {
      margin-top: 5px;

      &--mobile {
        @include mq($until: tablet) {
          margin-top: 5px;
        }
      }
    }
  }

  &-b {
    &-100 {
      margin-bottom: 100px;
    }

    &-96 {
      margin-bottom: 96px;
    }

    &-60 {
      margin-bottom: 60px;
    }

    &-50 {
      margin-bottom: 50px;
    }

    &-30 {
      margin-bottom: 30px;
    }

    &-20 {
      margin-bottom: 20px;

      &--tablet {
        @include mq($until: desktop) {
          margin-bottom: 20px;
        }
      }
    }

    &-15 {
      margin-bottom: 15px;
    }

    &-14 {
      margin-bottom: 14px;
    }

    &-10 {
      margin-bottom: 10px;
    }

    &-5 {
      margin-bottom: 5px;
    }

    &-0 {
      margin-bottom: 0px;
    }
  }

  &-l {
    &-50 {
      margin-left: 50px;
    }

    &-30 {
      margin-left: 30px;
    }

    &-20 {
      margin-left: 20px;
    }

    &-10 {
      margin-left: 10px;
    }

    &-0 {
      margin-left: 0px;

      &--mobile {
        @include mq($until: tablet) {
          margin-left: 0px;
        }
      }
    }
  }

  &-r {
    &-60 {
      margin-right: 60px;
    }

    &-30 {
      margin-right: 30px;
    }

    &-20 {
      margin-right: 20px;
    }

    &-10 {
      margin-right: 10px;
    }
  }

  &-t,
  &-b,
  &-l,
  &-r {
    &-auto {
      margin-left: auto;
    }
  }

  &-0 {
    margin: 0;
  }
}
