ul.no-list {
  margin: 0;
  padding: 0;

  list-style-type: none;

  li {
    display: inline-block;
  }
}

ul.no-bullet {
  margin-left: 0;
  padding-left: 0;

  list-style-type: none;
}
