.ls1 {
  letter-spacing: 1px;
}

.ls2 {
  letter-spacing: 2px;
}

.ls2-5 {
  letter-spacing: 2.5px;
}
